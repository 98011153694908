import { Component, TemplateRef, input } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'atom-vfi-accordion',
  templateUrl: './vfi-accordion.component.html',
  styleUrls: ['./vfi-accordion.component.scss'],
  imports: [NgTemplateOutlet],
})
export class VfiAccordionComponent {
  readonly header = input<TemplateRef<any>>(undefined);
  readonly body = input<TemplateRef<any>>(undefined);

  constructor() {}
}
