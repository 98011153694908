import {
  LensCategory,
  AlarmLens,
  NotificationCriteria,
  GlobalFilterSort,
  CriterionSelection,
} from '@vfi-ui/models';

export interface DisplayOptions {
  category?: LensCategory;
  criterion?: CriterionSelection[];
  exclude?: CriterionSelection[];
  lens?: AlarmLens;
  name?: string;
  notificationCriteria?: NotificationCriteria;
  sort?: GlobalFilterSort;
}
export enum DeviceAgent {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  SMARTTV = 'smarttv',
  DESKTOP = 'desktop',
}

export interface ScriptModel {
  name: string;
  src: string;
  loaded: boolean;
}

export interface TimeValues {
  days?: number;
  daysms?: number;
  hours?: number;
  hoursms?: number;
  minutes?: number;
  minutesms?: number;
  seconds?: number;
  d?: string;
  h?: string;
  m?: string;
  s?: string;
}

export type PathMatch = 'full' | 'prefix' | undefined;

export interface MenuListItem {
  id: number;
  name: string;
  subText?: string;
  secondaryTitle?: string;
}

export interface DetailsFields {
  isVisible: boolean;
  fields: DetailsField[];
}

export interface DetailsField {
  name?: string;
  title: string;
  dataType: string;
  isReadonly?: boolean;
  isRequired?: boolean;
  class?: string;
}

export interface DropdownOption {
  label: string;
  value: string | number;
}

export interface DropdownSearch {
  searchTerm: string;
  field: string;
}

export enum DropdownDataTypes {
  String = 'STRING',
  Number = 'NUMBER',
  Boolean = 'BOOLEAN',
  List = 'LIST',
}

export interface Tab {
  name: string;
  isVisible: boolean;
}

export interface GroupByChange {
  groupBy: string;
  groupByAlarmAttribute: string;
}

export enum DateType {
  Last7Days = 'LAST_7_DAYS',
  Last30Days = 'LAST_30_DAYS',
}

export interface TimeRange {
  from: string;
  to: string;
}
