<div
  [ngClass]="{ 'cursor-not-allowed': disabled() }"
  #select
  nz-popover
  [(nzPopoverVisible)]="showDropdown"
  nzPopoverTrigger="click"
  [nzPopoverContent]="vfiDropdown"
  [nzPopoverPlacement]="['bottom']"
  [nzPopoverOverlayStyle]="{ width: select?.offsetWidth + 'px' }"
  nzPopoverOverlayClassName="dropdown-container"
  (nzPopoverVisibleChange)="onPopoverVisibleChange($event)"
  [ngClass]="{
    'pointer-none': readOnly() || disabled(),
  }"
>
  <div class="vfi-input {{ size() }}" [ngClass]="{ disabled: disabled() }">
    <div *ngIf="label()" class="label" [class.iyellow-6]="required() && !readOnly()">
      <span>{{ label() }}</span>
    </div>
    <div
      class="select-icons position-relative cursor-pointer"
      [ngClass]="{ subtle: subtle(), success: success(), warning: warning(), error: error() }"
    >
      <span [ngClass]="{ hasIcon: iconLeft() }"></span>
      <div
        tabindex="0"
        class="select d-flex align-items-center"
        [ngClass]="{
          'select-icon-left-spacing': iconLeft(),
          'select-icon-right-spacing': value() && !success() && !error() && !warning(),
          'select-icon-right-state-spacing':
            value() && !subtle() && (success() || warning() || error()),
          readOnly: readOnly(),
          disabled: disabled(),
        }"
      >
        <span
          *ngIf="placeholder()?.length && !value()?.length"
          class="placeholder ellipsis"
          >{{ placeholder() }}</span
        >
        <span *ngIf="value()?.length" class="value">{{
          formatDisplayValues()
        }}</span>
      </div>
      <i class="{{ iconLeft() }} icon icon-left"></i>
      <span>
        <i
          *ngIf="success() && !subtle()"
          class="fa-solid fa-check icon cursor-pointer success-icon icon-right"
        ></i>
        <i
          *ngIf="warning() && !subtle()"
          class="fa-solid fa-triangle-exclamation icon cursor-pointer warning-icon icon-right"
        ></i>
        <i
          *ngIf="error() && !subtle()"
          class="fa-solid fa-circle-exclamation icon cursor-pointer error-icon icon-right"
        ></i>
        <i
          *ngIf="
            value() &&
            (showDropdown || canClear()) &&
            value() &&
            canClear() &&
            !success() &&
            !warning() &&
            !error() &&
            !readOnly()
          "
          class="fa-solid fa-circle-x icon first-icon-right icon-right regular-12 clear-icon"
          (click)="$event.stopPropagation(); cleared.emit()"
        ></i>
        <i
          *ngIf="!success() && !warning() && !error() && !readOnly()"
          class="fa-solid fa-chevron-down icon icon-right regular-12"
        ></i>
      </span>
      <p *ngIf="hint() && !warning() && !error()" class="mb-0 hint-text">
        {{ hint() }}
      </p>
      <p *ngIf="warning() && warningMessage() && !subtle()" class="mb-0 hint-text">
        {{ warningMessage() || hint() }}
      </p>
      <p *ngIf="error() && errorMessage() && !subtle()" class="mb-0 error-text">
        {{ errorMessage() || hint() }}
      </p>

      <ng-template #vfiDropdown>
        <div class="dropdown">
          <div class="dropdown-content">
            <div
              *ngIf="parentData()?.name?.length"
              class="back-label d-flex align-items-center"
            >
              <atom-vfi-button
                size="small"
                [text]="parentData()?.name"
                ariaLabel="back-btn"
                type="tertiary"
                icon="fa-solid fa-chevron-left"
                (clicked)="onBackSelected(parentData())"
              ></atom-vfi-button>
            </div>
            <div *ngIf="showSearch()" class="search">
              <input
                #searchInput
                class="dropdown-search"
                type="text"
                placeholder="Search"
                (keyup)="onSearch($event.target.value)"
              />
            </div>
            <div
              *ngIf="formattedOptions.length"
              class="options-container"
              #optionsContainer
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [infiniteScrollContainer]="optionsContainer"
              (scrolled)="onOptionsScroll()"
            >
              <div
                class="option d-flex align-items-center justify-content-between"
                [class.selected]="isChecked(o)"
                *ngFor="let o of formattedOptions"
                (click)="onOptionSelect(o)"
              >
                <span class="ellipsis" [title]="o?.label">{{ o?.label }}</span>
                <i
                  *ngIf="o?.hasChildren"
                  class="fa-solid fa-chevron-right ml-1"
                ></i>
                <i
                  *ngIf="value()?.includes(o?.label)"
                  class="fa-solid fa-check ml-1"
                ></i>
              </div>
            </div>
            <div
              *ngIf="!formattedOptions?.length"
              class="d-flex align-items-center justify-content-center results"
            >
              <span *ngIf="!loading()" class="no-results">No results</span>
              <span *ngIf="loading()" class="no-results">Loading...</span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
