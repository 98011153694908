import { Component, input, model, output } from '@angular/core';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'atom-vfi-checkbox',
  templateUrl: './vfi-checkbox.component.html',
  styleUrl: './vfi-checkbox.component.scss',
  imports: [NzCheckboxComponent, FormsModule, NgIf, NzTooltipDirective],
})
export class VfiCheckboxComponent {
  readonly label = input<string>(undefined);
  readonly labelCheck = input(false);
  readonly labelIcon = input<string>(undefined);
  readonly labelIconTooltip = input<string>(undefined);
  readonly value = model<boolean>(undefined);

  readonly disabled = input(false);
  readonly valueChanged = output<boolean>();

  /**
   * handle input changed event
   *
   * @param {string} value
   * @memberof VfiCheckboxComponent
   */
  changed(value: boolean) {
    this.value.set(value);
    this.valueChanged.emit(this.value());
  }
}
