import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';
import { GuardResult, RedirectCommand, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class DesktopGuard {
  constructor(
    private router: Router,
    private deviceDetectorService: DeviceDetectorService
  ) {}
  /**
   * determines if the application is run on non mobile device
   *
   * @returns {boolean}
   * @memberof DesktopGuard
   */
  canActivate(): GuardResult {
    const isMobile = this.deviceDetectorService.isMobile();
    const isTablet = this.deviceDetectorService.isTablet();
    if (!isMobile && !isTablet && !Capacitor.isNativePlatform()) {
      return new RedirectCommand(this.router.parseUrl('/'));
    }
    return true;
  }
}
