import {
  ResetAlarms,
  SetSortFilterType,
  SetGlobalCurrentLens,
  AlarmsState,
  AlarmsStateModel,
  TotalResetGlobalFilters,
  SetCoreGlobalFilters,
  ResetOnlyCoreCriterionFilters,
  LensState,
  SetCurrentlySelectedLens,
  SetAlarmLimit,
  SetAlarmView,
  SetSort,
} from '@vfi-ui/state';
import {
  TileType,
  MenuLens,
  LensParent,
  MAX_TABLE_LIMIT,
  AlarmViews,
  FastAlarm,
} from '@vfi-ui/models';
import { take, filter, switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { fetchLensTeamName, isEmpty } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class AlarmsTableResolver {
  @Select(AlarmsState.getState) alarmsState$: Observable<AlarmsStateModel>;
  @Select(LensState.getLenses) lenses$: Observable<MenuLens[]>;

  constructor(
    private store: Store,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    lensParent: LensParent
  ): Observable<FastAlarm[]> {
    const team = route.paramMap.get('team');
    const lens = route.paramMap.get('lens');

    this.store.dispatch(new SetSortFilterType('alarm'));
    this.store.dispatch(new ResetAlarms());
    this.store.dispatch(new SetAlarmLimit(MAX_TABLE_LIMIT));

    // Reset global filters only when switching to a different lens
    const currentLens = this.store.selectSnapshot(
      LensState.getCurrentlySelectedLens
    );
    if (isEmpty(currentLens) || currentLens?.name !== lens) {
      this.store.dispatch(new TotalResetGlobalFilters(false)).subscribe(() => {
        this.store.dispatch(new ResetOnlyCoreCriterionFilters());
      });
    }

    return this.waitForLensToUpdate(lens, team, lensParent).pipe(
      switchMap((r) => {
        if (r) {
          const lensOptions = {
            parent: r.category,
            child: r.name,
            parentId: r.id,
            isCustom: r?.isCustom,
            type: 'alarms' as TileType,
            category: r.category,
            description: r.description,
          };
          this.store.dispatch(new SetCurrentlySelectedLens(r.id));
          this.store.dispatch(new SetGlobalCurrentLens(lensOptions));
          this.store.dispatch(
            new SetCoreGlobalFilters(r.displayOptions.criterion)
          );
          const order = Array.isArray(r.criteria.order)
            ? r.criteria.order
            : [r.criteria.order];

          this.store.dispatch(new SetSort(order));
          this.store.dispatch(new SetAlarmView(AlarmViews.table));
        } else {
          this.router.navigate(['/alarms']);
        }
        return this.waitForStateToUpdate();
      })
    );
  }

  /**
   * Waits for lenses to contain selected lens
   *
   * @param {string} name
   * @param {string} team
   * @param {LensParent} lensParent
   * @return {*}  {Observable<MenuLens>}
   * @memberof AlarmsTableResolver
   */
  waitForLensToUpdate(
    name: string,
    team: string,
    lensParent: LensParent
  ): Observable<MenuLens> {
    return this.lenses$.pipe(
      filter(
        (lenses) =>
          lenses.length > 0 &&
          !isEmpty(lenses.find((l) => l.name === lensParent))
      ),
      map((lenses) =>
        lenses.find((x) => x.name === name && fetchLensTeamName(x) === team)
      ),
      take(1)
    );
  }
  /**
   * Gets the alarms from state
   *
   * @return {*}  {Observable<CoreAlarm[]>}
   * @memberof AlarmsTableResolver
   */
  waitForStateToUpdate(): Observable<FastAlarm[]> {
    return this.alarmsState$.pipe(
      map((res) => res.items),
      filter((state) => !!state),
      take(1)
    );
  }
}
