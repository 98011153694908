<div class="d-flex neutral-6 pl-sm-0 alarm-source-container" *ngIf="show()">
  <time class="flex-shrink-0" datetime="2019-05-16">
    <span
      class="alarm-source cursor-pointer underline"
      (click)="onSourceSelected()"
      aria-label="alarm-source"
      >{{ alarmSource() }}</span
    >
    <span *ngIf="showDate()" class="separator"></span>
  </time>
  <time *ngIf="showDate()" class="flex-shrink-0" datetime="2019-05-16">
    <span>{{ alarm()?.latestAlarmTime | dateTime: 'MM/d/yyyy' }}</span>
    <span>{{ alarm()?.latestAlarmTime | dateTime: 'hh:mm a' }}</span>
  </time>
</div>
