import {
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  input,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Directive({ selector: '[atomAutoGrow]' })
export class AutoGrowDirective implements OnInit, OnDestroy {
  readonly maximumHeight = input<number>(undefined); // based on pixel
  readonly minHeight = input<number>(undefined); // based on pixel
  readonly value = input<string>(undefined);
  readonly reset = input<Subject<boolean>>(new Subject<boolean>());
  readonly isReadOnly = input(false);
  sub: Subscription = new Subscription();
  hasAdjusted = false;

  constructor(public element: ElementRef) {}

  @HostListener('input', ['$event.target'])
  @HostListener('cut', ['$event.target'])
  @HostListener('paste', ['$event.target'])
  @HostListener('change', ['$event.target'])
  onInput() {
    this.adjustHeight();
  }

  /**
   * adjust the height dynamically
   *
   * @memberof AutoGrowDirective
   */
  adjustHeight(): void {
    if (this.isReadOnly() && this.hasAdjusted) {
      return;
    }
    const element = this.element.nativeElement;
    element.style.height = this.minHeight() + 'px';
    element.style.height = element.scrollHeight + 'px';
    if (element.scrollHeight <= this.maximumHeight()) {
      element.style.overflowY = 'hidden';
      delete element.style.maxHeight;
    } else {
      element.style.overflowY = 'scroll';
      element.style.maxHeight = this.maximumHeight() + 'px';
    }
  }

  ngOnInit(): void {
    if (this.element.nativeElement.scrollHeight) {
      // adjusts height after DOM is rendered so that we can get the correct values
      setTimeout(() => {
        this.adjustHeight();
        this.hasAdjusted = true;
      });
    }
    this.sub = this.reset().subscribe(() => this.resetHeight());
  }

  /**
   * reset the height based off rxjs subject
   *
   * @memberof AutoGrowDirective
   */
  resetHeight() {
    const element = this.element.nativeElement;
    element.style.height = this.minHeight() + 'px';
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
