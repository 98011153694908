import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  input,
  output,
  model,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ExternalCmmsField,
  ExternalCmmsDomainValueCreateInput,
  MODAL_Z_INDEX,
} from '@vfi-ui/models';
import { get } from '@vfi-ui/util/helpers';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { NgClass } from '@angular/common';
import { VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'nuclei-create-cmms-domain-value',
  templateUrl: './create-cmms-domain-value.component.html',
  styleUrls: ['./create-cmms-domain-value.component.scss'],
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    VfiButtonComponent,
  ],
})
export class CreateCmmsDomainValueComponent implements OnInit, OnChanges {
  readonly isVisible = model(false);
  readonly disableAddValueButton = input(false);
  readonly selectedCmmsField = input<ExternalCmmsField>(undefined);
  readonly closeModal = output();
  readonly createCmmsDomainValue = output<ExternalCmmsDomainValueCreateInput>();
  form: UntypedFormGroup;
  modalZIndex = MODAL_Z_INDEX;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      value: [null, Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes.isVisible, 'currentValue', false)) {
      this.form.reset();
    }
  }

  /**
   * Emit event to create cmms value.
   *
   * @memberof CreateCmmsDomainValueComponent
   */
  handleCreateCmmsDomainValue() {
    this.createCmmsDomainValue.emit(this.form.value);
  }

  /**
   * Invoked when the cancel button is selected. Closes
   * the modal and resets the form.
   *
   * @memberof CreateCmmsDomainValueComponent
   */
  handleCancel() {
    this.form.reset();
    this.closeModal.emit();
  }
}
