import { gql } from 'apollo-angular';

export const CREATE_LENS_QUERY = gql`
  mutation createLens($data: LensCreateInput!) {
    lens: createLens(data: $data) {
      id
      name
      description
      criteria
      category
      isCustom
      isActive
      isParent
      type
      orderValue
      tableOptions
    }
  }
`;

export const UPDATE_LENS_QUERY = gql`
  mutation updateLens($id: String!, $data: LensUpdateInput!) {
    lens: updateLens(id: $id, data: $data) {
      name
      description
      id
      criteria
      category
      isActive
      isCustom
      isParent
      type
      orderValue
      tableOptions
      team {
        id
        name
      }
    }
  }
`;

export const DELETE_LENS_QUERY = gql`
  mutation deleteLens($id: String!) {
    deleteLens(id: $id)
  }
`;

export const GET_LENS_COUNT_QUERY = gql`
  query lensEntityCounts($options: LensOptions) {
    lensEntityCounts(options: $options) {
      id
      count
    }
  }
`;

export const UPDATE_LENS_ORDER = gql`
  mutation updateLensOrder($input: [LensOrderValueInput!]!) {
    updateLensOrder(input: $input)
  }
`;
