<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Replay Alarms"
  [nzBodyStyle]="{ 'min-height': '200px' }"
  [nzFooter]="modalFooter"
  [nzWidth]="460"
  [nzZIndex]="modalZIndex"
  (nzOnCancel)="closeModal.emit()"
>
  <ng-template nzModalContent>
    <section
      aria-label="externalSystemReplayAlarms"
      class="d-flex scrollable-frame modalBodyHeight"
    >
      <div class="d-flex flex-column" [formGroup]="form">
        <div class="d-flex flex-row">
          <p>
            Upon submission will replay and reparse all alarms for
            <strong>{{ externalSystemName() }}</strong
            >.
          </p>
        </div>
        <div class="d-flex">
          <label class="gray-1 regular-16 mr-2 mb-3"> Replay All Alarms </label>
          <nz-switch
            aria-label="replay-all-alarms-toggle"
            formControlName="replayAll"
            (ngModelChange)="replayAllToggled()"
          ></nz-switch>
        </div>
        <div
          class="d-flex flex-row orange-6"
          *ngIf="validationErrorMessage && !form.value.replayAll"
        >
          {{ validationErrorMessage }}
        </div>
        <div class="d-flex">
          <label class="mt-2">Replay Start Date</label>
        </div>
        <div class="d-flex">
          <nz-date-picker
            nzSize="large"
            nzPlaceHolder="Start Date"
            formControlName="replayStartAt"
            nzShowTime
            nzFormat="MM/dd/yyyy hh:mm a"
            [nzDisabled]="form.value.replayAll"
            (nzOnOk)="dateChanged()"
          ></nz-date-picker>
        </div>
        <div class="d-flex">
          <label class="mt-2">Replay End Date</label>
        </div>
        <div class="d-flex">
          <nz-date-picker
            nzSize="large"
            nzPlaceHolder="End Date"
            formControlName="replayEndAt"
            nzShowTime
            nzFormat="MM/dd/yyyy hh:mm a"
            [nzDisabled]="form.value.replayAll"
            (nzOnOk)="dateChanged()"
          ></nz-date-picker>
        </div>
        <div class="d-flex flex-row mt-2">
          <p>
            <i
              >NOTE: Replay could take up to 24 hours depending on the time
              range selected and the volume of alarms</i
            >
          </p>
        </div>
      </div>
    </section>
  </ng-template>
  <ng-template #modalFooter>
    <div class="w-100 d-flex justify-content-end">
      <atom-vfi-button
        [text]="'Cancel'"
        [type]="'secondary'"
        (clicked)="handleCancel()"
      ></atom-vfi-button>
      <atom-vfi-button
        class="ml-1"
        [text]="'Replay Alarms'"
        [type]="'primary'"
        [disabled]="
          (!form.value.replayAll &&
            !form.value.replayStartAt &&
            !form.value.replayEndAt) ||
          validationErrorMessage
        "
        (clicked)="handleSubmit()"
      ></atom-vfi-button>
    </div>
  </ng-template>
</nz-modal>
