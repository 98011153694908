import { CriterionSelection } from '@vfi-ui/models';
import { isNil, isEmpty, head } from './lodash.util';

export function hasReqFields(criterion: CriterionSelection[]): boolean {
  if (
    head(criterion) &&
    head(criterion).detail &&
    head(criterion).detail.selection
  ) {
    const crit = head(criterion).detail.selection;
    let isValid: boolean;
    switch (head(criterion).detail.type) {
      case 'dateTime':
        isValid = crit.min && !isNil(crit.min) && crit.max && !isNil(crit.max);
        break;
      default:
        isValid = crit && !isEmpty(crit.value) && crit.value !== '';
        break;
    }
    return isValid;
  }
  return false;
}
