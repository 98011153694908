import { BLANK_VALUE, BLANK_VALUE_TEXT } from './core.constant';

export const AlarmDetailTabs = [
  { name: 'History', isVisible: true },
  { name: 'Notes', isVisible: true },
  { name: 'Details', isVisible: true },
  { name: 'CMMS Config', isVisible: true },
  { name: 'Change History', isVisible: true },
];

export enum AlarmType {
  ASSET = 'ASSET',
  EQUIPMENT = 'EQUIPMENT',
  SYSTEM = 'SYSTEM',
  SPACE = 'SPACE',
}

export const AssetClassBlankOption = [
  { label: BLANK_VALUE_TEXT, value: BLANK_VALUE },
];

export const AssetClassFieldOptions = [
  { label: 'Equipment', value: AlarmType.EQUIPMENT },
  { label: 'Space', value: AlarmType.SPACE },
  { label: 'System', value: AlarmType.SYSTEM },
];

export const AssetClassFilterOptions = [
  ...AssetClassBlankOption,
  ...AssetClassFieldOptions,
];
