import {
  Component,
  SimpleChanges,
  OnChanges,
  input,
  output,
  model,
} from '@angular/core';
import { NEW_VALUE_TEXT, SELECT_ALL_VALUE } from '@vfi-ui/models';
import { head, isNil, uniq } from '@vfi-ui/util/helpers';
import { get } from '@vfi-ui/util/helpers';
import { NzSelectComponent, NzOptionComponent } from 'ng-zorro-antd/select';
import { NgStyle, NgFor, NgIf, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'atom-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  imports: [
    NzSelectComponent,
    NgStyle,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NgIf,
    NzTooltipDirective,
    NgClass,
  ],
})
export class DropdownMenuComponent implements OnChanges {
  readonly options = model(undefined);
  readonly label = input<string>(undefined);
  readonly value = model(undefined, {});
  readonly allowClear = input(false);
  readonly multiple = input(false);
  readonly required = input(false);
  readonly disabled = input(false);
  readonly showSearch = input(true);
  readonly showTooltips = input(false);
  readonly width = input('229');
  readonly name = input<string>(undefined);
  readonly showPlaceholder = input(true);
  readonly allowNewValues = input(false);
  readonly useAllOption = input(false);
  readonly selectAllPlaceholder = input(null);
  readonly open = input(false);
  readonly capitalizePlaceholder = input(true);
  readonly revertToValue = input(undefined);
  readonly valueChange = output<any>();
  readonly optionSearch = output<string>();
  readonly newValuesSelected = output<string>();
  readonly dropdownStateChanged = output<boolean>();
  inputValue;
  focused = false;
  newValues = [];

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    const optionChanged = get(change.options, 'currentValue', false);
    if (change.value && change.value.currentValue) {
      const value = this.value();
      if (this.multiple() && !Number.isNaN(Number(head(value)))) {
        this.value.update((v) => v.map(Number));
      }
    }
    if (optionChanged) {
      this.addAllOptions(this.name(), this.selectAllPlaceholder());
      if (this.allowNewValues()) {
        this.options.update((options) =>
          this.removeDuplicateOptions(options, this.newValues)
        );
      }
    }
    // reverts the previously selected value back to its original
    // this is good for selections that need to be confirmed first
    if (
      change.revertToValue?.currentValue &&
      change.revertToValue?.currentValue !== null
    ) {
      this.value.set(change.revertToValue.currentValue);
    }
  }

  /**
   * emit value of selected option
   *
   * @param {*} value
   * @memberof DropdownMenuComponent
   */
  valueChanged(value) {
    if (this.allowNewValues()) {
      const formatted = this.removeNewValueText(
        value,
        this.options(),
        this.newValues
      );
      this.options.set(formatted.options);
      value = formatted.value;
    }
    if (this.multiple() && !Number.isNaN(Number(head(value)))) {
      this.inputValue = value.map(String);
      this.valueChange.emit(uniq(this.inputValue));
    } else {
      this.valueChange.emit(value);
    }
  }
  /**
   * emit the state of the dropdown status
   *
   * @param {boolean} value
   * @memberof DropdownMenuComponent
   */
  openChange(value: boolean) {
    this.dropdownStateChanged.emit(value);
  }

  /**
   * emit even to fetch search options
   *
   * @param {string} ev
   * @param {*} options
   * @param {*} newValues
   * @memberof DropdownMenuComponent
   */
  fetchOptions(ev: string, options, newValues) {
    this.optionSearch.emit(ev);
    if (this.allowNewValues()) {
      this.options.set(this.addNewValueText(ev, options, newValues));
    }
  }

  /**
   * remove new value text from selected option
   *
   * @param {string} value
   * @param {*} options
   * @param {*} newValues
   * @returns
   * @memberof DropdownMenuComponent
   */
  removeNewValueText(value: string, options, newValues) {
    if (newValues.length && newValues[0].label === value) {
      const selected = value.replace(` ${NEW_VALUE_TEXT}`, '');
      newValues[0].label = selected;
      newValues[0].value = selected;
      this.newValuesSelected.emit(selected);
      this.newValues = newValues;
      options = this.removeDuplicateOptions(options, newValues);
      const newVal = head(options).value;
      this.value.set(newVal);
      value = newVal;
    } else {
      this.newValuesSelected.emit('');
    }

    return { options, value };
  }

  /**
   * add new value text to options
   *
   * @param {string} name
   * @param {*} options
   * @param {*} newValues
   * @returns
   * @memberof DropdownMenuComponent
   */
  addNewValueText(name: string, options, newValues) {
    if (options.length && head(options).label.includes(NEW_VALUE_TEXT)) {
      options.shift();
    }
    if (name.length) {
      name = this.trimNewValue(name);
      const newVal = {
        label: `${name} ${NEW_VALUE_TEXT}`,
        value: `${name} ${NEW_VALUE_TEXT}`,
      };
      newValues[0] = newVal;
    }
    this.newValues = newValues;
    return this.removeDuplicateOptions(options, newValues);
  }

  /**
   * set options
   *
   * @param {*} options
   * @param {*} newValues
   * @returns
   * @memberof DropdownMenuComponent
   */
  removeDuplicateOptions(options, newValues) {
    if (newValues.length) {
      const label = head(newValues).label.replace(` ${NEW_VALUE_TEXT}`, '');
      newValues = options.some(
        (r) => r.label.toLowerCase() === label.toLowerCase()
      )
        ? []
        : newValues;
    }
    return [...newValues, ...options];
  }

  /**
   * trim new value to 100 characters
   *
   * @param {string} value
   * @returns
   * @memberof DropdownMenuComponent
   */
  trimNewValue(value: string) {
    return value.length > 100 ? value.substring(0, 100) : value;
  }

  /**
   * check if value is valid
   *
   * @param {*} value
   * @returns
   * @memberof DropdownMenuComponent
   */
  hasValue(value) {
    return !isNil(value);
  }

  /**
   * add select all option
   *
   * @param {string} name
   * @memberof DropdownMenuComponent
   */
  addAllOptions(name: string, selectAllPlaceholder: string) {
    const options = this.options();
    if (
      this.useAllOption() &&
      !options.some((o) => o.value === SELECT_ALL_VALUE)
    ) {
      const placeholder = selectAllPlaceholder || name;
      this.options.update((o: any[]) => {
        o.unshift({
          label: `All ${placeholder}s`,
          value: SELECT_ALL_VALUE,
        });
        return o;
      });
    }
  }
}
