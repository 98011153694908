<div
  class="dropdown-list d-flex flex-column justify-content-between"
  role="listbox"
  aria-expanded="false"
>
  <div
    class="back"
    *ngIf="
      (parent()?.length && type() && showTypeSelector()) ||
      (parent()?.length > 1 && type())
    "
    (click)="back()"
  >
    <i class="fas fa-chevron-left"></i>
    <span>{{ parent()[parent()?.length - 1]?.name }}</span>
  </div>

  <div>
    <form [formGroup]="formGroup" *ngIf="showSearch()">
      <input
        type="text"
        class="dropdown-search"
        placeholder="Search"
        formControlName="search"
        (keyup)="search($event.target.value)"
      />
    </form>

    <div class="alert" *ngIf="loading()">Loading...</div>
    <div class="alert" *ngIf="options()?.length === 0 && !loading()">
      No Results
    </div>

    <div
      class="list-container"
      *ngIf="!loading() && !optionsBySection()"
      #listContainer
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="listContainer"
      (scrolled)="scroll()"
    >
      <atom-dropdown-item
        *ngFor="let o of options()"
        [item]="o"
        [title]="type() === 'Asset' && parent()?.length ? getAssetName(o) : o?.name"
        [showCheckbox]="
          (multiSelect() && !showTypeSelector()) ||
          (multiSelect() && showTypeSelector() && parent()?.length)
        "
        [selected]="isChecked(o)"
        [showBadge]="o.meta?.badge"
        [badgeCount]="o.meta?.badge"
        [showRightIcon]="o?.count > 0 && showDrilldown()"
        [showDrilldown]="showDrilldown()"
        [disabled]="o?.meta?.disabled"
        (selectChange)="pickOption(o)"
        (drillDownChange)="o.count > 0 ? drillDown(o) : pickOption(o)"
        [nzTooltipTitle]="
          type() === 'Asset' && parent()?.length ? getAssetName(o) : o?.name
        "
        nz-tooltip
        [nzTooltipMouseEnterDelay]="0.5"
        [ngClass]="{ 'cursor-not-allowed': o?.meta?.disabled }"
      ></atom-dropdown-item>
    </div>

    <div
      class="list-container"
      *ngIf="!loading() && optionsBySection()"
      #listContainer
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="listContainer"
      (scrolled)="scroll()"
    >
      <div
        *ngFor="let key of getOptionKeys(options()); let last = last"
        class="mb-3 pb-1"
        [class.team-section]="unassignedOptions() || !last"
      >
        <div class="mb-2">{{ key === 'null' ? '-' : key }}</div>
        <atom-dropdown-item
          *ngFor="let o of options()[key]; let first = first"
          [item]="o"
          [title]="o?.name"
          [showCheckbox]="multiSelect() || parent()?.length"
          [selected]="isChecked(o)"
          [showBadge]="o.meta?.badge"
          [badgeCount]="o.meta?.badge"
          [showRightIcon]="o?.count > 0"
          [disabled]="o?.meta?.disabled"
          (selectChange)="pickOption(o)"
          (drillDownChange)="o.count > 0 ? drillDown(o) : pickOption(o)"
          [nzTooltipTitle]="getAssigneeTooltipText(o)"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="0.5"
          [ngClass]="{ 'cursor-not-allowed': o?.meta?.disabled }"
        ></atom-dropdown-item>
      </div>
      <div class="mb-3 pb-1" *ngIf="unassignedOptions()?.length">
        <div class="mb-2">-</div>
        <atom-dropdown-item
          *ngFor="let o of unassignedOptions(); let last = last"
          [item]="o"
          [title]="o?.name"
          [showCheckbox]="multiSelect() || parent()?.length"
          [selected]="isChecked(o)"
          [showBadge]="o.meta?.badge"
          [badgeCount]="o.meta?.badge"
          [showRightIcon]="o?.count > 0"
          [disabled]="o?.meta?.disabled"
          (selectChange)="pickOption(o)"
          (drillDownChange)="o.count > 0 ? drillDown(o) : pickOption(o)"
          [nzTooltipTitle]="getAssigneeTooltipText(o)"
          nz-tooltip
          [nzTooltipMouseEnterDelay]="0.5"
          [ngClass]="{ 'cursor-not-allowed': o?.meta?.disabled }"
        ></atom-dropdown-item>
      </div>
    </div>
  </div>

  <button
    class="select-btn"
    [disabled]="formGroup.get('selected').invalid"
    (click)="select(formGroup.get('selected').value)"
  >
    {{ buttonLabel() }}
    {{
      multiSelect() && formGroup.get('selected').value?.length
        ? '(' + formGroup.get('selected').value?.length + ')'
        : ''
    }}
  </button>
</div>
