<div class="vfi-multi-select">
  <div *ngIf="label()" class="vfi-label" [class.yellow-6]="required() && !readOnly()">
    <span>{{ label() }}</span>
  </div>
  <button
    class="vfi-multi-select-input"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showDropdown"
    [nzPopoverPlacement]="['bottom', 'top']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="dropdownContent"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    (nzPopoverVisibleChange)="fetchFields()"
    [disabled]="disabled()"
    [class.gray-8-bg]="disabled()"
    [class.required-field]="required() && !value()"
  >
    <div class="vfi-multi-select-placeholder d-flex" *ngIf="!value()?.length">
      {{ placeholder() }}
    </div>
    <div *ngIf="value()?.length" class="vfi-multi-select-value">
      {{ formatDisplayValue(value()) }}
    </div>
  </button>
</div>
<ng-template #dropdownContent>
  <atom-vfi-select-popup
    [options]="options"
    [selectedValues]="selectedOptions"
    [loading]="loading"
    (scrolled)="onScroll(searchValue)"
    (searched)="onSearchChange($event)"
    (valueChanged)="valueChanged($event)"
  ></atom-vfi-select-popup>
</ng-template>
