import {
  Component,
  ElementRef,
  AfterViewInit,
  OnInit,
  input,
  output,
  viewChild,
} from '@angular/core';
import { isNil } from '@vfi-ui/util/helpers';
import { debounceTime, Subject } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { FormsModule } from '@angular/forms';
import { AutoGrowDirective } from '../auto-grow.directive.ts/auto-grow.directive';

@Component({
  selector: 'atom-vfi-textarea',
  templateUrl: './vfi-textarea.component.html',
  styleUrls: ['./vfi-textarea.component.scss'],
  imports: [NgClass, NgIf, NzTooltipDirective, FormsModule, AutoGrowDirective],
})
export class VfiTextareaComponent implements OnInit, AfterViewInit {
  readonly inputElm = viewChild<ElementRef>('inputElm');
  readonly value = input<string>(undefined);
  readonly label = input<string>(undefined);
  readonly placeholder = input('');
  readonly labelCheck = input(false);
  readonly labelIcon = input<string>(undefined);
  readonly labelIconTooltip = input<string>(undefined);
  readonly hint = input<string>(undefined);
  readonly required = input(false);
  readonly readOnly = input(false);
  readonly disabled = input(false);
  readonly subtle = input(false);
  readonly success = input(false);
  readonly warning = input(false);
  readonly warningMessage = input<string>(undefined);
  readonly error = input(false);
  readonly errorMessage = input<string>(undefined);
  readonly autofocus = input(false);
  readonly reset = input<Subject<boolean>>(new Subject<boolean>());
  readonly blurChanged = output<boolean>();
  readonly valueChanged = output<string>();
  readonly save = output<string>();
  inputChanged = new Subject<string>();

  constructor() {}

  ngOnInit() {
    this.inputChanged.pipe(debounceTime(300)).subscribe((value) => {
      this.valueChanged.emit(value);
    });
  }

  ngAfterViewInit() {
    // Autofocus on input
    if (this.autofocus()) {
      this.inputElm().nativeElement.focus();
    }
  }

  /**
   * handle blur event
   *
   * @param {FocusEvent} ev
   * @memberof VfiTextareaComponent
   */
  onBlur(ev: FocusEvent) {
    // Prevent blur event from firing when clicking on buttons
    if (isNil(ev.relatedTarget)) {
      this.onCancel();
    }
  }

  /**
   * handle save event
   *
   * @param {string} value
   * @memberof VfiTextareaComponent
   */
  onSave(value: string) {
    this.save.emit(value);
  }

  /**
   * handle cancel event
   *
   * @memberof VfiTextareaComponent
   */
  onCancel() {
    this.blurChanged.emit(true);
  }

  /**
   * handle input change event
   *
   * @param {string} value
   * @memberof VfiTextareaComponent
   */
  onInputChange(value: string) {
    this.inputChanged.next(value);
  }
}
