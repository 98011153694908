import {
  Component,
  OnChanges,
  SimpleChanges,
  input,
  output,
} from '@angular/core';
import { MenuLens, AlarmViews } from '@vfi-ui/models';
import { RouterLink } from '@angular/router';
import { NgClass, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'atom-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  imports: [RouterLink, NgClass, TitleCasePipe],
})
export class TabComponent implements OnChanges {
  readonly url = input<string>(undefined);
  readonly listRoute = input<string>(undefined);
  readonly dashRoute = input<string>(undefined);
  readonly tableRoute = input<string>(undefined);
  readonly lens = input<MenuLens>(undefined);
  readonly listDisabled = input(false);
  readonly dashDisabled = input(false);
  readonly alarmView = input<AlarmViews>(undefined);
  readonly tabChanged = output<string>();

  stateUrl: string;
  alarmViews = AlarmViews;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lens && changes.lens.currentValue) {
      this.stateUrl = `${this.lens().category}/${this.lens().name}/${this.lens().id}`;
    }
  }

  /**
   * show active status based off url
   *
   * @param {string} url
   * @param {string} type
   * @returns {boolean}
   * @memberof TabComponent
   */
  isActive(url: string, type: string): boolean {
    return url?.includes(type) || false;
  }
  /**
   * fire tab changed event
   *
   * @param {string} ev
   * @memberof TabComponent
   */
  change(ev: string) {
    this.tabChanged.emit(ev);
  }
}
