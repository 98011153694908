// TODO: fix
/* eslint-disable @nx/enforce-module-boundaries */
import { BaseComponent } from '@vfi-ui/feature/core';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CriterionSelection } from '@vfi-ui/models';
import { GlobalFiltersState } from '@vfi-ui/state';
import { orderBy } from '@vfi-ui/util/helpers';
import { takeUntil } from 'rxjs';
import { NgFor } from '@angular/common';
import { NzTagComponent } from 'ng-zorro-antd/tag';

@Component({
  selector: 'atom-filter-pills',
  templateUrl: './filter-pills.component.html',
  styleUrls: ['./filter-pills.component.scss'],
  imports: [NgFor, NzTagComponent],
})
export class FilterPillsComponent extends BaseComponent implements OnInit {
  coreFilters: CriterionSelection[];

  constructor(private store: Store) {
    super();
  }

  ngOnInit() {
    this.store
      .select(GlobalFiltersState.getCoreCriterionPills)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (core) => (this.coreFilters = orderBy(core, ['name'], ['asc']))
      );
  }
}
