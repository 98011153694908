import { Component, input } from '@angular/core';
import { VFI_THEME } from '@vfi-ui/models';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'atom-rate-of-change',
  templateUrl: './rate-of-change.component.html',
  styleUrls: ['./rate-of-change.component.scss'],
  imports: [NgIf, NgClass],
})
export class RateOfChangeComponent {
  readonly change = input<number>(undefined);
  readonly inverted = input(false);
  readonly theme = input<VFI_THEME>(VFI_THEME.DARK);
  isNaN = isNaN;
  abs = Math.abs;
  themeType = VFI_THEME;

  constructor() {}
}
