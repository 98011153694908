<div
  #workTicketState
  class="wo-status d-flex align-items-center justify-content-center"
  [ngClass]="getBadgeClasses(ticket(), externalTicket())"
>
  <span class="regular-14 status-text">{{
    externalTicket()
      ? ticket()?.externalStatus
      : getStatusText(ticket()?.state, ticket()?.status)
  }}</span>
</div>
