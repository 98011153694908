import {
  Component,
  ElementRef,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
  input,
  output,
  viewChildren,
  model,
} from '@angular/core';
import {
  AlarmsDataService,
  ExternalSystemsDataService,
  EntityManagementDataService,
  SpaceManagementDataService,
  WorkTicketDataService,
  AssetsDataService,
} from '@vfi-ui/data-access/shared';
import { take } from 'rxjs/operators';
import {
  SelectDropdownOption,
  AlarmProperty,
  SELECT_ALL_VALUE,
  BLANK_VALUE_TEXT,
  BLANK_VALUE,
  Asset,
  AssetType,
  AssetProperty,
} from '@vfi-ui/models';
import { UntypedFormGroup, FormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ExternalSystemsState } from '@vfi-ui/state';
import {
  autoFocusContentEditable,
  isNil,
  get,
  uniq,
  getSpecialLabels,
  getSpecialValues,
  fastParse,
} from '@vfi-ui/util/helpers';
import { NgIf, NgClass } from '@angular/common';
import {
  NgSelectComponent,
  NgHeaderTemplateDirective,
  NgOptionTemplateDirective,
} from '@ng-select/ng-select';
import { DisabledInputComponent } from '../disabled-input/disabled-input.component';
import { Capacitor } from '@capacitor/core';

const NON_ADDABLE_FIELDS = [
  'workTickets',
  'alarmId',
  'entityManagement',
  'entityClass',
  'spaceTypes',
  'assetBuildings',
  'assetFloors',
  'assetRooms',
  'assetIds',
  'assetNames',
  'assetLabels',
];

const ID_FIELDS = [
  'entityManagement',
  'assetBuildings',
  'assetFloors',
  'assetRooms',
];

@Component({
  selector: 'atom-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    NgSelectComponent,
    FormsModule,
    NgHeaderTemplateDirective,
    NgOptionTemplateDirective,
    DisabledInputComponent,
  ],
})
export class SelectMultipleComponent implements OnChanges {
  readonly name = input<string>(undefined);
  readonly placeholder = input<string>(undefined);
  readonly value = model<string[]>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly newDisabled = input(false);
  readonly customQuery = input<string>(undefined);
  readonly customQueryType = input<string>(undefined);
  readonly classes = input<string>(undefined);
  readonly width = input<string>(undefined);
  readonly focused = model<boolean>(undefined);
  readonly addBlanks = input(false);
  readonly customValidator = input<boolean>(undefined);
  readonly useIds = input(false);
  readonly alarmSource = input(false);
  readonly cmmsSource = input(false);
  readonly allowNewValues = input(false);
  readonly useLocation = input(false);
  readonly detailsForm = input<UntypedFormGroup>(undefined);
  readonly showPlaceholder = input(true);
  readonly fullInput = input(false);
  readonly showTooltip = input(false);
  readonly required = input(false);
  readonly requiredPlaceholder = input(false);
  readonly appendTo = input('');
  readonly updatedProperties = input<string[]>(undefined);
  readonly useFullValues = input(false);
  readonly useAllOption = input(false);
  readonly fullValues = input(undefined);
  readonly inputChanged = output<string[]>();
  readonly newValueAdded = output<string[]>();
  readonly fullValuesChanged = output<SelectDropdownOption[]>();
  readonly enterEvent = output<string>();
  readonly focusChanged = output<string>();
  readonly input = viewChildren<ElementRef>('input');
  baseOptions = [];
  options: SelectDropdownOption[] = [];
  copyOptions: SelectDropdownOption[] = [];
  optionsTotalCount = 0;
  isOpen = false;
  loading = false;
  newValues = [];
  isFirstValueChanged = false;
  selectedOptions: SelectDropdownOption[] = [];
  searchValue = null;

  constructor(
    private alarmsDataService: AlarmsDataService,
    private changeDetection: ChangeDetectorRef,
    private externalSystemService: ExternalSystemsDataService,
    private entityManagementService: EntityManagementDataService,
    private spaceManagementService: SpaceManagementDataService,
    private store: Store,
    private workTicketService: WorkTicketDataService,
    private assetsDataService: AssetsDataService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const isValueChange = get(changes.value, 'currentValue', false);
    if (this.disabled() || !this.isFirstValueChanged) {
      if (isValueChange) {
        this.options = this.setOptions(
          this.options,
          this.value(),
          this.fullValues()
        );
        const fullValues = this.fullValues();
        if (ID_FIELDS.includes(this.customQuery()) && fullValues) {
          this.selectedOptions = this.selectedOptions.length
            ? this.selectedOptions
            : fullValues.map((value) => ({
                label: value?.name,
                value: value?.value,
              }));
        }
        this.fetchFields();
        this.isFirstValueChanged = true;
      }
    }

    if (
      changes?.updatedProperties?.currentValue &&
      this.updatedProperties().includes(this.name().toLowerCase())
    ) {
      this.fetchFields();
    }

    if (
      isValueChange &&
      this.useFullValues() &&
      !NON_ADDABLE_FIELDS.includes(this.customQuery())
    ) {
      this.options = this.copyOptions;
    }
  }

  /**
   * fetch custom query if it is available
   *
   * @param {string} [$event]
   * @param {number} [offset=0]
   * @memberof SelectMultipleComponent
   */
  fetchFields($event?: string, offset = 0) {
    this.searchValue = $event;
    this.loading = true;
    const customQuery = this.customQuery();
    if (!customQuery && !this.disabled() && !this.useLocation()) {
      this.getAutoCompleteFields($event, offset);
    } else if (customQuery === 'systemSource') {
      this.getSystemSourceAutoCompleteFields($event);
    } else if (customQuery === 'alarmId') {
      this.getAlarmIdFields($event, offset);
    } else if (customQuery === 'workTickets') {
      this.getWorkTicketsIdFields($event, offset);
    } else if (customQuery === 'entityManagement') {
      this.getEntityClassFields({ search: $event, offset, useId: true });
    } else if (customQuery === 'entityClass') {
      this.getEntityClassFields({ search: $event, offset, useId: false });
    } else if (customQuery === 'spaceTypes') {
      this.getSpaceTypeFields($event, offset);
    } else if (customQuery === 'assetIds') {
      this.getAssetOptions($event, 'assetIds', offset);
    } else if (customQuery === 'assetNames') {
      this.getAssetOptions($event, 'assetNames', offset);
    } else if (customQuery === 'assetBuildings') {
      this.getAssetOptions($event, AssetType.BUILDING, offset, true);
    } else if (customQuery === 'assetFloors') {
      this.getAssetOptions($event, AssetType.FLOOR, offset, true);
    } else if (customQuery === 'assetRooms') {
      this.getAssetOptions($event, AssetType.ROOM, offset, true);
    } else if (customQuery === 'assetLabels') {
      this.getAssetPropertyOptions($event, 'LABEL', offset);
    } else if (customQuery === 'points') {
      this.getPointOptions($event, offset);
    }
    this.focusChanged.emit(this.name());
  }

  /**
   * calls alarms data service to query for possible values for auto-complete fields
   *
   * @param {string} event
   * @memberof SelectMultipleComponent
   */
  getAutoCompleteFields(event: string, offset: number) {
    const type = this.customQueryType() || this.name();
    const value = event || '';

    if (type === 'building') {
      this.alarmsDataService
        .getEntities(type, offset, value)
        .pipe(take(1))
        .subscribe((data) => {
          this.optionsTotalCount = data?.entityCount;
          this.formatAutoCompleteValues(data?.entities, offset, event);
        });
    } else {
      this.alarmsDataService
        .getAlarmAutoCompleteWithId({
          property: type,
          offset,
          value,
          loadAll: null,
          customHeader: null,
          dbName: this.customQueryType(),
        })
        .pipe(take(1))
        .subscribe((data) => {
          this.optionsTotalCount = data?.count;
          this.formatAutoCompleteValues(data.items, offset, event);
        });
    }
  }

  /**
   * calls the external system service to query for system auto-complete fields
   *
   * @param {string} event
   * @memberof SelectMultipleComponent
   */
  getSystemSourceAutoCompleteFields(event: string) {
    this.externalSystemService
      .getSourceSystemStatus(event)
      .pipe(take(1))
      .subscribe((data) => {
        this.options = data.map(({ name, displayName }) => ({
          label: displayName,
          value: name,
        }));
        this.loading = false;
        this.addBlankOption();
        this.changeDetection.detectChanges();
      });
  }

  /**
   * calls the alarms service to query for alarms
   *
   * @param {string} event
   * @param {number} offset
   * @memberof SelectMultipleComponent
   */
  getAlarmIdFields(event: string, offset: number) {
    if (event) {
      if (event?.charAt(0).toLowerCase() === 'a') {
        event = event.slice(1);
      }
    }

    this.alarmsDataService
      .getAlarm(event, offset)
      .pipe(take(1))
      .subscribe((data) => {
        const alarmIds =
          offset > 0 ? [...this.baseOptions, ...data.Alarm] : data?.Alarm;
        this.baseOptions = fastParse(alarmIds);
        this.optionsTotalCount = data?.count;
        this.options = alarmIds.map(({ displayId }) => ({
          label: displayId,
          value: displayId,
        })) as SelectDropdownOption[];
        this.loading = false;
        this.changeDetection.detectChanges();
      });
  }

  /**
   * call data service to fetch work ticket options
   *
   * @param {string} event
   * @param {number} offset
   * @memberof SelectMultipleComponent
   */
  getWorkTicketsIdFields(event: string, offset: number) {
    let id;
    if (event) {
      if (event?.charAt(0).toLowerCase() === 'w') {
        id = +event.slice(1);
      }
    }
    this.loading = true;
    this.workTicketService.getWorkTicketIds(id, offset).subscribe((data) => {
      const workIds =
        offset > 0 ? [...this.baseOptions, ...data.items] : data?.items;
      this.baseOptions = fastParse(workIds);
      this.optionsTotalCount = data?.count;
      this.options = workIds.map(({ displayId }) => ({
        label: displayId,
        value: displayId,
      })) as SelectDropdownOption[];
      this.loading = false;
      this.changeDetection.detectChanges();
    });
  }

  /**
   * calls entity service to fetch entity classes
   *
   * @param {{
   *     search: string;
   *     offset: number;
   *     useId?: boolean;
   *   }} {
   *     search,
   *     offset,
   *     useId,
   *   }
   * @memberof SelectMultipleComponent
   */
  getEntityClassFields({
    search,
    offset,
    useId,
  }: {
    search: string;
    offset: number;
    useId?: boolean;
  }) {
    search = isNil(search) ? '' : search;
    this.entityManagementService
      .fetchEntityClasses(search, offset)
      .subscribe((data) => {
        const entityClasses =
          offset > 0
            ? [...this.baseOptions, ...data.entityClasses]
            : data.entityClasses;
        this.baseOptions = fastParse(entityClasses);
        this.optionsTotalCount = data?.entityClassCount;
        this.options = entityClasses.map((d) => ({
          label: d?.name,
          value: useId ? String(d?.id) : d?.name,
        })) as SelectDropdownOption[];
        this.loading = false;
        this.changeDetection.detectChanges();
      });
  }

  /**
   * calls data service to fetch space type options
   *
   * @param {string} search
   * @param {number} offset
   * @memberof SelectMultipleComponent
   */
  getSpaceTypeFields(search: string, offset: number) {
    search = isNil(search) ? '' : search;
    this.spaceManagementService
      .fetchSpaceTypes(offset, search)
      .subscribe((r) => {
        const spaceTypes =
          offset > 0 ? [...this.baseOptions, ...r.spaceTypes] : r.spaceTypes;
        this.baseOptions = fastParse(spaceTypes);
        this.optionsTotalCount = r?.spaceTypeCount;
        this.options = spaceTypes.map((spaceType) => ({
          label: spaceType?.name,
          value: spaceType?.name,
        })) as SelectDropdownOption[];
        this.loading = false;
        this.changeDetection.detectChanges();
      });
  }

  /**
   * emits value when changed
   *
   * @param {string} value
   * @memberof SelectMultipleComponent
   */
  valueChanged(ev: any, value: string[]) {
    if (Array.isArray(ev)) {
      value = this.formatChangedValue(ev, value);

      if (ID_FIELDS.includes(this.customQuery())) {
        this.selectedOptions = ev;
        this.fullValuesChanged.emit(ev);
      } else {
        let opt = this.useFullValues() ? this.copyOptions : this.options;
        opt = this.handleMissingSearchedOptions(opt, value);
        const fullValues = opt.filter((v) => value.includes(v.value));
        this.fullValuesChanged.emit(fullValues);
      }

      if (Array.isArray(value) && value.length === 0) {
        this.value.set(null);
        this.focused.set(false);
      }
      if (this.allowNewValues()) {
        this.newValues = this.removeNewValues(value, this.newValues);
        this.newValueAdded.emit(this.newValues);
      }
      this.inputChanged.emit(value);
    }
  }

  /**
   * add missing searched options to options
   *
   * @param {SelectDropdownOption[]} opt
   * @param {string[]} value
   * @returns
   * @memberof SelectMultipleComponent
   */
  handleMissingSearchedOptions(opt: SelectDropdownOption[], value: string[]) {
    const missingValues = value.filter((v) => !opt.find((o) => o.value === v));
    missingValues.forEach((v) => opt.push({ label: v, value: v }));
    return opt;
  }

  /**
   * format value changed data
   *
   * @param {*} ev
   * @param {string[]} value
   * @returns
   * @memberof SelectMultipleComponent
   */
  formatChangedValue(ev: any, value: string[]) {
    if (!this.alarmSource() && value.includes(undefined)) {
      return typeof ev[0] === 'object'
        ? ev.map((e) => e.label)
        : (ev as string[]);
    }
    return value;
  }

  /**
   * handles select open change
   *
   * @param {boolean} open
   * @memberof SelectMultipleComponent
   */
  handleOpenChange(open: boolean) {
    this.isOpen = false;
    this.focused.set(open);
    if (!Capacitor.isNativePlatform()) {
      autoFocusContentEditable(`ng-dropdown-input-${this.name()}`, false);
    }
  }

  /**
   * handle select close change
   *
   * @memberof SelectMultipleComponent
   */
  handleCloseChange() {
    this.focused.set(false);
    this.baseOptions = [];
    this.optionsTotalCount = 0;
    this.searchValue = null;
  }

  /**
   * add new tag to properties
   *
   * @param {string} el
   * @memberof SelectMultipleComponent
   */
  addNewTag(el: string | { label: string }) {
    if (
      !this.alarmSource() &&
      !this.cmmsSource() &&
      !NON_ADDABLE_FIELDS.includes(this.customQuery())
    ) {
      const options = this.copyOptions.map((o) => o.value);
      const updatedOptions = uniq(
        this.options.map((o) => getSpecialLabels(o.value, this.name()))
      );
      if (typeof el === 'object') {
        el = el.label;
      }
      this.options = updatedOptions.map((o) => ({
        label: o,
        value: getSpecialValues(o, this.name()),
      }));
      if (!options.includes(el)) {
        if (typeof el === 'string') {
          this.newValues.push(el);
          this.newValueAdded.emit(this.newValues);
        }
      }
    }
  }

  /**
   * handle select scroll event
   *
   * @param {string} search
   * @memberof SelectMultipleComponent
   */
  onScroll(search: string) {
    if (this.baseOptions.length !== this.optionsTotalCount) {
      this.fetchFields(search, this.baseOptions.length);
    }
  }

  /**
   * fetch asset options
   *
   * @private
   * @param {string} search
   * @param {string} type
   * @param {number} [offset=0]
   * @param {boolean} [useType=false]
   * @memberof SelectMultipleComponent
   */
  private getAssetOptions(
    search: string,
    type: string,
    offset = 0,
    useType = false
  ) {
    const assetType = useType ? type : null;
    this.assetsDataService
      .getAssetOptions(search, offset, assetType)
      .subscribe((r) => {
        const assets =
          offset > 0 ? [...this.baseOptions, ...r.assets] : r.assets;
        this.baseOptions = fastParse(assets);
        this.optionsTotalCount = r?.assetCount;
        this.options = this.formatAssetOptions(type, assets, useType);
        this.loading = false;
        this.changeDetection.detectChanges();
      });
  }

  /**
   * fetch asset property options
   *
   * @private
   * @param {string} search
   * @param {string} type
   * @param {number} [offset=0]
   * @memberof SelectMultipleComponent
   */
  private getAssetPropertyOptions(search: string, type: string, offset = 0) {
    this.assetsDataService
      .getAssetPropertyOptions(search, offset, type)
      .subscribe((r) => {
        const formattedOptions = r.assetProperties.map(({ value }) => ({
          label: value,
          value,
        }));
        const baseOptions =
          offset > 0
            ? [...this.baseOptions, ...formattedOptions]
            : formattedOptions;
        this.baseOptions = baseOptions;
        this.optionsTotalCount = r?.assetPropertyCount;
        this.options = baseOptions;
        this.loading = false;
        this.changeDetection.detectChanges();
      });
  }

  /**
   * Gets the point data
   *
   * @private
   * @param {string} search
   * @param {number} [offset=0]
   * @memberof SelectMultipleComponent
   */
  private getPointOptions(search: string, offset = 0) {
    this.alarmsDataService.getPointOptions(search, offset).subscribe((r) => {
      const formattedOptions = r.points.map((value) => ({
        label: value,
        value,
      }));
      const baseOptions =
        offset > 0
          ? [...this.baseOptions, ...formattedOptions]
          : formattedOptions;
      this.baseOptions = baseOptions;
      this.optionsTotalCount = r?.pointCount;
      this.options = baseOptions;
      this.loading = false;
      this.changeDetection.detectChanges();
    });
  }

  /**
   * format asset options
   *
   * @private
   * @param {string} type
   * @param {(Asset[] | AssetProperty[])} assets
   * @param {boolean} [useType=false]
   * @returns
   * @memberof SelectMultipleComponent
   */
  private formatAssetOptions(
    type: string,
    assets: Asset[] | AssetProperty[],
    useType = false
  ) {
    let options = [];
    if (type === 'assetNames' || useType) {
      options = assets.map((asset) => ({
        label: asset?.name,
        value: asset?.name,
      })) as SelectDropdownOption[];
    } else if (type === 'assetIds') {
      options = assets.map((asset) => ({
        label: String(asset?.id),
        value: String(asset?.id),
      })) as SelectDropdownOption[];
    }
    return options;
  }

  /**
   * remove new values if they are not part of selected values
   *
   * @private
   * @param {string[]} values
   * @param {string[]} newValues
   * @return {*}  {string[]}
   * @memberof SelectMultipleComponent
   */
  private removeNewValues(values: string[], newValues: string[]): string[] {
    return newValues.filter((option) => values.includes(option));
  }

  /**
   * add blank option if the input is provided
   *
   * @private
   * @memberof SelectMultipleComponent
   */
  private addBlankOption() {
    if (this.addBlanks()) {
      this.options.unshift({
        label: BLANK_VALUE_TEXT,
        value: BLANK_VALUE,
      });
    }
  }

  /**
   * add select all option
   *
   * @private
   * @param {string} name
   * @memberof SelectMultipleComponent
   */
  private addAllOption(name: string) {
    if (this.useAllOption()) {
      this.options.unshift({
        label: `All ${name}s`,
        value: SELECT_ALL_VALUE,
      });
    }
  }

  /**
   * add selected value to the options list if they are missing
   *
   * @private
   * @memberof SelectMultipleComponent
   */
  private addSelectedValues() {
    (this.value() || []).forEach((val) => {
      const hasValue = this.options.find((option) => option.label === val);
      if (!hasValue && val !== SELECT_ALL_VALUE && val !== BLANK_VALUE) {
        this.options.unshift({
          label: val,
          value: val,
        });
      }
    });
  }

  /**
   * format auto complete values
   *
   * @private
   * @param {AlarmProperty[]} data
   * @param {number} [offset=0]
   * @param {string} [searchTerm]
   * @memberof SelectMultipleComponent
   */
  private formatAutoCompleteValues(
    data: AlarmProperty[],
    offset = 0,
    searchTerm?: string
  ) {
    const entities = offset > 0 ? [...this.baseOptions, ...data] : data;
    this.baseOptions = fastParse(entities);
    this.options = entities.map((r) => ({
      label: r.value || r.name,
      value: r.value || r.name,
    }));
    this.loading = false;
    this.addSelectedValues();
    this.addBlankOption();
    this.addAllOption(this.name());

    if (isNil(searchTerm) || (!isNil(searchTerm) && searchTerm.length === 0)) {
      this.copyOptions = this.options;
    }

    this.changeDetection.detectChanges();
  }

  /**
   * set default options
   *
   * @private
   * @param {SimpleChanges} changes
   * @param {SelectDropdownOption[]} options
   * @returns
   * @memberof SelectMultipleComponent
   */
  private setOptions(
    options: SelectDropdownOption[],
    value: string[],
    fullValues?: { name: string; value: string }[]
  ) {
    const customQuery = this.customQuery();
    if (this.alarmSource()) {
      options = this.formatAlarmSourceOptions(value);
    } else if (this.cmmsSource()) {
      options = this.formatCMMSSourceOptions(value);
    } else if (customQuery === 'alarmId') {
      options = value.map((v) => ({
        label: typeof v === 'number' ? `A${v}` : v,
        value: v,
      }));
    } else if (customQuery === 'entityManagement' && fullValues) {
      options = fullValues.map((fv) => ({
        label: fv.name,
        value: String(fv.value),
      }));
    } else if (!this.useIds()) {
      options = this.formatIdOptions(value);
    } else {
      options = this.formatOptions(options, value);
    }
    return options;
  }

  /**
   * format options for alarm source
   *
   * @private
   * @param {SimpleChanges} changes
   * @returns
   * @memberof SelectMultipleComponent
   */
  private formatAlarmSourceOptions(value: string[]) {
    let options = [];
    const sources = this.store.selectSnapshot(
      ExternalSystemsState.getIntegrations
    );
    options = sources.map((source) => ({
      value: source.name,
      label: source.displayName,
    }));
    options = value.map((v) => {
      const opt = options.find((o) => o.value === v);
      return {
        label: opt?.label,
        value: opt?.value,
      };
    });
    return options;
  }
  /**
   * format options for work source
   *
   * @private
   * @param {string[]} value
   * @return {*}
   * @memberof SelectMultipleComponent
   */
  private formatCMMSSourceOptions(value: string[]) {
    let options = [];
    const sources = this.store.selectSnapshot(ExternalSystemsState.getCmms);
    options = sources.map((source) => ({
      value: source.name,
      label: source.displayName,
    }));
    options = value.map((v) => {
      const opt = options.find((o) => o.value === v);
      return {
        label: opt?.label,
        value: opt?.value,
      };
    });
    return options;
  }

  /**
   * format options for ids
   *
   * @private
   * @param {SimpleChanges} changes
   * @returns
   * @memberof SelectMultipleComponent
   */
  private formatIdOptions(value: string[]) {
    if (value) {
      return value.map((val) => ({
        label: val === BLANK_VALUE ? BLANK_VALUE_TEXT : val,
        value: val,
      }));
    }
  }

  /**
   * format options
   *
   * @private
   * @param {SimpleChanges} changes
   * @param {SelectDropdownOption[]} options
   * @returns
   * @memberof SelectMultipleComponent
   */
  private formatOptions(options: SelectDropdownOption[], value: string[]) {
    return value
      .map((v) => {
        const opt = options.find((o) => o.value === v);
        if (!opt) {
          return null;
        }
        return {
          label: opt.label,
          value: opt.value,
        };
      })
      .filter((i) => i);
  }
}
