import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AuthService } from '@vfi-ui/data-access/shared';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'vfi-auth-mobile-sso-login',
  templateUrl: './mobile-sso-login.component.html',
  styleUrls: ['./mobile-sso-login.component.scss'],
})
export class MobileSsoLoginComponent {
  constructor(
    public readonly authService: AuthService,
    public activeRoute: ActivatedRoute
  ) {
    this.authService.getRedirectResult().subscribe(async (cred) => {
      if (cred && !Capacitor.isNativePlatform()) {
        await this.authService.signOut(); // Need to sign out here to trigger this sub to run if user logs out and back in again
        const encodedCreds = btoa(JSON.stringify(cred));
        window.location.search = `?credential=${encodedCreds}`;
      } else if (!cred) {
        const email = activeRoute?.snapshot?.queryParams['email'];
        if (email) {
          this.checkEmail({ email });
        }
      }
    });
  }

  /**
   * Checks whether user should use sso or not
   *
   * @param {*} value
   * @memberof LoginComponent
   */
  async checkEmail(value: any) {
    if (value.email) {
      const loginInfo = await firstValueFrom(
        this.authService.checkEmailTenant(value.email)
      );
      if (loginInfo.isSso) {
        await this.authService.signInWithRedirect(
          loginInfo.tenantId,
          loginInfo.providerId,
          value.email
        );
      }
    }
  }
}
