<div [ngClass]="{ 'cursor-not-allowed': disabled() }">
  <div
    class="vfi-input"
    [ngClass]="{
      disabled: disabled()
    }"
  >
    <div
      *ngIf="label()"
      class="vfi-label"
    >
      <span [class.yellow-6]="required() && !readOnly()">{{ label() }}</span>
      <i
        *ngIf="labelIcon()"
        class="ml-1 mr-1 {{ labelIcon() }}"
        nz-tooltip
        [nzTooltipTitle]="labelIconTooltip()"
      ></i>
      <i
        *ngIf="labelCheck()"
        class="ml-1 fa-solid fa-check check-green"
        nz-tooltip
        nzTooltipTitle="Verified"
      ></i>
    </div>
    <div
      class="input-icons position-relative"
      [ngClass]="{ subtle: subtle(), success: success(), warning: warning(), error: error(), 'pointer-none': readOnly() || disabled() }"
    >
      <textarea
        [ngModel]="value()"
        (ngModelChange)="onInputChange($event)"
        [ngClass]="{ 'disabled-input': disabled() }"
        class="vfi-textarea"
        [class.readOnly]="readOnly()"
        [maximumHeight]="1000"
        [minHeight]="48"
        maxlength="1000"
        [placeholder]="placeholder()"
        [reset]="reset()"
        atomAutoGrow
        [isReadOnly]="readOnly()"
        tabindex="-1"
      ></textarea>
    </div>
    <p
      *ngIf="hint() && !warning() && !error()"
      class="mb-0 hint-text"
    >{{ hint() }}</p>
    <p
      *ngIf="warning() && warningMessage() && !subtle()"
      class="mb-0 hint-text"
    >
      {{ warningMessage() || hint() }}
    </p>
    <p
      *ngIf="error() && errorMessage() && !subtle()"
      class="mb-0 error-text"
    >
      {{ errorMessage() || hint() }}
    </p>
  </div>
</div>
