<p class="fw-500 regular-14 dm40">Alarm Details</p>
<div
  *ngFor="let field of equipmentFields"
  class="d-flex align-items-center field-container"
>
  <ng-container *ngTemplateOutlet="fields; context: { field }"></ng-container>
</div>
<div class="d-flex align-items-center field-container">
  <p class="label">Alarm Point</p>
  <div class="select-container">
    {{ alarm().point || '-' }}
  </div>
</div>
<hr />

<p class="fw-500 regular-14 dm40">Alarm Classification</p>
<div class="d-flex align-items-center field-container">
  <p class="label">Is Service Failure Alarm</p>
  <div class="select-container">
    <atom-vfi-select
      placeholder="-"
      [value]="values?.['isServiceImpacting']?.label"
      [subtle]="true"
      [options]="booleanOptions"
      [showSearch]="false"
      [readOnly]="readOnly()"
      (optionSelected)="onOptionSelect('isServiceImpacting', $event)"
    ></atom-vfi-select>
  </div>
  <i
    *ngIf="alarm()?.serviceImpactLastModifiedBy"
    class="fa-solid fa-user-check green-7 ml-1"
  ></i>
</div>

<div class="d-flex align-items-center field-container">
  <p class="label">Alarm Priority</p>
  <div class="select-container">
    <atom-vfi-select
      placeholder="-"
      [value]="values?.['priority']?.label"
      [iconLeft]="
        values?.['priority']?.label
          ? 'fa-solid fa-bell ' +
            priorityFontColors[values?.['priority']?.label]
          : ''
      "
      [options]="priorityOptions"
      [showSearch]="false"
      [subtle]="true"
      [readOnly]="readOnly()"
      [canClear]="true"
      (optionSelected)="onOptionSelect('priority', $event)"
      (cleared)="onCleared('priority')"
    ></atom-vfi-select>
  </div>
  <i
    *ngIf="alarm()?.priorityLastModifiedBy"
    class="fa-solid fa-user-check green-7 ml-1"
  ></i>
</div>

<div
  *ngFor="let field of alarmFields"
  class="d-flex align-items-center field-container"
>
  <ng-container *ngTemplateOutlet="fields; context: { field }"></ng-container>
</div>

<hr />

<ng-template
  #fields
  let-field="field"
>
  <p class="label">{{ fieldNameMap.get(field?.name) }}</p>
  <div
    class="select-container"
    *ngIf="!multiSelectFields.includes(field?.name)"
  >
    <atom-vfi-select
      placeholder="-"
      [value]="values?.[field?.name]?.label"
      [options]="dropdownOptions"
      [showSearch]="true"
      [subtle]="true"
      [loading]="optionsLoading"
      [readOnly]="readOnly()"
      [canClear]="true"
      [autofocus]="true"
      (optionSelected)="onOptionSelect(field?.name, $event)"
      (dropdownVisibleChanged)="onDropdownVisibleChange(field?.name, $event)"
      (cleared)="onCleared(field?.name)"
    ></atom-vfi-select>
  </div>
  <div
    class="select-container h-100"
    *ngIf="multiSelectFields.includes(field?.name)"
  >
    <atom-vfi-multi-select
      placeholder="-"
      [value]="getMultiSelectValue(values?.[field?.name])"
      [options]="dropdownOptions"
      [showSearch]="true"
      [subtle]="true"
      [loading]="optionsLoading"
      [readOnly]="readOnly()"
      [canClear]="true"
      [autofocus]="true"
      [useLabels]="true"
      (optionSelected)="onMultiOptionSelect(field?.name, $event)"
      (dropdownVisibleChanged)="onDropdownVisibleChange(field?.name, $event)"
      (cleared)="onCleared(field?.name)"
    ></atom-vfi-multi-select>
  </div>
  <i
    *ngIf="confidence[field?.name]"
    class="fa-solid fa-user-check green-7 ml-1"
  ></i>
</ng-template>
