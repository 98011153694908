<label
  nz-checkbox
  [ngModel]="value()"
  [nzDisabled]="disabled()"
  (ngModelChange)="changed($event)"
>{{ label() }}
</label>
<i
  *ngIf="labelIcon()"
  class="ml-1 mr-1 iregular-12 {{ labelIcon() }}"
  nz-tooltip
  [nzTooltipTitle]="labelIconTooltip()"
></i>
<i
  *ngIf="labelCheck()"
  class="ml-1 fa-solid fa-check check-green iregular-12"
  nz-tooltip
  nzTooltipTitle="Verified"
></i>
