import { Apollo } from 'apollo-angular';
import { MenuService } from './menu.service';
import {
  CREATE_NOTIFICATION_QUERY,
  GET_NOTIFICATIONS_QUERY,
  DELETE_NOTIFICATION_QUERY,
  UPDATE_NOTIFICATION_QUERY,
  GET_ALL_NOTIFICATIONS,
  GET_NOTIFICATION_AUDIT_LOGS,
  TEST_WEBHOOK_NOTIFICATION_MUTATION,
} from './../queries/notification.query';
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import {
  NotificationData,
  NOTIFICATION_ORDER,
  LensParent,
  ERROR_CREATE_NOTIFICATION,
  ERROR_DELETE_NOTIFICATION,
  ERROR_UPDATE_NOTIFICATION,
  ERROR_GET_NOTIFICATION,
  ERROR_UNSUBSCRIBE_NOTIFICATION,
  NotificationAuditLog,
  TestNotificationResult,
  ERROR_TEST_NOTIFICATION_WEBHOOK_URL,
} from '@vfi-ui/models';
import { catchError, map, delay, switchMap, filter } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService,
    private menuService: MenuService
  ) {}

  /**
   * calls mutation to create notification
   *
   * @param {NotificationData} data
   * @returns
   * @memberof NotificationDataService
   */
  createNotification(data: NotificationData) {
    return this.apollo
      .mutate({
        mutation: CREATE_NOTIFICATION_QUERY,
        variables: { data },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_CREATE_NOTIFICATION);
          return throwError(error);
        })
      );
  }

  /**
   * calls mutation to delete notification
   *
   * @param {NotificationData} data
   * @returns
   * @memberof NotificationDataService
   */
  deleteNotification(id: string) {
    return this.apollo
      .mutate({
        mutation: DELETE_NOTIFICATION_QUERY,
        variables: { id },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_DELETE_NOTIFICATION);
          return throwError(error);
        })
      );
  }

  /**
   * calls mutation to update notificaion
   *
   * @param {string} id
   * @param {NotificationData} data
   * @param {AlarmProperty[]} [alarmProperties]
   * @returns
   * @memberof NotificationDataService
   */
  updateNotification(id: string, data: NotificationData) {
    return this.apollo
      .mutate({
        mutation: UPDATE_NOTIFICATION_QUERY,
        variables: { id, data },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_NOTIFICATION);
          return throwError(error);
        })
      );
  }

  /**
   * query for notifications
   *
   * @param {*} where
   * @returns {Observable<NotificationData>}
   * @memberof NotificationDataService
   */
  getNotifications(where): Observable<NotificationData[]> {
    const options = {
      offset: 0,
      order: NOTIFICATION_ORDER,
      where,
    };
    return this.apollo
      .query<{ lensNotificationsAndCount: { items: NotificationData[] } }>({
        fetchPolicy: 'network-only',
        query: GET_NOTIFICATIONS_QUERY,
        variables: { options },
      })
      .pipe(
        filter((d) => !!d),
        map(
          (notifications) => notifications.data.lensNotificationsAndCount.items
        ),
        catchError((error) => {
          this.notification.showError(ERROR_GET_NOTIFICATION);
          return throwError(error);
        })
      );
  }

  /**
   * Gets all the notifications
   *
   * @return {*}  {Observable<NotificationData[]>}
   * @memberof NotificationDataService
   */
  getAllNotifications(): Observable<NotificationData[]> {
    return this.apollo
      .query<{ lensNotificationsAndCount: { items: NotificationData[] } }>({
        fetchPolicy: 'network-only',
        query: GET_ALL_NOTIFICATIONS,
        variables: { options: { where: { disableUserFilter: true } } },
      })
      .pipe(
        filter((d) => !!d),
        map(
          (notifications) => notifications.data.lensNotificationsAndCount.items
        ),
        catchError((error) => {
          this.notification.showError(ERROR_GET_NOTIFICATION);
          return throwError(error);
        })
      );
  }

  /**
   * fetch notification audit logs
   *
   * @returns {Observable<NotificationAuditLog[]>}
   * @memberof NotificationDataService
   */
  getNotificationAuditLogs(): Observable<NotificationAuditLog[]> {
    return this.apollo
      .query<{ lensNotificationAuditLogs: NotificationAuditLog[] }>({
        fetchPolicy: 'network-only',
        query: GET_NOTIFICATION_AUDIT_LOGS,
      })
      .pipe(
        filter((d) => !!d),
        map((notifications) => notifications?.data?.lensNotificationAuditLogs),
        catchError((error) => {
          this.notification.showError(ERROR_GET_NOTIFICATION);
          return throwError(error);
        })
      );
  }

  /**
   * validates lens and notification to unsubscribe from notifications
   *
   * @param {string} id
   * @param {string} lensId
   * @returns {Observable<NotificationData[]>}
   * @memberof NotificationDataService
   */
  unsubNotification(
    id: string,
    lensId: string
  ): Observable<NotificationData[]> {
    return this.menuService
      .getCustomLens(
        {
          ids: [lensId],
        },
        LensParent.ACTIVE_ALARMS,
        true
      )
      .pipe(
        filter((d) => !!d),
        delay(1000),
        map((len) => {
          if (!len.isActive) {
            throwError('error');
          }
          return len;
        }),
        filter((len) => len && len.isActive),
        switchMap(() => {
          const where = {
            ids: [id],
          };
          return this.getNotifications(where);
        }),
        catchError((err) => {
          this.notification.showError(ERROR_UNSUBSCRIBE_NOTIFICATION);
          return throwError(err);
        })
      );
  }

  /**
   * Sends a test webhook notification and returns the result.
   *
   * @param {number} alarmId
   * @param {string} lensId
   * @param {string} url
   * @returns {Observable<TestNotificationResult>}
   * @memberof NotificationDataService
   */
  testWebhookNotification({
    alarmId,
    lensId,
    url,
  }: {
    alarmId: number;
    lensId: string;
    url: string;
  }): Observable<TestNotificationResult> {
    return this.apollo
      .mutate<{ testWebhookNotification: TestNotificationResult }>({
        mutation: TEST_WEBHOOK_NOTIFICATION_MUTATION,
        variables: { alarmId, lensId, url },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res.data.testWebhookNotification),
        catchError((err) => {
          this.notification.showError(ERROR_TEST_NOTIFICATION_WEBHOOK_URL);
          return throwError(() => err);
        })
      );
  }
}
