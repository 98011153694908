import { CustomerSelectorComponent } from './customer-selector/customer-selector.component';
import { RouterStateSnapshot, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import {
  LoginGuard,
  SsoGuard,
  AuthGuard,
  MfaGuard,
} from '@vfi-ui/data-access/shared';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { VersionSelectorComponent } from './version-selector/version-selector.component';
import { MfaComponent } from './mfa/mfa.component';
import { LogoutComponent } from './logout/logout.component';
import { MobileSsoLoginComponent } from './mobile-sso-login/mobile-sso-login.component';
import { inject } from '@angular/core';

export const AUTH_ROUTES: Routes = [
  {
    path: 'login',
    canActivateChild: [() => inject(SsoGuard).canActivateChild()],
    children: [
      {
        path: '',
        component: LoginComponent,
        canActivate: [() => inject(LoginGuard).canActivate()],
      },
    ],
    canDeactivate: [() => inject(SsoGuard).canDeactivate()],
  },
  {
    path: 'mobileSsoLogin',
    component: MobileSsoLoginComponent,
  },
  {
    path: 'mfa',
    canActivate: [() => inject(MfaGuard).canActivate()],
    component: MfaComponent,
  },
  {
    path: 'reset',
    component: ResetPasswordComponent,
  },
  {
    path: 'register',
    canActivate: [() => inject(LoginGuard).canActivate()],
    component: RegisterComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'change_password',
    component: ChangePasswordComponent,
  },
  {
    path: 'select',
    canActivate: [
      (state: RouterStateSnapshot) => inject(AuthGuard).canActivate(state),
    ],
    component: CustomerSelectorComponent,
  },
  {
    path: 'version',
    component: VersionSelectorComponent,
  },
  // {
  //   path: 'forgot-password',
  //   component: ForgotPasswordComponent,
  // },
  // {
  //   path: 'logout',
  //   component: LogoutComponent,
  // },
  // {
  //   path: 'mail-confirm',
  //   component: MailConfirmComponent,
  // },
  // {
  //   path: 'register',
  //   component: RegisterComponent,
  // },
  // {
  //   path: 'reset-password',
  //   component: ResetPasswordComponent,
  // },
  // {
  //   path: 'signup',
  //   component: SignupComponent,
  // },
];
