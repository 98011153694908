import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RedirectCommand,
  GuardResult,
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { get } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard {
  constructor(
    private router: Router,
    private deviceDetectorService: DeviceDetectorService
  ) {}
  /**
   * determines if the application is run on mobile device
   *
   * @returns {GuardResult}
   * @memberof MobileGuard
   */
  canActivateChild(route: ActivatedRouteSnapshot): GuardResult {
    const isMobile = this.deviceDetectorService.isMobile();
    const isTablet = this.deviceDetectorService.isTablet();
    if (isMobile || isTablet || Capacitor.isNativePlatform()) {
      const url = get(route, '_routerState.url', '/alarms');
      return new RedirectCommand(this.router.parseUrl(`m${url}`));
    }
    return true;
  }
}
