import { Component, input } from '@angular/core';
import { NgIf, NgClass, DecimalPipe } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'atom-badge-performance-metric',
  templateUrl: './badge-performance-metric.component.html',
  styleUrls: ['./badge-performance-metric.component.scss'],
  imports: [NgIf, NzTooltipDirective, NgClass, DecimalPipe],
})
export class BadgePerformanceMetricComponent {
  readonly percentage = input<number>(undefined);
  readonly delta = input<number>(undefined);
  readonly show = input(false);
  isFinite = Number.isFinite;
  abs = Math.abs;

  constructor() {}
}
