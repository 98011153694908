import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { inject } from '@angular/core';
import { environment } from '@vfi-ui/environments/environment';

export const GRAPHQL_PROVIDER = provideApollo(() => {
  const httpLink = inject(HttpLink);
  const httpLinkDef = httpLink.create({ uri: environment.backend });
  return {
    link: ApolloLink.from([httpLinkDef]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  };
});
