import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { LensType, MenuLens } from '@vfi-ui/models';
import {
  LensState,
  ResetOnlyCoreCriterionFilters,
  SetCoreGlobalFilters,
  SetCurrentlySelectedLens,
  SetGlobalCurrentLens,
  SetSortFilterType,
  TotalResetGlobalFilters,
} from '@vfi-ui/state';
import { fetchLensTeamName, isEmpty } from '@vfi-ui/util/helpers';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssetsResolver {
  @Select(LensState.getLenses) lenses$: Observable<MenuLens[]>;

  constructor(
    private store: Store,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.store.dispatch(new SetSortFilterType('assets'));
    const teamParam = route.paramMap.get('team');
    const lensParam = route.paramMap.get('lens');
    return this.waitforLensToUpdate(lensParam, teamParam).pipe(
      tap((lens) => {
        if (lens) {
          this.store.dispatch([
            new TotalResetGlobalFilters(false),
            new ResetOnlyCoreCriterionFilters(),
          ]);
          this.store.dispatch(new SetCurrentlySelectedLens(lens.id));
          this.store.dispatch(
            new SetGlobalCurrentLens({
              parent: lens.category,
              child: lens.name,
              parentId: lens.id,
              isCustom: lens?.isCustom,
              category: lens.category,
              type: 'asset',
              description: lens.description,
            })
          );
          this.store.dispatch(
            new SetCoreGlobalFilters(lens.displayOptions.criterion)
          );
        } else {
          this.router.navigate(['/assets']);
        }
      })
    );
  }

  waitforLensToUpdate(lens: string, team: string): Observable<MenuLens> {
    return this.lenses$.pipe(
      filter(
        (lenses) =>
          lenses.length > 0 &&
          !isEmpty(
            lenses.find((l) => l.name === lens && l.type === LensType.ASSET)
          )
      ),
      map((lenses) =>
        lenses.find((x) => x.name === lens && fetchLensTeamName(x) === team)
      ),
      take(1)
    );
  }
}
