import { Component, input } from '@angular/core';
import { WorkResolutionState } from '@vfi-ui/models';
import { NgIf } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'nuclei-work-instance-icon',
  templateUrl: './work-instance-icon.component.html',
  styleUrls: ['./work-instance-icon.component.scss'],
  imports: [NgIf, NzTooltipDirective],
})
export class WorkInstanceIconComponent {
  readonly workState = input<WorkResolutionState>(undefined);
  readonly size = input(14);
  workResolutionState = WorkResolutionState;

  constructor() {}
}
