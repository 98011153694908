import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  input,
  model,
  output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CmmsService } from '@vfi-ui/data-access/shared';
import {
  ExternalCmmsField,
  ExternalCmmsFieldWhere,
  MODAL_Z_INDEX,
} from '@vfi-ui/models';
import { get } from '@vfi-ui/util/helpers';
import { Observable, map } from 'rxjs';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { NgClass, AsyncPipe } from '@angular/common';
import { DropdownMenuComponent, VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'nuclei-create-cmms-relation',
  templateUrl: './create-cmms-relation.component.html',
  styleUrls: ['./create-cmms-relation.component.scss'],
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    DropdownMenuComponent,
    VfiButtonComponent,
    AsyncPipe,
  ],
})
export class CreateCmmsRelationComponent implements OnInit, OnChanges {
  readonly isVisible = model(false);
  readonly disableAddButton = input(false);
  readonly selectedCmmsField = input<ExternalCmmsField>(undefined);
  readonly externalSystemId = input<number>(undefined);
  readonly closeModal = output();
  readonly createRelation = output<ExternalCmmsField>();
  form: UntypedFormGroup;
  selectedParentField: ExternalCmmsField;
  totalFieldCount = 0;
  searchField = '';
  fieldOptions$: Observable<any>;
  fields = [];
  modalZIndex = MODAL_Z_INDEX;
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private cmmsService: CmmsService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      parentFieldId: [null, Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes.isVisible, 'currentValue', false)) {
      this.form.reset();
      this.fieldOptions$ = this.fetchCmmsFields();
    }
  }
  /**
   * Should set fields
   *
   * @param {string} event
   * @memberof CreateCmmsRelationComponent
   */
  setFields(event: string) {
    this.searchField = event;
    this.fieldOptions$ = this.fetchCmmsFields();
  }

  /**
   * Fetches the fields
   *
   * @param {number} [offset=0]
   * @return {*}
   * @memberof CreateCmmsRelationComponent
   */
  fetchCmmsFields(offset = 0) {
    const where = {
      externalSystemIds: [this.externalSystemId()],
    } as ExternalCmmsFieldWhere;
    if (this.searchField) {
      where.titleLike = `%${this.searchField}%`;
    }
    return this.cmmsService.getCmmsFields({ where, offset }).pipe(
      map((res) => {
        this.fields = res.externalCmmsFields;
        return res?.externalCmmsFields?.map((field) => ({
          value: field.id,
          label: field.title,
        }));
      })
    );
  }

  /**
   * Handle external value selected event.
   *
   * @param {*} ev
   * @memberof DetailsUserComponent
   */
  updateParentId(ev) {
    this.selectedParentField = this.fields.find((field) => field.id === ev);
    this.form.patchValue({ parentFieldId: ev });
    this.form.markAsDirty();
  }

  /**
   * Emits event to create cmms relation
   *
   * @memberof CreateCmmsRelationComponent
   */
  handleCreateCmmsRelation() {
    this.createRelation.emit(this.selectedParentField);
  }

  /**
   * Invoked when the cancel button is selected. Closes
   * the modal and resets the form.
   *
   * @memberof CreateCmmsRelationComponent
   */
  handleCancel() {
    this.form.reset();
    this.closeModal.emit();
  }
}
