<div class="alarm-source-wrapper d-flex flex-column align-items-start mb-2">
  <p class="fw-500 regular-14 dm40 alarm-source-title">Source Alarm Data</p>
  <div class="d-flex flex-column">
    <div
      *ngFor="let field of alarmSourceFields"
      class="d-flex align-items-center field-container"
    >
      <div class="d-flex align-items-center field-container">
        <p class="label">{{ alarmSourceFieldMap.get(field) }}</p>
        <div class="select-container">
          {{ alarm?.[field] || '-' }}
        </div>
      </div>
    </div>
  </div>
</div>
