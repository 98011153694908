export enum ExternalCmmsFieldSortField {
  Name = 'NAME',
  Title = 'TITLE',
  Description = 'DESCRIPTION',
  DisplayOrder = 'DISPLAY_ORDER',
}

export interface ExternalCmmsFieldWhere {
  id?: string[];
  titleLike?: string;
  isVisible?: boolean;
  isCreateWorkField?: boolean;
  isRequired?: boolean;
  viewAll?: boolean;
  externalSystemIds?: number[];
}

export enum CmmsDataType {
  List = 'LIST',
  Boolean = 'BOOLEAN',
  Number = 'NUMBER',
  String = 'STRING',
  DateTime = 'DATE_TIME',
  TextArea = 'TEXT_AREA',
}

export enum CmmsDisplayTemplate {
  KeyValue = 'KEY_VALUE',
  Value = 'VALUE',
}

export interface ExternalCmmsDomainValueCreateInput {
  externalSystemCmmsExternalFieldId: string;
  name: string;
  value: string;
}

export interface ExternalCmmsDomainValueUpdateInput {
  name?: string;
  isDeleted?: string;
}

export interface CmmsExternalFieldCreateInput {
  title: string;
  externalSystemId: number;
  description?: string;
  maxLength?: number;
  dataType: CmmsDataType;
  displayTemplate?: CmmsDisplayTemplate;
  defaultValue?: string;
  name: string;
  isCreateWorkField?: boolean;
  isRequired?: boolean;
  isVisible?: boolean;
}

export interface CmmsExternalFieldUpdateInput {
  title?: string;
  description?: string;
  maxLength?: number;
  dataType?: CmmsDataType;
  displayTemplate?: CmmsDisplayTemplate;
  defaultValue?: string;
  isCreateWorkField?: boolean;
  isRequired?: boolean;
  isVisible?: boolean;
  isDeleted?: boolean;
}

export interface ExternalCmmsField {
  id: string;
  name: string;
  defaultValue: string;
  defaultName: string;
  title: string;
  description: string;
  maxLength: number;
  dataType: CmmsDataType;
  isCreateWorkField: boolean;
  isRequired: boolean;
  isVisible: boolean;
  isReadOnly: boolean;
  hasDomain: boolean;
  displayOrder: number;
  displayTemplate: CmmsDisplayTemplate;
  isAutomationRuleAlarmAttribute: boolean;
  relatedParentField?: ExternalCmmsField;
}

export interface ExternalCmmsDomainValue {
  id: string;
  name: string;
  value: string;
  field: ExternalCmmsField;
}

export interface ExternalCmmsResolvedField {
  name: string;
  value: string;
  field: ExternalCmmsField;
  isInherited?: boolean;
  defaultedBy?: string;
}

export enum NormalizedCmmsField {
  Asset = 'ASSET',
  Location = 'LOCATION',
}

export interface ExternalCmmsDomainValueWhere {
  externalCmmsFieldId?: string;
  nameLike?: string;
  valueLike?: string;
  keyValueLike?: string;
  viewAll?: boolean;
  genericField?: NormalizedCmmsField;
}

export enum ExternalCmmsDomainValueSortField {
  KeyValue = 'KEY_VALUE',
  Name = 'NAME',
  Value = 'VALUE',
}

export interface InternalCmmsField {
  id: string;
  name: string;
}

export interface CmmsFieldMapping {
  internalField: InternalCmmsField;
  externalField: ExternalCmmsField;
}

export interface ExternalCmmsFieldMappingCreateInput {
  externalId: string;
  internalFieldType: CmmsInternalFieldType;
  internalFieldName?: string;
}

export enum CmmsInternalFieldType {
  State = 'STATE',
  Attribute = 'ATTRIBUTE',
}

export interface CmmsValueTranslation {
  id: string;
  externalValue: string;
  internalValue: string;
  translationDirection: ExternalCmmsTranslationDirection;
}

export enum ExternalCmmsTranslationDirection {
  InternalToExternal = 'INTERNAL_TO_EXTERNAL',
  ExternalToInternal = 'EXTERNAL_TO_INTERNAL',
}

export enum CmmsTranslationInternalValueSource {
  AlarmAttribute = 'ALARM_ATTRIBUTE',
  CmmsAttribute = 'CMMS_ATTRIBUTE',
}

export interface ExternalCmmsValueTranslationCreateInput {
  externalFieldId: string;
  externalValue: string;
  internalValue: string;
  translationDirection: ExternalCmmsTranslationDirection;
  internalValueSource: CmmsTranslationInternalValueSource;
}

export interface ExternalCmmsValueTranslationUpdateInput {
  externalValue?: string;
  internalValue?: string;
  translationDirection?: ExternalCmmsTranslationDirection;
  internalValueSource?: CmmsTranslationInternalValueSource;
}
export interface ExternalCmmsMappings {
  field: ExternalCmmsField;
  relationships: ExternalCmmsField[];
}

export interface ExternalCmmsDomainValueQuery {
  externalCmmsDomainValues: ExternalCmmsDomainValue[];
  externalCmmsDomainValueCount: number;
}

export interface UpdateCmmsConfigInput {
  alarmId?: number;
  entityId?: number;
  externalSystemId: number;
  cmmsConfig: any;
}

export interface TranslateCmmsInput {
  alarmId?: number;
  entityId?: number;
  externalSystemId: number;
}
