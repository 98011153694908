import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AdminNavigationComponent } from '../admin-navigation/admin-navigation.component';

@Component({
  selector: 'vfi-admin-dashboard-admin-layout',
  templateUrl: './admin-layout.component.html',
  styles: [],
  imports: [RouterOutlet, AdminNavigationComponent],
})
export class AdminLayoutComponent {
  constructor() {}
}
