import { ErrorHandler } from '@angular/core';
import { CoreConfig, CoreConfigService } from './services/core.service';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import { GlobalErrorHandler } from './handlers';
import xRange from 'highcharts/modules/xrange.src';
import Tree from 'highcharts/modules/treemap.src';
import NoData from 'highcharts/modules/no-data-to-display.src';
import { provideNgxMask } from 'ngx-mask';

registerLocaleData(en);
export const getCoreProvider = (config: CoreConfig) => [
  { provide: ErrorHandler, useClass: GlobalErrorHandler },
  { provide: HIGHCHARTS_MODULES, useFactory: () => [xRange, Tree, NoData] },
  provideNgxMask(),
  {
    provide: CoreConfigService,
    useValue: config,
  },
];
