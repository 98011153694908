import { gql } from 'apollo-angular';

export const FETCH_AUTOMATION_RULES_QUERY = gql`
  query automationRules($input: AutomationRuleOptions!) {
    automationRules(input: $input) {
      id
      createdAt
      createdBy {
        id
        displayName
      }
      lastModifiedAt
      lastModifiedBy {
        id
        displayName
      }
      name
      description
      executionOrder
      state
      criteria
      trigger
      delay {
        days
        hours
        minutes
      }
      actions {
        id
        createdAt
        lastModifiedAt
        executionAction
        config
      }
      isComplete
      lastError
      excludeIds
      lastExecutionAt
      executionCount
      schedule {
        timeInYear {
          day
          month
        }
        dayOfMonth
        scheduleCreatedAt
        everyNthDay
        everyNthWeek
        everyNthMonth
        everyNthYear
        dayOfWeek
        intervalInMonth {
          dayOfWeek
          repeatingTimePrefix
          month
        }
        timeRanges {
          from
          to
        }
      }
      assetCriteria {
        entityIds
        assets
        statuses
      }
    }
  }
`;

export const CREATE_AUTOMATION_RULES_QUERY = gql`
  mutation createAutomationRule($input: AutomationRuleCreateInput!) {
    createAutomationRule(input: $input) {
      id
      createdAt
      createdBy {
        id
        displayName
      }
      lastModifiedAt
      lastModifiedBy {
        id
        displayName
      }
      name
      description
      executionOrder
      state
      criteria
      trigger
      delay {
        days
        hours
        minutes
      }
      actions {
        id
        createdAt
        lastModifiedAt
        executionAction
        config
      }
      isComplete
      lastError
      excludeIds
      schedule {
        timeInYear {
          day
          month
        }
        dayOfMonth
        scheduleCreatedAt
        everyNthDay
        everyNthWeek
        everyNthMonth
        everyNthYear
        dayOfWeek
        intervalInMonth {
          dayOfWeek
          repeatingTimePrefix
          month
        }
        timeRanges {
          from
          to
        }
      }
      lastExecutionAt
      executionCount
    }
  }
`;
export const UPDATE_AUTOMATION_RULES_QUERY = gql`
  mutation updateAutomationRule($id: Int!, $input: AutomationRuleUpdateInput!) {
    updateAutomationRule(id: $id, input: $input) {
      id
      lastModifiedAt
      lastError
    }
  }
`;

export const UPDATE_AUTOMATION_RULE_EXECUTION_ORDER_QUERY = gql`
  mutation updateAutomationRuleOrder($input: [AutomationRuleOrderInput!]!) {
    updateAutomationRuleOrder(input: $input)
  }
`;

export const UPDATE_AUTOMATION_RULE_QUERY = gql`
  mutation updateAutomationRule($id: Int!, $input: AutomationRuleUpdateInput) {
    updateAutomationRule(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_AUTOMATION_RULE_QUERY = gql`
  mutation deleteAutomationRule($id: Int!) {
    deleteAutomationRule(id: $id) {
      id
    }
  }
`;

export const FETCH_AUTOMATION_ALARM_COUNT_QUERY = gql`
  query alarmCount($input: AlarmGroupCriteriaInput, $excludeIds: [Int!]) {
    alarmCount(input: $input, excludeIds: $excludeIds)
  }
`;

export const FETCH_AUTOMATION_ALARMS_QUERY = gql`
  query alarms($input: AlarmNestedOptions!, $excludeIds: [Int!]) {
    alarms(input: $input, excludeIds: $excludeIds) {
      id
      contextualName
      text
      properties {
        type
        values
      }
      cmmsAttributes
    }
  }
`;

export const CLONE_AUTOMATION_RULE_QUERY = gql`
  mutation cloneAutomationRule($id: Int!) {
    cloneAutomationRule(id: $id) {
      actions {
        id
        createdAt
        lastModifiedAt
        executionAction
        config
      }
      createdAt
      createdBy {
        id
        displayName
      }
      criteria
      delay {
        days
        hours
        minutes
      }
      description
      excludeIds
      executionOrder
      id
      isComplete
      lastError
      lastModifiedAt
      lastModifiedBy {
        id
        displayName
      }
      name
      state
      trigger
      lastExecutionAt
      executionCount
      schedule {
        timeInYear {
          day
          month
        }
        dayOfMonth
        scheduleCreatedAt
        everyNthDay
        everyNthWeek
        everyNthMonth
        everyNthYear
        dayOfWeek
        intervalInMonth {
          dayOfWeek
          repeatingTimePrefix
          month
        }
        from
        to
      }
    }
  }
`;
