<div *ngIf="!simpleMode()" class="multi-level-select">
  <button
    class="dropdown-button"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showMultiLevelSelect"
    [nzPopoverPlacement]="['bottom', 'top']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="multiLevelDropdown"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    (nzPopoverVisibleChange)="teamId() ? getUsersByTeams() : getUsers()"
    [disabled]="disabled()"
    [class.gray-8-bg]="disabled()"
    [class.required-field]="required() && !value()"
  >
    <div
      class="dropdown-label-placeholder"
      *ngIf="
        !displaySelectedValue() ||
        !value() ||
        (isArray(value()) && !value()?.length)
      "
    >
      {{ placeholder() }}
    </div>
    <div
      class="value w-100"
      *ngIf="
        ((!isArray(value()) && value()) ||
          (isArray(value()) && value()?.length)) &&
        displaySelectedValue()
      "
    >
      <label>{{ placeholder() }}</label>
      <div
        class="dropdown-label d-flex align-items-start"
        *ngIf="!isArray(value())"
      >
        {{ value()?.name }}
      </div>
      <div
        class="dropdown-label d-flex align-items-start"
        *ngIf="isArray(value())"
      >
        <span class="pill mr-1" *ngFor="let item of value()">
          {{ item.name }}
        </span>
      </div>
    </div>
  </button>
</div>
<span
  *ngIf="simpleMode()"
  class="cursor-pointer"
  nz-popover
  [(nzPopoverVisible)]="showMultiLevelSelect"
  [nzPopoverPlacement]="['bottom', 'top']"
  nzPopoverTrigger="click"
  [nzPopoverContent]="multiLevelDropdown"
  [nzPopoverOverlayClassName]="'multi-level-select-popover'"
  (nzPopoverVisibleChange)="teamId() ? getUsersByTeams() : getUsers()"
  ><i class="fa-solid fa-plus green-7 regular-14 mr-1"></i> Add users
</span>
<ng-template #multiLevelDropdown>
  <atom-multi-level-input
    [options]="optionsByTeams() ? teamOptions : options"
    [unassignedOptions]="unassignedTeamOptions"
    [multiSelect]="multiSelect()"
    [optionsBySection]="optionsByTeams()"
    (searchChange)="searchUser($event)"
    [selectedValue]="selectedValue"
    (valueChange)="onValueChange($event)"
    [loading]="loading"
  ></atom-multi-level-input>
</ng-template>
