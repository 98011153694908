<div class="popup p-4">
  <div class="d-flex align-items-center justify-content-between mb-1 neutral-4">
    <div class="d-flex align-items-center">
      <div class="title regular-16 mr-4">{{ title() }}</div>
    </div>
    <div class="cursor-pointer" (click)="onTogglePopup($event)">
      <i class="fa-regular fa-xmark"></i>
    </div>
  </div>
  <main>
    <ng-content></ng-content>
  </main>
</div>
