<div
  [ngClass]="{ 'cursor-not-allowed': disabled() }"
  #select
  nz-popover
  [(nzPopoverVisible)]="showDropdown"
  [nzPopoverTrigger]="'click'"
  [nzPopoverContent]="vfiDropdown"
  [nzPopoverOverlayStyle]="{ width: select?.offsetWidth + 'px' }"
  nzPopoverOverlayClassName="dropdown-container"
  [nzPopoverPlacement]="['bottom', 'top']"
  [ngClass]="{ 'pointer-none': readOnly() || disabled() }"
  (nzPopoverVisibleChange)="onPopoverVisibleChange()"
>
  <div
    class="vfi-input {{ size() }}"
    [ngClass]="{ disabled: disabled() }"
  >
    <div
      *ngIf="label()"
      class="vfi-label"
    >
      <span [class.yellow-6]="required() && !readOnly()">{{ label() }} </span>
      <i
        *ngIf="labelIcon()"
        class="ml-1 mr-1 {{ labelIcon() }}"
        nz-tooltip
        [nzTooltipTitle]="labelIconTooltip()"
      ></i>
      <i
        *ngIf="labelCheck()"
        class="ml-1 fa-solid fa-check check-green"
        nz-tooltip
        nzTooltipTitle="Verified"
      ></i>
    </div>
    <div class="select-icons position-relative cursor-pointer">
      <div
        tabindex="0"
        class="select d-flex align-items-center"
        [ngClass]="{
          'select-icon-right-spacing': value(),
          'select-icon-right-state-spacing': value() && !subtle(),
          readOnly: readOnly(),
          disabled: disabled(),
        }"
      >
        <span
          *ngIf="placeholder()?.length && isEmpty(value())"
          class="placeholder ellipsis"
        >{{ placeholder() }}</span>
        <span
          *ngIf="!isEmpty(value())"
          class="ellipsis value"
        >{{
          getFieldLabel(value())
          }}</span>
      </div>
      <span>
        <i
          *ngIf="
                    value() &&
                    canClear() &&
                    !readOnly()
                  "
          class="fa-regular fa-circle-xmark icon first-icon-right icon-right regular-12 clear-icon"
          (click)="$event.stopPropagation(); clearValue()"
        ></i>
        <i
          *ngIf="!readOnly()"
          class="fa-solid fa-chevron-down icon icon-right regular-12"
        ></i>
      </span>

      <ng-template #vfiDropdown>
        <div class="dropdown">
          <div class="dropdown-content">
            <div class="search">
              <input
                #searchInput
                class="dropdown-search"
                type="text"
                placeholder="Search"
                (keyup)="fetchFields($event.target.value)"
              />
            </div>
            <div
              *ngIf="options.length"
              class="options-container"
              #optionsContainer
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [infiniteScrollContainer]="optionsContainer"
              (scrolled)="onScroll(searchValue)"
            >
              <div
                class="option d-flex align-items-center justify-content-between"
                *ngFor="let o of options"
                [ngClass]="{ selected: value() === o?.value }"
                (click)="valueChanged(o)"
              >
                <span
                  class="ellipsis"
                  [title]="o?.label"
                >{{ o?.label }}</span>
              </div>
            </div>
            <div
              *ngIf="!options?.length"
              class="d-flex align-items-center justify-content-center results"
            >
              <span
                *ngIf="!loading"
                class="no-results"
              >No results</span>
              <span
                *ngIf="loading"
                class="no-results"
              >Loading...</span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
