import {
  Component,
  OnChanges,
  SimpleChanges,
  input,
  model,
  output,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SsoService } from '@vfi-ui/data-access/shared';
import { MODAL_Z_INDEX, SsoConfig } from '@vfi-ui/models';
import { keys } from '@vfi-ui/util/helpers';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NgClass, NgIf } from '@angular/common';
import { NzUploadComponent } from 'ng-zorro-antd/upload';
import { AutoGrowDirective, VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'nuclei-create-sso',
  templateUrl: './create-sso.component.html',
  styleUrls: ['./create-sso.component.scss'],
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NzTooltipDirective,
    NgClass,
    AutoGrowDirective,
    NzUploadComponent,
    VfiButtonComponent,
    NgIf,
  ],
})
export class CreateSsoComponent implements OnChanges {
  readonly isVisible = model(false);
  readonly mode = input<string>(undefined);
  readonly form = input<FormGroup>(undefined);
  readonly model = input<any>(undefined);
  readonly editData = input<SsoConfig>(undefined);
  readonly closeModal = output();
  readonly createSso = output<SsoConfig>();
  readonly updateSso = output<SsoConfig>();
  readonly deleteSso = output<string>();
  modalZIndex = MODAL_Z_INDEX;
  constructor(private readonly ssoService: SsoService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.mode?.currentValue === 'edit') {
      this.populateEditForm(this.editData());
    }
  }

  /**
   * Populates the edit form
   *
   * @param {SsoConfig} data
   * @memberof CreateSsoComponent
   */
  populateEditForm(data: SsoConfig) {
    keys(this.form().value).forEach((key) =>
      this.form().controls[key].setValue(data[key])
    );
  }

  /**
   * Handles creating sso
   *
   * @memberof CreateSsoComponent
   */
  handleCreateSso() {
    this.createSso.emit({ ...this.form().value });
  }

  /**
   * Handles updating sso
   *
   * @memberof CreateSsoComponent
   */
  handleUpdateSso() {
    this.updateSso.emit({ ...this.form().value });
  }

  /**
   * Handles deleting sso
   *
   * @memberof CreateSsoComponent
   */
  handleDeleteSso() {
    this.deleteSso.emit(this.form().value['emailDomain']);
  }

  /**
   * Handles uploading metadata and updating form
   *
   * @param {*} event
   * @memberof CreateSsoComponent
   */
  handleUpload = (item: { file: File }) => {
    this.ssoService.uploadMetdata(item.file).subscribe((res) => {
      this.form().patchValue({ ...res });
      this.form().markAsDirty();
    });
  };
}
