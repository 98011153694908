<div
  [nzTooltipTitle]="
    showTooltip()
      ? status() === 'Inactive' || status() === 'inactive'
        ? 'Inactive Alarm'
        : 'Active Alarm'
      : null
  "
  nz-tooltip
>
  <div
    class="d-flex align-items-center justify-content-center"
    [ngClass]="{ 'gray-10-bg square': showBackground() }"
  >
    <i
      class="regular-{{ size() }} {{ iconPrefix }} {{ iconBell }} {{ iconColor }}"
    ></i>
  </div>
</div>
