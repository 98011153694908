import { LensDataService, MenuService } from '@vfi-ui/data-access/shared';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  input,
  output,
  inject,
} from '@angular/core';
import {
  LensCategory,
  filterModelMode,
  MenuLens,
  LensType,
  TileType,
  ALARM_SORT,
  WORK_SORT,
  LensAlarmCategories,
  LensWorkCategories,
  HIDE_BADGE_CATEGORIES,
  AuthRoles,
} from '@vfi-ui/models';
import { Observable, of } from 'rxjs';
import {
  LensState,
  SetCurrentlySelectedLens,
  ResetGlobalFilters,
  ToggleFilterModal,
  ToggleSystemLensModal,
  SetTriageCounts,
  GlobalFiltersState,
  AuthState,
  ToggleCreateLensFilterMode,
  SetGlobalCurrentLens,
  NavigateToCurrentlySelectedLens,
  SetSort,
  SetLensTeam,
} from '@vfi-ui/state';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { debounceTime, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { fastParse, fetchLensTeamName, uniq } from '@vfi-ui/util/helpers';
import {
  NzMenuDirective,
  NzSubMenuComponent,
  NzMenuItemComponent,
} from 'ng-zorro-antd/menu';
import { NgFor, NgIf, NgClass, AsyncPipe } from '@angular/common';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NzBadgeComponent } from 'ng-zorro-antd/badge';
import { NzNoAnimationDirective } from 'ng-zorro-antd/core/no-animation';
import { DragulaModule, DragulaService } from 'ng2-dragula';

@Component({
  selector: 'vfi-core-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NzMenuDirective,
    NgFor,
    NzSubMenuComponent,
    ɵNzTransitionPatchDirective,
    NgIf,
    NzMenuItemComponent,
    NgClass,
    NzTooltipDirective,
    NzBadgeComponent,
    NzNoAnimationDirective,
    AsyncPipe,
    DragulaModule,
  ],
})
export class NavigationMenuComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  readonly type = input<LensType>(undefined);
  readonly routeChange = output<string>();
  triageCounts$ = inject(Store).select(LensState.getTriageCounts);
  lens$ = inject(Store).select(LensState.getLenses);
  lensCategory = LensCategory;
  lensType = LensType;
  parent: string;
  lenses: MenuLens[];
  currentSelectedLens: MenuLens;
  menuOpenState = {};
  defaultLensId: string;
  showStarAnimation = false;
  canEditTeamLenses = false;
  dragulaGroup = 'NAVIGATION_MENU';

  constructor(
    private store: Store,
    private router: Router,
    private menuService: MenuService,
    private lensService: LensDataService,
    private cdr: ChangeDetectorRef,
    private dragulaService: DragulaService
  ) {
    super();
  }

  /**
   * returns if lens should show counts
   *
   * @param {LensCategory} category
   * @returns
   * @memberof NavigationMenuComponent
   */
  showCounts(category: LensCategory) {
    if (!category) {
      return false;
    }
    return !HIDE_BADGE_CATEGORIES.includes(category);
  }

  ngOnInit() {
    // Set permissions
    this.canEditTeamLenses = this.store.selectSnapshot(
      AuthState.userPermissions
    )?.lenses?.manageTeamLenses;

    const type = this.type();
    this.defaultLensId = this.store.selectSnapshot(
      LensState.getDefaultLens(type)
    )?.id;
    this.getLensCount(type);
    this.parent = this.store.selectSnapshot(GlobalFiltersState.getLens).parent;
    this.store
      .select(LensState.getFormattedLens)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((lenses) => {
        this.lenses = this.applyDragPermissions(fastParse(lenses));
        this.createDragHandles(this.lenses, this.dragulaGroup);
        this.cdr.detectChanges();
      });
    this.store
      .select(LensState.getCurrentlySelectedLens)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((lens) => {
        this.currentSelectedLens = lens;
        this.cdr.detectChanges();
      });
    this.menuService
      .getTriageLensCount()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((counts) => this.store.dispatch(new SetTriageCounts(counts)));
    this.menuService.menuUpdated
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((len) => {
        const authRole = this.store.selectSnapshot(AuthState.getAuthRole);
        let waitingMenuItems$: Observable<void> = of();
        if (len.type === LensType.ALARM) {
          waitingMenuItems$ = this.menuService.getMenuItems().pipe(
            take(1),
            switchMap(() =>
              this.store.dispatch(new SetCurrentlySelectedLens(len.id))
            )
          );
        } else if (len.type === LensType.WORK) {
          waitingMenuItems$ = this.menuService
            .getReportingMenuItems(authRole)
            .pipe(
              take(1),
              switchMap(() =>
                this.store.dispatch(new SetCurrentlySelectedLens(len.id))
              )
            );
        } else if (len.type === LensType.ASSET) {
          waitingMenuItems$ = this.menuService.getAssetMenuItems().pipe(
            take(1),
            switchMap(() =>
              this.store.dispatch(new SetCurrentlySelectedLens(len.id))
            )
          );
        }
        waitingMenuItems$
          .pipe(
            tap(() => {
              this.getLensCount(this.type());
              this.store.dispatch([
                new NavigateToCurrentlySelectedLens(),
                new SetGlobalCurrentLens({
                  parent: len?.category,
                  child: len?.name,
                  parentId: len?.id,
                  isCustom: len?.isCustom,
                  type: len?.type as TileType,
                  category: len?.category,
                  description: len?.description,
                }),
              ]);
            })
          )
          .subscribe(() => {
            this.menuOpenState = this.setMenuOpenState(this.menuOpenState, len);
            this.cdr.detectChanges();
          });
      });

    // Re-calculate navigation menu state when lens is deleted
    this.menuService.lensDeleted
      .pipe(takeUntil(this.componentDestroyed), debounceTime(500))
      .subscribe(() => {
        this.menuOpenState = this.setMenuOpenState(this.menuOpenState);
        this.cdr.detectChanges();
      });

    this.menuOpenState = this.setMenuOpenState(this.menuOpenState);
    this.cdr.detectChanges();
  }

  /**
   * open new lens model by dispatching reset and toggle states
   *
   * @param {LensCategory} category
   * @param {MenuLens} lens
   * @memberof NavigationMenuComponent
   */
  openLensModel(category: LensCategory, lens: MenuLens) {
    this.store.dispatch(new ResetGlobalFilters()).subscribe(() => {
      const team = lens.id ? { name: lens?.name, id: lens?.id } : null;
      this.store.dispatch(new SetLensTeam(team));
      if (LensAlarmCategories.includes(category)) {
        this.store.dispatch(new SetSort(ALARM_SORT));
      }
      if (LensWorkCategories.includes(category)) {
        this.store.dispatch(new SetSort(WORK_SORT));
      }
      this.store.dispatch([
        new ToggleCreateLensFilterMode({
          mode: filterModelMode.CREATE_LENS,
          category,
        }),
        new ToggleFilterModal(true),
      ]);
    });
  }

  /**
   * open edit lens model by dispatching reset and toggle states
   *
   * @memberof NavigationMenuComponent
   */
  updateLens(category: LensCategory, isCustom: boolean, lens: MenuLens) {
    if (isCustom) {
      const team = lens.id ? { name: lens?.name, id: lens?.id } : null;
      this.store.dispatch(new SetLensTeam(team));
      this.store.dispatch([
        new ToggleCreateLensFilterMode({
          mode: filterModelMode.EDIT_LENS,
          category,
        }),
        new ToggleFilterModal(true),
      ]);
    } else {
      this.store.dispatch(new ToggleSystemLensModal(true));
    }
  }

  /**
   * navigate to lens route
   *
   * @param {Lens} lens
   * @memberof NavigationMenuComponent
   */
  routeChanged(lens: MenuLens) {
    const teamName = fetchLensTeamName(lens);
    let type = 'alarms';
    if (lens.type === LensType.ASSET) {
      type = 'assets';
    }
    if (lens.type === LensType.WORK) {
      type = 'work';
    }
    this.router.navigate([type, teamName, lens.name]);
  }

  /**
   * set default lens
   *
   * @param {string} lensId
   * @memberof NavigationMenuComponent
   */
  setDefaultLens(lensId: string) {
    this.menuService.setDefaultLens(lensId).subscribe();
    this.defaultLensId = lensId;
    this.showStarAnimation = true;
    setTimeout(() => {
      this.showStarAnimation = false;
      this.cdr.detectChanges();
    }, 800);
  }

  /**
   * return if user can edit lens
   *
   * @param {MenuLens} lens
   * @returns
   * @memberof NavigationMenuComponent
   */
  canEditLens(lens: MenuLens) {
    if (!lens) {
      return false;
    }
    if (lens?.isCustom && !lens?.team) {
      return true;
    }
    if (lens?.isCustom && lens?.team) {
      return this.canEditTeamLenses;
    }
    return true;
  }

  /**
   * return if user can create lens
   *
   * @param {MenuLens} parent
   * @param {MenuLens} lens
   * @returns
   * @memberof NavigationMenuComponent
   */
  canCreateLens(parent: MenuLens, lens: MenuLens) {
    if (lens.type === LensType.CREATE) {
      return parent.isTeam ? this.canEditTeamLenses : true;
    }
    return false;
  }

  /**
   * calls data service to update lens order
   *
   * @param {number} index
   * @memberof NavigationMenuComponent
   */
  lensOrderChanged(index: number) {
    const updatedOrder = this.lenses[index].childLens
      .filter((lens) => lens.type !== 'create')
      .map((l, i) => ({
        lensId: l.id,
        orderValue: i,
      }));
    this.lensService.updateLensOrder(updatedOrder).subscribe();
  }

  /**
   * apply drag permission
   *
   * @private
   * @param {MenuLens[]} lenses
   * @returns
   * @memberof NavigationMenuComponent
   */
  private applyDragPermissions(lenses: MenuLens[]) {
    const authRole = this.store.selectSnapshot(AuthState.getAuthRole);
    return lenses.map((lens) => {
      let canDrag = false;
      if (!lens.isCustom && !lens.isTeam) {
        canDrag = false;
      } else if (lens.isCustom && !lens.isTeam) {
        canDrag = true;
      } else if (lens.isTeam) {
        canDrag =
          authRole === AuthRoles.admin || authRole === AuthRoles.manager;
      }
      return { ...lens, canDrag };
    });
  }

  /**
   * apply drag handle to dragula groups
   *
   * @private
   * @param {MenuLens[]} lenses
   * @param {string} dragulaGroup
   * @memberof NavigationMenuComponent
   */
  private createDragHandles(lenses: MenuLens[], dragulaGroup: string) {
    // Create dragula group per lens parent and apply drag handle
    lenses.forEach((lensGroup, index) => {
      this.dragulaService.destroy(`${dragulaGroup}${index}`);
      this.dragulaService.createGroup(`${dragulaGroup}${index}`, {
        moves: (el, container, handle) =>
          handle.className.includes('drag-handle'),
      });
    });
  }

  /**
   * set menu open state
   *
   * @private
   * @param {*} openMenuState
   * @param {MenuLens} [lens]
   * @returns
   * @memberof NavigationMenuComponent
   */
  private setMenuOpenState(openMenuState: any, lens?: MenuLens) {
    const urlTree = this.router.parseUrl(this.router.url);
    const urlSegments = urlTree?.root?.children?.['primary']?.segments?.map(
      (segment) => segment.path
    );

    const lenses = this.store.selectSnapshot(LensState.getFormattedLens);
    let lensHeader = null;
    if (lens) {
      lensHeader = lenses.find((l) =>
        l.childLens.some((child) => child.id === lens.id)
      );
    }

    const userOpened = Object.keys(openMenuState).filter(
      (key) => openMenuState[key]
    );
    const teams = uniq([
      lensHeader?.name || urlSegments[1] || 'Standard Lenses',
      ...userOpened,
    ]);
    const headers = lenses.map((l) => l.name);
    const menuState = {};
    headers.reduce((obj, key) => {
      Object.assign(obj, {
        [key]: teams.includes(key),
      });
      return obj;
    }, menuState);

    return menuState;
  }

  /**
   * fetch counts for lens
   *
   * @private
   * @memberof NavigationMenuComponent
   */
  private getLensCount(type: LensType) {
    this.lensService.getLensCounts(type).subscribe((counts) => {
      const triageCounts = {};
      counts.reduce((obj, key) => {
        Object.assign(obj, { [key?.id]: +key?.count });
        return obj;
      }, triageCounts);
      this.store.dispatch(new SetTriageCounts(triageCounts));
    });
  }
}
