import { Component, OnChanges, input, SimpleChanges } from '@angular/core';
import { NgFor } from '@angular/common';
import { VfiMultiSelectComponent } from '../vfi-multi-select/vfi-multi-select.component';
import { AlarmsDataService } from '@vfi-ui/data-access/shared';
import {
  AlarmSourceFieldMap,
  AlarmSourceFields,
  FastAlarm,
} from '@vfi-ui/models';

@Component({
  selector: 'atom-alarm-source-fields',
  templateUrl: './alarm-source-fields.component.html',
  styleUrls: ['./alarm-source-fields.component.scss'],
  imports: [NgFor, VfiMultiSelectComponent],
})
export class AlarmSourceFieldsComponent implements OnChanges {
  readonly alarmId = input<number>(undefined);
  readonly isMobile = input<boolean>(false);
  alarm: FastAlarm;
  alarmSourceFields = AlarmSourceFields;
  alarmSourceFieldMap = AlarmSourceFieldMap;

  constructor(private alarmsDataService: AlarmsDataService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.alarmId?.currentValue) {
      this.fetchAlarmSourceData(changes?.alarmId?.currentValue);
    }
  }

  /**
   * fetch alarm source data
   *
   * @param {number} alarmId
   * @memberof AlarmSourceFieldsComponent
   */
  fetchAlarmSourceData(alarmId: number) {
    this.alarmsDataService
      .getEntityFastAlarms({ where: { ids: [alarmId] } })
      .subscribe((alarm) => (this.alarm = alarm[0]));
  }
}
