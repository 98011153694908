<div
  class="dropdown-list d-flex flex-column justify-content-between"
  role="listbox"
  aria-expanded="false"
>
  <div>
    <input
      type="text"
      class="dropdown-search"
      placeholder="Search"
      (keyup)="searched.emit($event.target.value)"
    />

    <div class="alert" *ngIf="!options()?.length && loading()">Loading...</div>
    <div class="alert" *ngIf="!options()?.length && !loading()">No Results</div>

    <div
      class="list-container"
      *ngIf="options()?.length"
      #listContainer
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="listContainer"
      (scrolled)="scrolled.emit(true)"
    >
      <atom-dropdown-item
        *ngFor="let o of options()"
        [item]="o?.value"
        [title]="o?.label"
        [selected]="isChecked(o)"
        [showCheckbox]="false"
        [showTooltips]="true"
        (selectChange)="optionSelected(o)"
      ></atom-dropdown-item>
    </div>
  </div>
</div>
