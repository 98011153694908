<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Add Mapping"
  [nzBodyStyle]="{ 'min-height': '200px' }"
  [nzFooter]="modalFooter"
  [nzWidth]="460"
  [nzZIndex]="modalZIndex"
  (nzOnCancel)="closeModal.emit()"
>
  <ng-template nzModalContent>
    <section
      aria-label="createCmmsMappingFields"
      class="d-flex scrollable-frame modalBodyHeight"
    >
      <div class="d-flex flex-column">
        <div class="d-flex flex-row">
          <p>
            Please enter the internal type and name (if applicable) to map to
            <strong>{{ selectedCmmsField().name }}</strong> field
          </p>
        </div>
        <div class="d-flex" [formGroup]="form">
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active': form?.controls?.internalFieldType?.value,
            }"
          >
            <atom-dropdown-menu
              aria-label="cmms-mapping-internal-type"
              [name]="'internalFieldType'"
              [options]="options"
              [value]=""
              [multiple]="false"
              (valueChange)="updateInternalType($event)"
            ></atom-dropdown-menu>
            <label>Internal Type</label>
          </div>
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active': form?.controls?.internalFieldName?.value,
            }"
          >
            <input
              *ngIf="
                form?.controls?.internalFieldType?.value !== 'Alarm Attribute'
              "
              aria-label="cmms-field-value-value"
              [ngClass]="{
                'required-field': form.controls.internalFieldName.invalid,
                'disabled-input':
                  form?.controls?.internalFieldType?.value !== 'CMMS Attribute',
              }"
              #displayName
              type="text"
              class="form-control"
              formControlName="internalFieldName"
            />
            <atom-dropdown-menu
              *ngIf="
                form?.controls?.internalFieldType?.value === 'Alarm Attribute'
              "
              aria-label="cmms-mapping-internal-value"
              [name]="'internalFieldName'"
              [options]="attributeOptions"
              [value]=""
              [multiple]="false"
              (valueChange)="updateInternalValue($event)"
            ></atom-dropdown-menu>
            <label>Attribute Name</label>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
  <ng-template #modalFooter>
    <div class="w-100 d-flex justify-content-end">
      <atom-vfi-button
        [text]="'Cancel'"
        [type]="'secondary'"
        (clicked)="handleCancel()"
      ></atom-vfi-button>
      <atom-vfi-button
        class="ml-1"
        [text]="'Add Mapping'"
        [type]="'primary'"
        [disabled]="form.invalid || disableAddButton()"
        (clicked)="handleCreateCmmsMapping()"
      ></atom-vfi-button>
    </div>
  </ng-template>
</nz-modal>
