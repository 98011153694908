import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { AuthService } from '@vfi-ui/data-access/shared';
import { ValidateUser } from '@vfi-ui/models';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { getCurrentYear } from '@vfi-ui/util/helpers';
import { NgIf } from '@angular/common';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { SetPasswordComponent } from '@vfi-ui/ui/nucleus';
import { VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'vfi-auth-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  imports: [NgIf, NzAvatarComponent, SetPasswordComponent, VfiButtonComponent],
})
export class RegisterComponent implements OnInit {
  formValid = false;
  user: ValidateUser;
  token: string;
  customerId: string;
  currentYear = getCurrentYear();
  validatedToken = false;

  passwordForm = this.formBuilder.group({
    password: [null, Validators.required],
    confirmPassword: [null, Validators.required],
  });

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(
        filter((params) => !!params),
        take(1)
      )
      .subscribe((params) => {
        this.token = params.token;
        this.customerId = params.cid;
        this.authService
          .validateToken(this.token, this.customerId)
          .subscribe((user) => {
            this.validatedToken = true;
            this.user = user;
          });
      });
  }

  /**
   * sets formValid value
   *
   * @param {boolean} isValid
   * @memberof RegisterComponent
   */
  checkFormValid(isValid: boolean) {
    this.formValid = isValid;
  }

  /**
   * calls auth service to activate user and redirect to application
   *
   * @param {string} email
   * @memberof RegisterComponent
   */
  activateUser() {
    if (
      this.formValid &&
      this.passwordForm.value.password ===
        this.passwordForm.value.confirmPassword
    ) {
      const password = this.passwordForm.value.confirmPassword;
      this.authService
        .activateUser(Number(this.customerId), password, this.token)
        .subscribe((res) => {
          if (res) {
            localStorage.removeItem('vfi-deepLink');
            window.location.href = '/login';
          }
        });
    } else {
      this.passwordForm.controls['confirmPassword'].setErrors({
        noMatch: true,
      });
    }
  }
}
