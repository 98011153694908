<div
  class="item d-flex"
  [class.disabled]="disabled()"
  [class.selected]="selected()"
  [ngStyle]="{ paddingRight: showRightIcon() ? '0' : '8px' }"
>
  <input
    *ngIf="item() && showCheckbox()"
    class="vfi-checkbox"
    type="checkbox"
    [value]="item()"
    [checked]="selected()"
    (click)="selectItem()"
  />
  <div
    class="ellipsis title"
    (click)="item().count > 0 && showDrilldown() ? drillDown() : selectItem()"
    [nzTooltipTitle]="title()"
    nzTooltipPlacement="right"
    [nzTooltipTrigger]="showTooltips() ? 'hover' : null"
    nzTooltipMouseEnterDelay="1"
    nz-tooltip
  >
    {{ title() }}
    <nz-badge
      *ngIf="item()?.count && showBadge()"
      [nzStandalone]="true"
      [nzShowZero]="false"
      [nzCount]="badgeCount()"
    ></nz-badge>
  </div>
  <i
    [class]="'fas ' + customRightIcon() + ' pr-1 right-icon custom-right-icon'"
    [class.showRightIcon]="alwaysShowRightIcon()"
    *ngIf="customRightIcon()"
    (click)="rightIconClick.emit()"
  ></i>
  <i
    class="fas fa-chevron-right pr-1 right-icon"
    *ngIf="showRightIcon()"
    (click)="drillDown()"
  ></i>
</div>
