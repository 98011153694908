import { Component, input, model, output } from '@angular/core';
import { MODAL_Z_INDEX, Tab } from '@vfi-ui/models';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { VfiButtonComponent } from '../vfi-button/vfi-button.component';

@Component({
  selector: 'atom-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  imports: [
    NgFor,
    NgIf,
    NgClass,
    NzModalComponent,
    NzModalContentDirective,
    VfiButtonComponent,
  ],
})
export class TabsComponent {
  readonly tabs = input<Tab[]>([]);
  readonly selectedTab = model<string>(undefined);
  readonly hasChanges = input(false);
  readonly showChangeModal = input(false);
  readonly tabSelected = output<string>();
  changeModalVisible = false;
  destinationTab: string;
  modalZIndex = MODAL_Z_INDEX;

  constructor() {}

  /**
   * handle tab selected event
   *
   * @param {string} tab
   * @memberof TabsComponent
   */
  selected(tab: string) {
    if (this.showChangeModal() && this.hasChanges()) {
      this.destinationTab = tab;
      this.changeModalVisible = true;
    } else {
      this.selectedTab.set(tab);
      this.tabSelected.emit(tab);
    }
  }

  /**
   * do not change tabs
   *
   * @memberof TabsComponent
   */
  stay() {
    this.destinationTab = null;
    this.changeModalVisible = false;
  }

  /**
   * move to new tab
   *
   * @memberof TabsComponent
   */
  leave() {
    this.selectedTab.set(this.destinationTab);
    this.tabSelected.emit(this.selectedTab());
    this.destinationTab = null;
    this.changeModalVisible = false;
  }
}
