import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  input,
  model,
  output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ExternalSystemReplayAlarmsInput, MODAL_Z_INDEX } from '@vfi-ui/models';
import { get } from '@vfi-ui/util/helpers';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
import { NgIf } from '@angular/common';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'nuclei-replay-alarms',
  templateUrl: './replay-alarms.component.html',
  styleUrls: ['./replay-alarms.component.scss'],
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NzSwitchComponent,
    NgIf,
    NzDatePickerComponent,
    VfiButtonComponent,
  ],
})
export class ReplayAlarmsComponent implements OnInit, OnChanges {
  readonly isVisible = model(false);
  readonly externalSystemName = input<string>(undefined);
  readonly closeModal = output();
  readonly replayAlarms = output<ExternalSystemReplayAlarmsInput>();
  form: UntypedFormGroup;
  validationErrorMessage: string;
  modalZIndex = MODAL_Z_INDEX;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      replayStartAt: null,
      replayEndAt: null,
      replayAll: null,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes.isVisible, 'currentValue', false)) {
      this.form.reset();
    }
  }

  /**
   * Emits event to start the replay.
   *
   * @memberof ReplayAlarmsComponent
   */
  handleSubmit() {
    let { replayStartAt, replayEndAt } = this.form.value;
    if (this.form.value.replayAll) {
      replayStartAt = null;
      replayEndAt = null;
    }
    this.replayAlarms.emit({ replayStartAt, replayEndAt });
  }

  /**
   * Triggered when the replay start or replay end
   * date changes.
   */
  dateChanged() {
    const { replayStartAt, replayEndAt } = this.form.value;
    if (replayStartAt && replayEndAt && replayStartAt > replayEndAt) {
      this.validationErrorMessage =
        'Replay start date must be less than end date';
    } else {
      this.validationErrorMessage = null;
    }
  }

  /**
   * Invoked when the replay all alarms toggled
   * changes.
   */
  replayAllToggled() {
    if (this.form.value.replayAll) {
      this.form.controls['replayStartAt'].reset();
      this.form.controls['replayEndAt'].reset();
      this.validationErrorMessage = null;
    }
  }

  /**
   * Invoked when the cancel button is selected. Closes
   * the modal and resets the form.
   *
   * @memberof ReplayAlarmsComponent
   */
  handleCancel() {
    this.closeModal.emit();
  }
}
