import { BaseComponent } from '@vfi-ui/feature/core';
import { Component, input, output } from '@angular/core';
import { AngularDateFormat, CoreAlarm } from '@vfi-ui/models';
import { NgIf } from '@angular/common';
import { DateTimePipe } from '@vfi-ui/feature/core';

@Component({
  selector: 'nuclei-alarm-source',
  templateUrl: './alarm-source.component.html',
  styleUrls: ['./alarm-source.component.scss'],
  imports: [NgIf, DateTimePipe],
})
export class AlarmSourceComponent extends BaseComponent {
  readonly alarm = input<CoreAlarm>(undefined);
  readonly show = input(true);
  readonly showDate = input(true);
  readonly alarmSource = input<string>(undefined);
  readonly sourceSelected = output();
  angularDateFormat = AngularDateFormat;

  /**
   * Invoked when the source link is selected
   * emits an event notifying callbacks that
   * the source has been selected so that they
   * may take the appropriate action.
   *
   * @memberof AlarmSourceComponent
   */
  onSourceSelected() {
    this.sourceSelected.emit();
  }
}
