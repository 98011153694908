import {
  LoadAlarms,
  ResetAlarms,
  SetSortFilterType,
  GlobalFiltersState,
  SetGlobalCurrentLens,
  AlarmsState,
  AlarmsStateModel,
  TotalResetGlobalFilters,
  SetCoreGlobalFilters,
  ResetOnlyCoreCriterionFilters,
  SetCoreSortValue,
  LensState,
  SetCurrentlySelectedLens,
  SetAlarmLimit,
  LoadAlarmStatuses,
  SetSort,
} from '@vfi-ui/state';
import {
  TileType,
  MenuLens,
  LensParent,
  MaxAlarmsLimit,
  FastAlarm,
  LensType,
} from '@vfi-ui/models';
import { take, filter, switchMap, map, tap } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { isEmpty, isNil } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class AlarmsResolver {
  alarmsState$: Observable<AlarmsStateModel> = inject(Store).select(
    AlarmsState.getState
  );
  lenses$: Observable<MenuLens[]> = inject(Store).select(LensState.getLenses);

  constructor(private store: Store) {}

  resolve(
    route: ActivatedRouteSnapshot,
    lensParent: LensParent
  ): Observable<FastAlarm[]> {
    const lensId = route.paramMap.get('lensId');
    this.store.dispatch(new SetSortFilterType('alarm'));
    this.store.dispatch(new ResetAlarms());
    this.store.dispatch(new LoadAlarmStatuses());
    this.store.dispatch(new SetAlarmLimit(MaxAlarmsLimit));

    // Reset global filters only when switching to a different lens
    const currentLens = this.store.selectSnapshot(
      LensState.getCurrentlySelectedLens
    );
    if (isEmpty(currentLens) || currentLens?.id !== lensId) {
      this.store.dispatch(new TotalResetGlobalFilters(false)).subscribe(() => {
        this.store.dispatch(new ResetOnlyCoreCriterionFilters());
      });
    }

    return this.waitForLensToUpdate(lensId, lensParent).pipe(
      tap((r) => {
        const lensOptions = {
          parent: r.category,
          child: r.name,
          parentId: r.id,
          isCustom: r.isCustom,
          type: 'alarms' as TileType,
          category: r.category,
          description: r.description,
        };
        this.store.dispatch(new SetCurrentlySelectedLens(r.id));
        this.store.dispatch(new SetGlobalCurrentLens(lensOptions));
        this.store.dispatch(
          new SetCoreGlobalFilters(r.displayOptions.criterion)
        );

        const order = Array.isArray(r.criteria.order)
          ? r.criteria.order
          : [r.criteria.order];

        this.store.dispatch(new SetSort(order));
        this.store.dispatch(
          new SetCoreSortValue({
            ...r.displayOptions?.sort,
            sortDbName: r.displayOptions?.sort?.sortDbName,
            sortValue: r.displayOptions?.sort?.sortValue,
            sortType: r.displayOptions?.sort?.sortType,
          })
        );
        // Initiate call for alarm data
        return this.loadData().subscribe();
      }),
      // Wait for alarm data to actually load
      switchMap(() => this.waitForStateToUpdate())
    );
  }

  waitForLensToUpdate(
    lensId: string,
    lensParent: LensParent
  ): Observable<MenuLens> {
    return this.lenses$.pipe(
      filter(
        (lenses) =>
          lenses.length > 0 &&
          !isEmpty(
            lenses.find(
              (l) => l.name === lensParent && l.type === LensType.ALARM
            )
          )
      ),
      map(
        (lenses) =>
          lenses.find((x) => x.id === lensId) ||
          lenses.find((y) => y.name === lensParent)
      ),
      take(1)
    );
  }

  waitForStateToUpdate(): Observable<FastAlarm[]> {
    return this.alarmsState$.pipe(
      map((res) => res.items),
      filter((state) => !!state),
      take(1)
    );
  }

  /**
   * Loads the alarm data
   *
   * @return {*}  {Observable<void>}
   * @memberof AlarmsResolver
   */
  loadData(): Observable<void> {
    return this.alarmsState$.pipe(
      filter((alarms) => {
        const { child } = this.store.selectSnapshot(GlobalFiltersState.getLens);
        return (
          !alarms.tiles.length &&
          alarms.alarmStatuses.length > 0 &&
          !isNil(child)
        );
      }),
      take(1),
      switchMap(() => this.store.dispatch(new LoadAlarms()))
    );
  }
}
