// TODO: fix
/* eslint-disable @nx/enforce-module-boundaries */
import { BaseComponent } from '@vfi-ui/feature/core';
import { Component, OnInit, input, model, output } from '@angular/core';
import { Entity, SearchEntityResult } from '@vfi-ui/models';
import { Subject, takeUntil } from 'rxjs';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { NgIf } from '@angular/common';
import { MultiLevelSelectInputComponent } from '../multi-level-select-input/multi-level-select-input.component';

@Component({
  selector: 'atom-multi-level-selector',
  templateUrl: './multi-level-selector.component.html',
  styleUrls: ['./multi-level-selector.component.scss'],
  imports: [NzPopoverDirective, NgIf, MultiLevelSelectInputComponent],
})
export class MultiLevelSelectorComponent
  extends BaseComponent
  implements OnInit
{
  readonly placeholder = input<string>(undefined);
  readonly pointEntity = model<Entity[]>(undefined);
  readonly pointEntityValue = model<SearchEntityResult>(undefined);
  readonly value = input<string>(undefined);
  readonly reset = input<Subject<boolean>>(undefined);
  readonly disabled = input(false);
  readonly valueChange = output<SearchEntityResult>();
  showMultiLevelSelect = false;

  ngOnInit() {
    const reset = this.reset();
    if (reset) {
      reset.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
        this.pointEntityValue.set(null);
        this.pointEntity.set(null);
      });
    }
  }

  /**
   * emit changes to value
   * @memberof MultiLevelSelectorComponent
   */
  onValueChange() {
    this.valueChange.emit(this.pointEntityValue());
  }
}
