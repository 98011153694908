import { Component, input, model, output } from '@angular/core';
import { ButtonSize } from '@vfi-ui/models';
import { NgClass, NgIf } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'atom-vfi-date-picker',
  templateUrl: './vfi-date-picker.component.html',
  styleUrls: ['./vfi-date-picker.component.scss'],
  imports: [
    NgClass,
    NgIf,
    NzTooltipDirective,
    NzDatePickerComponent,
    FormsModule,
  ],
})
export class VfiDatePickerComponent {
  readonly value = model(undefined);
  readonly label = input<string>(undefined);
  readonly placeholder = input('');
  readonly labelCheck = input(false);
  readonly labelIcon = input<string>(undefined);
  readonly labelIconTooltip = input<string>(undefined);
  readonly required = input(false);
  readonly readOnly = input(false);
  readonly disabled = input(false);
  readonly subtle = input(false);
  readonly errorMessage = input<string>(undefined);
  readonly autofocus = input(false);
  readonly showCancel = input(true);
  readonly showEditButtons = input(false);
  readonly allowSpecialCharacters = input(true);
  readonly disabledDates = input(undefined);
  readonly blurChanged = output<boolean>();
  readonly valueChanged = output<string>();
  readonly save = output<string>();
  buttonSize = ButtonSize;

  constructor() {}

  /**
   * handle input changed event
   *
   * @param {string} value
   * @memberof VfiInputComponent
   */
  changed(value) {
    this.value.set(value);
    this.valueChanged.emit(this.value());
  }
}
