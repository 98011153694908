<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Add Field Value Translation"
  [nzBodyStyle]="{ 'min-height': '200px' }"
  [nzFooter]="modalFooter"
  [nzWidth]="460"
  [nzZIndex]="modalZIndex"
  (nzOnCancel)="closeModal.emit()"
>
  <ng-template nzModalContent>
    <section aria-label="createCmmsDomainValueFields" class="d-flex">
      <div class="d-flex flex-column">
        <div class="d-flex flex-row">
          <p>
            Please enter the internal work ticket value to translate to a CMMS
            work ticket value.
          </p>
        </div>
        <div class="d-flex flex-column" [formGroup]="form">
          <div class="d-flex">
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': form?.controls?.internalValue?.value,
              }"
            >
              <input
                aria-label="internal-value"
                [ngClass]="{
                  'required-field': form.controls.internalValue.invalid,
                }"
                #displayName
                type="text"
                class="form-control"
                formControlName="internalValue"
              />
              <label>{{ cmmsFieldMapping()?.internalField?.name }} (VF)</label>
            </div>
            <div class="translation-direction-icon">
              <i class="fa fa-long-arrow-right"></i>
            </div>
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': form?.controls?.externalValue?.value,
              }"
            >
              <atom-dropdown-menu
                aria-label="external-value-dropdown"
                [name]="'externalValue'"
                [options]="domainValueOptions$ | async"
                [label]="cmmsFieldMapping()?.externalField?.name + ' (CMMS)'"
                (optionSearch)="setDomainValues($event)"
                [value]=""
                [multiple]="false"
                (valueChange)="updateExternalValue($event)"
              ></atom-dropdown-menu>
            </div>
          </div>
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active': form?.controls?.internalValueSource?.value,
            }"
          >
            <atom-dropdown-menu
              aria-label="internal-value-source-dropdown"
              [name]="'internalValueSource'"
              [options]="internalFieldSourceOptions"
              [label]="'Internal Field Source'"
              [value]="form?.controls?.internalValueSource?.value"
              [multiple]="false"
              (valueChange)="updateInternalValueSource($event)"
            ></atom-dropdown-menu>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
  <ng-template #modalFooter>
    <div class="w-100 d-flex justify-content-end">
      <atom-vfi-button
        [text]="'Cancel'"
        [type]="'secondary'"
        (clicked)="handleCancel()"
      ></atom-vfi-button>
      <atom-vfi-button
        class="ml-1"
        [text]="'Add Value'"
        [type]="'primary'"
        [disabled]="form.invalid || disableAddButton()"
        (clicked)="handleCreateValueTransation()"
      ></atom-vfi-button>
    </div>
  </ng-template>
</nz-modal>
