<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="{{
    mode() === 'create' ? 'Add an Integration' : 'Edit Integration'
  }}"
  [nzBodyStyle]="{ 'min-height': '400px' }"
  [nzFooter]="modalFooter"
  [nzWidth]="900"
  [nzZIndex]="modalZIndex"
  (nzOnCancel)="closeModal.emit()"
>
  <ng-template nzModalContent>
    <section
      aria-label="createIntegrationFields"
      class="d-flex scrollable-frame modalBodyHeight"
    >
      <div class="d-flex flex-column" [formGroup]="form()">
        <div
          class="input-wrapper mr-2 mb-1"
          [ngClass]="{
            'input-active': form()?.controls?.displayName?.value,
          }"
        >
          <input
            aria-label="profile-first-name"
            [ngClass]="{
              'required-field': form().controls.displayName.invalid,
            }"
            #displayName
            type="text"
            class="form-control"
            formControlName="displayName"
          />
          <label>Integration Name</label>
        </div>
        <div class="mb-1">
          <atom-dropdown-menu
            [name]="'direction'"
            [options]="integrationDirectionOptions"
            [label]="'Integration Direction'"
            [value]="form()?.controls.direction?.value"
            [multiple]="false"
            [width]="270"
            [required]="
              form()?.controls?.direction?.value === null ||
              form()?.controls?.direction?.value === ''
            "
            [disabled]="mode() === 'edit'"
            (valueChange)="updateFormValue($event, 'direction')"
          ></atom-dropdown-menu>
        </div>
        <div
          *ngIf="
            form()?.controls?.direction?.value !== null &&
            form()?.controls.direction?.value !== ''
          "
        >
          <atom-dropdown-menu
            [name]="'schema'"
            [options]="integrationTypeOptions()"
            [label]="'Integration Type'"
            [value]="form()?.controls?.schema?.value"
            [multiple]="false"
            [width]="270"
            [disabled]="mode() === 'edit'"
            (valueChange)="updateFormValue($event, 'schema')"
          ></atom-dropdown-menu>
        </div>
        <div
          class="input-wrapper mr-2 mb-1"
          *ngIf="
            mode() === 'edit' &&
            CmmsIntegrationType.includes(form().controls.schema.value)
          "
          [ngClass]="{
            'input-active':
              form()?.controls?.workTicketObjectiveTemplate?.value,
          }"
        >
          <input
            aria-label="work-ticket-objective-template"
            [ngClass]="{
              'required-field':
                form().controls.workTicketObjectiveTemplate.invalid,
            }"
            #workTicketObjectiveTemplate
            type="text"
            maxLength="255"
            class="form-control"
            formControlName="workTicketObjectiveTemplate"
          />
          <label>Work Ticket Objective Default Value</label>
        </div>
      </div>
      <div>
        <div *ngFor="let field of dynamicFields">
          <div
            *ngIf="field.type === 'select' && showDynamicField(field)"
            class="mb-1"
          >
            <atom-dropdown-menu
              [attr.aria-label]="field.formName"
              [name]="field.formName"
              [options]="field.options"
              [label]="field.name"
              [value]="dynamicForm()?.controls[field.formName]?.value"
              [multiple]="false"
              [width]="270"
              [required]="
                (dynamicForm()?.controls[field.formName]?.value === null ||
                  dynamicForm()?.controls[field.formName]?.value === '') &&
                field?.required
              "
              [disabled]="field?.editDisabled && mode() === 'edit'"
              (valueChange)="updateDynamicFormValue($event, field.formName)"
            ></atom-dropdown-menu>
          </div>
          <div *ngIf="field.type === 'slider'" class="mb-1">
            <div
              class="gray-10-bg pl-2 pr-2 pt-1 pb-1 slider-inputs d-flex flex-column justify-content-center"
            >
              <span class="slider-placeholder">{{ field.name }}</span>
              <div class="d-flex align-items-center">
                <span class="regular-16 neutral-1 mt-1 mr-1"
                  >{{
                    dynamicForm()?.controls[field.formName]?.value || 0
                  }}%</span
                >
                <nz-slider
                  [attr.aria-label]="field.formName"
                  class="w-100"
                  [ngModel]="dynamicForm()?.controls[field.formName]?.value"
                  [nzMax]="100"
                  (ngModelChange)="
                    updateDynamicFormValue($event, field.formName)
                  "
                  [nzTooltipVisible]="false"
                ></nz-slider>
              </div>
            </div>
          </div>
          <div *ngIf="field.type === 'text' && showDynamicField(field)">
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': dynamicForm()?.controls[field.formName]?.value,
              }"
            >
              <input
                [attr.aria-label]="field.formName"
                [ngClass]="{
                  'required-field':
                    dynamicForm()?.controls[field.formName]?.invalid,
                }"
                type="text"
                class="form-control"
                [ngModel]="dynamicForm()?.controls[field.formName]?.value"
                (ngModelChange)="updateDynamicFormValue($event, field.formName)"
              />
              <label>{{ field.name }}</label>
            </div>
          </div>
          <div *ngIf="field.type === 'number' && showDynamicField(field)">
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': dynamicForm()?.controls[field.formName]?.value,
              }"
            >
              <input
                [attr.aria-label]="field.formName"
                [ngClass]="{
                  'required-field':
                    dynamicForm()?.controls[field.formName]?.invalid,
                }"
                type="number"
                class="form-control"
                [ngModel]="dynamicForm()?.controls[field.formName]?.value"
                (ngModelChange)="updateDynamicFormValue($event, field.formName)"
              />
              <label>{{ field.name }}</label>
            </div>
          </div>
          <div *ngIf="field.type === 'password' && showDynamicField(field)">
            <div
              class="input-wrapper mr-2 mb-1"
              [ngClass]="{
                'input-active': dynamicForm()?.controls[field.formName]?.value,
              }"
            >
              <input
                [attr.aria-label]="field.formName"
                [ngClass]="{
                  'required-field':
                    dynamicForm()?.controls[field.formName]?.invalid,
                }"
                type="password"
                class="form-control"
                [ngModel]="dynamicForm()?.controls[field.formName]?.value"
                (ngModelChange)="updateDynamicFormValue($event, field.formName)"
              />
              <label>{{ field.name }}</label>
            </div>
          </div>
        </div>
        <div
          *ngIf="mode() === 'edit'"
          class="input-wrapper mr-2 mb-1"
          [ngClass]="{
            'input-active': dynamicForm()?.controls?.externalAlertEmails?.value,
          }"
        >
          <input
            aria-label="externalAlertEmails"
            type="text"
            class="form-control"
            [ngModel]="dynamicForm()?.controls?.externalAlertEmails?.value"
            (ngModelChange)="
              updateDynamicFormValue($event, 'externalAlertEmails')
            "
          />
          <label>External Alert Emails</label>
        </div>
        <div
          *ngIf="
            mode() === 'edit' && editData()?.type !== externalSystemTypes.WORK
          "
        >
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active':
                dynamicForm()?.controls?.alarmThroughputEmailThreshold?.value >=
                  0 &&
                dynamicForm()?.controls?.alarmThroughputEmailThreshold
                  ?.value !== null,
            }"
          >
            <input
              aria-label="alarmThroughputEmailThreshold"
              type="number"
              class="form-control"
              min="0"
              [ngModel]="
                dynamicForm()?.controls?.alarmThroughputEmailThreshold?.value
              "
              (ngModelChange)="
                updateDynamicFormValue($event, 'alarmThroughputEmailThreshold')
              "
            />
            <label>Throughput Alert Delay (hr)</label>
          </div>
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active':
                dynamicForm()?.controls?.lastAccessOnSlackThreshold?.value >=
                  0 &&
                dynamicForm()?.controls?.lastAccessOnSlackThreshold?.value !==
                  null,
            }"
          >
            <input
              aria-label="lastAccessOnSlackThreshold"
              type="number"
              class="form-control"
              min="0"
              [ngModel]="
                dynamicForm()?.controls?.lastAccessOnSlackThreshold?.value
              "
              (ngModelChange)="
                updateDynamicFormValue($event, 'lastAccessOnSlackThreshold')
              "
            />
            <label>Connectivity Alert Delay (min)</label>
          </div>
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active':
                dynamicForm()?.controls?.lastAccessOnResolutionThreshold
                  ?.value >= 0 &&
                dynamicForm()?.controls?.lastAccessOnResolutionThreshold
                  ?.value !== null,
            }"
          >
            <input
              aria-label="lastAccessOnResolutionThreshold"
              type="number"
              class="form-control"
              min="0"
              [ngModel]="
                dynamicForm()?.controls?.lastAccessOnResolutionThreshold?.value
              "
              (ngModelChange)="
                updateDynamicFormValue(
                  $event,
                  'lastAccessOnResolutionThreshold'
                )
              "
            />
            <label>Connectivity Resolution Delay (min)</label>
          </div>
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active':
                dynamicForm()?.controls?.etlNormalizeVersion?.value !== null,
            }"
          >
            <atom-dropdown-menu
              [name]="'etlNormalizeVersion'"
              [options]="etlNormalizerVersionOptions"
              [label]="'ETL Normalizer Version'"
              [value]="dynamicForm()?.controls.etlNormalizeVersion?.value"
              [multiple]="false"
              [width]="270"
              (valueChange)="
                updateDynamicFormValue($event, 'etlNormalizeVersion')
              "
            ></atom-dropdown-menu>
          </div>
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active':
                dynamicForm()?.controls?.etlIgnorableStates?.value !== null,
            }"
          >
            <atom-dropdown-menu
              [name]="'etlIgnorableStates'"
              [options]="etlIgnorableStateOptions"
              [label]="'ETL Ignorable States'"
              [value]="dynamicForm()?.controls.etlIgnorableStates?.value"
              [multiple]="true"
              [width]="270"
              (valueChange)="
                updateDynamicFormValue($event, 'etlIgnorableStates')
              "
            ></atom-dropdown-menu>
          </div>
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active':
                dynamicForm()?.controls?.etlTimestampOffset?.value !== null,
            }"
          >
            <input
              aria-label="etlTimestampOffset"
              type="number"
              class="form-control"
              [ngModel]="dynamicForm()?.controls?.etlTimestampOffset?.value"
              (ngModelChange)="
                updateDynamicFormValue($event, 'etlTimestampOffset')
              "
            />
            <label>ETL Timestamp Offset (hours)</label>
          </div>
        </div>
        <div
          *ngIf="mode() === 'edit'"
          class="input-wrapper mr-2 mb-1"
          [ngClass]="{
            'input-active':
              dynamicForm()?.controls?.cmmsWorkTicketSyncThreshold?.value !==
              null,
          }"
        >
          <input
            aria-label="cmmsWorkTicketSyncThreshold"
            type="number"
            class="form-control"
            min="0"
            [ngModel]="
              dynamicForm()?.controls?.cmmsWorkTicketSyncThreshold?.value
            "
            (ngModelChange)="
              updateDynamicFormValue($event, 'cmmsWorkTicketSyncThreshold')
            "
          />
          <label>Cmms Work Ticket Sync Threshold</label>
        </div>
      </div>
      <div class="ml-2" *ngIf="mode() === 'edit'">
        <div class="d-flex align-items-center">
          <nz-switch
            [ngModel]="dynamicForm()?.controls?.isExternalAlertsEnabled?.value"
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
            (ngModelChange)="
              updateDynamicFormValue($event, 'isExternalAlertsEnabled')
            "
          ></nz-switch>
          <p class="mb-0 dm00 regular-14 ml-2">Enable external status alerts</p>
        </div>
        <div class="d-flex align-items-center">
          <nz-switch
            [ngModel]="dynamicForm()?.controls?.hasProcessVariables?.value"
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
            (ngModelChange)="
              updateDynamicFormValue($event, 'hasProcessVariables')
            "
          ></nz-switch>
          <p class="mb-0 dm00 regular-14 ml-2">
            Enable process variables in Alarm Instance History
          </p>
        </div>
        <div class="d-flex align-items-center">
          <nz-switch
            [ngModel]="
              dynamicForm()?.controls?.isAutomationRuleCmmsSyncEnabled?.value
            "
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
            (ngModelChange)="
              updateDynamicFormValue($event, 'isAutomationRuleCmmsSyncEnabled')
            "
          ></nz-switch>
          <p class="mb-0 dm00 regular-14 ml-2">
            Allow CMMS sync on automation rules
          </p>
        </div>
        <div class="d-flex align-items-center">
          <nz-switch
            [ngModel]="dynamicForm()?.controls?.isCloseWorkButtonEnabled?.value"
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
            (ngModelChange)="
              updateDynamicFormValue($event, 'isCloseWorkButtonEnabled')
            "
          ></nz-switch>
          <p class="mb-0 dm00 regular-14 ml-2">
            Show Close option on work ticket
          </p>
        </div>
        <div class="d-flex align-items-center">
          <nz-switch
            [ngModel]="
              dynamicForm()?.controls?.isCmmsPollingBackSyncEnabled?.value
            "
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
            (ngModelChange)="
              updateDynamicFormValue($event, 'isCmmsPollingBackSyncEnabled')
            "
          ></nz-switch>
          <p class="mb-0 dm00 regular-14 ml-2">
            Sync external work changes to internal work tickets
          </p>
        </div>
      </div>
    </section>

    <div
      aria-label="licenseKey"
      *ngIf="mode() === 'edit' && licenseKey()"
      class="mt-3"
    >
      <div class="input-wrapper mb-1 input-active licenseKey">
        <textarea
          class="form-control"
          [value]="licenseKey()"
          disabled="true"
        ></textarea>
        <label>License Key</label>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <div class="w-100 d-flex justify-content-end">
      <atom-vfi-button
        [text]="'Cancel'"
        [type]="'secondary'"
        (clicked)="closeModal.emit()"
      ></atom-vfi-button>
      <atom-vfi-button
        *ngIf="mode() === 'create'"
        class="ml-1"
        [text]="'Add Integration'"
        [type]="'primary'"
        [disabled]="form().invalid || dynamicForm().invalid"
        (clicked)="handleCreateIntegration()"
      ></atom-vfi-button>
      <atom-vfi-button
        *ngIf="mode() === 'edit'"
        class="ml-1"
        [text]="'Save Integration'"
        [type]="'primary'"
        [disabled]="
          form().invalid ||
          dynamicForm().invalid ||
          (form().pristine && dynamicForm().pristine)
        "
        (clicked)="handleUpdateIntegration()"
      ></atom-vfi-button>
    </div>
  </ng-template>
</nz-modal>

<ng-template #checkedTemplate><i class="fas fa-check dm00"></i></ng-template>
<ng-template #unCheckedTemplate><i class="fas fa-times dm00"></i></ng-template>
