import { GroupCriteriaClause } from '@vfi-ui/models';
import { Component, input, output } from '@angular/core';
import { NgIf } from '@angular/common';
import { NzRadioGroupComponent, NzRadioComponent } from 'ng-zorro-antd/radio';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'atom-and-or',
  templateUrl: './and-or.component.html',
  styleUrls: ['./and-or.component.scss'],
  imports: [NgIf, NzRadioGroupComponent, FormsModule, NzRadioComponent],
})
export class AndOrComponent {
  readonly show = input<boolean>(undefined);
  readonly value = input<string>(undefined);
  readonly valueChanged = output<GroupCriteriaClause>();
  groupClause = GroupCriteriaClause;

  constructor() {}
}
