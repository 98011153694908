import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  SimpleChanges,
  input,
  output,
  model,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CreateCustomers, Customers, MODAL_Z_INDEX } from '@vfi-ui/models';
import { get, head, keys } from '@vfi-ui/util/helpers';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { NgIf } from '@angular/common';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'nuclei-create-customer-model',
  templateUrl: './create-customer-model.component.html',
  styleUrls: ['./create-customer-model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxComponent,
    NgIf,
    NzDatePickerComponent,
    VfiButtonComponent,
  ],
})
export class CreateCustomerModelComponent implements OnInit, OnChanges {
  readonly mode = input('create');
  readonly customer = input<Customers>(undefined);
  readonly isVisible = model(false);
  readonly disableAddButton = input(false);
  readonly closeModal = output<boolean>();
  readonly addCustomer = output<CreateCustomers>();
  readonly updateCustomer = output<CreateCustomers>();
  createCustomerForm: UntypedFormGroup;
  pilotEnabled = false;
  modalZIndex = MODAL_Z_INDEX;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.createCustomerForm = this.formBuilder.group({
      name: [null, Validators.required],
      domains: null,
      pilotDateEnd: null,
      isPilot: false,
      isSSOEnabled: false,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const mode = this.mode();
    if (get(changes.isVisible, 'currentValue', false) && mode === 'create') {
      this.createCustomerForm.reset();
    }

    if (changes?.isVisible?.currentValue && mode === 'edit') {
      this.populateForm(this.customer());
    }
  }

  /**
   * handle create customer event
   *
   * @memberof CreateCustomerModelComponent
   */
  createCustomer(mode: string) {
    if (mode === 'edit') {
      this.updateCustomer.emit(this.createCustomerForm.value);
    } else {
      this.addCustomer.emit(this.createCustomerForm.value);
    }
  }

  /**
   * populate form
   *
   * @param {Customers} customer
   * @memberof CreateCustomerModelComponent
   */
  populateForm(customer: Customers) {
    const objKeys = keys(this.createCustomerForm.value);
    objKeys.forEach((key) => {
      if (key === 'domains') {
        this.createCustomerForm.controls[key].setValue(head(customer[key]));
      } else {
        this.createCustomerForm.controls[key].setValue(customer[key]);
      }
      this.createCustomerForm.controls[key].updateValueAndValidity();
    });
    this.createCustomerForm.updateValueAndValidity();
  }

  /**
   * handle modal close event
   *
   * @memberof CreateCustomerModelComponent
   */
  handleCancel() {
    this.createCustomerForm.reset();
    this.closeModal.emit(false);
  }
}
