<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Add Relation"
  [nzBodyStyle]="{ 'min-height': '200px' }"
  [nzFooter]="modalFooter"
  [nzWidth]="460"
  [nzZIndex]="modalZIndex"
  (nzOnCancel)="closeModal.emit()"
>
  <ng-template nzModalContent>
    <section
      aria-label="createCmmsRelation"
      class="d-flex scrollable-frame modalBodyHeight"
    >
      <div class="d-flex flex-column">
        <div class="d-flex flex-row">
          <p>
            Please enter the parent field to relate to
            <strong>{{ selectedCmmsField().name }}</strong> field
          </p>
        </div>
        <div class="d-flex" [formGroup]="form">
          <div
            class="input-wrapper mr-2 mb-1"
            [ngClass]="{
              'input-active': form?.controls?.parentFieldId?.value,
            }"
          >
            <atom-dropdown-menu
              aria-label="parent-field-dropdown"
              [name]="'parentFieldId'"
              [options]="fieldOptions$ | async"
              [label]="'Parent Field'"
              (optionSearch)="setFields($event)"
              [value]=""
              [multiple]="false"
              (valueChange)="updateParentId($event)"
            ></atom-dropdown-menu>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
  <ng-template #modalFooter>
    <div class="w-100 d-flex justify-content-end">
      <atom-vfi-button
        [text]="'Cancel'"
        [type]="'secondary'"
        (clicked)="handleCancel()"
      ></atom-vfi-button>
      <atom-vfi-button
        class="ml-1"
        [text]="'Add Relation'"
        [type]="'primary'"
        [disabled]="form.invalid || disableAddButton()"
        (clicked)="handleCreateCmmsRelation()"
      ></atom-vfi-button>
    </div>
  </ng-template>
</nz-modal>
