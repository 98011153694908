import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import en from '@angular/common/locales/en';
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  RouterStateSnapshot,
  Routes,
  withRouterConfig,
} from '@angular/router';
import { provideNgIdle } from '@ng-idle/core';
import { AuthGuard } from '@vfi-ui/data-access/shared';
import { environment } from '@vfi-ui/environments/environment';
import { AdminLayoutComponent } from '@vfi-ui/feature/admin-dashboard';
import {
  getCoreProvider,
  MeResolver,
  SuperUserResolver,
} from '@vfi-ui/feature/core';
import { Indicator404Component } from '@vfi-ui/ui/atoms';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { AUTH_ROUTES, GRAPHQL_PROVIDER } from '@vfi-ui/feature/auth';
import { AG_GRID_LICENSE_KEY } from '@vfi-ui/models';
import { LicenseManager } from 'ag-grid-enterprise';
import { STATE_PROVIDER } from '@vfi-ui/state';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { HttpConfigInterceptor } from '@vfi-ui/feature/auth';
import { CancelHttpInterceptor } from '@vfi-ui/data-access/shared';
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
registerLocaleData(en);

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [
      (state: RouterStateSnapshot) => inject(AuthGuard).canActivate(state),
    ],
    resolve: {
      me: () => inject(MeResolver).resolve(),
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/users',
      },
      {
        path: '',
        canActivate: [
          (state: RouterStateSnapshot) => inject(AuthGuard).canActivate(state),
        ],
        resolve: {
          me: () => inject(MeResolver).resolve(),
          superUser: () => inject(SuperUserResolver).resolve(),
        },
        children: [
          {
            path: 'users',
            loadChildren: () =>
              import('@vfi-ui/feature/admin').then((m) => m.ADMIN_ROUTES),
          },
          {
            path: 'alarm-throughput',
            loadChildren: () =>
              import('@vfi-ui/feature/alarm-throughput').then(
                (m) => m.ALARM_THROUGHPUT_ROUTES
              ),
          },
        ],
      },
    ],
  },
  // Authentication and other `ROOT-LEVEL` Routes
  // ie: /login (within AuthModuleRoutes)
  // ... etc ...
  {
    path: '',
    children: [...AUTH_ROUTES],
  },
  { path: '404', component: Indicator404Component },
  { path: '**', pathMatch: 'full', redirectTo: '404' },
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
    provideAnimations(),
    provideNgIdle(),
    { provide: NZ_I18N, useValue: en_US },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideHttpClient(withInterceptorsFromDi()),
    GRAPHQL_PROVIDER,
    STATE_PROVIDER,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CancelHttpInterceptor,
      multi: true,
    },
    importProvidersFrom(NzModalModule),
    getCoreProvider(environment),
  ],
};
