import {
  Component,
  ElementRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  input,
  output,
  viewChild,
} from '@angular/core';
import {
  SelectSize,
  VfiSelectOption,
  VfiSelectParentData,
} from '@vfi-ui/models';
import { fastParse } from '@vfi-ui/util/helpers';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { VfiButtonComponent } from '../vfi-button/vfi-button.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'atom-vfi-multi-select',
  templateUrl: './vfi-multi-select.component.html',
  styleUrls: ['./vfi-multi-select.component.scss'],
  imports: [
    NzPopoverDirective,
    NgClass,
    NgIf,
    VfiButtonComponent,
    InfiniteScrollDirective,
    NgFor,
  ],
})
export class VfiMultiSelectComponent implements OnChanges {
  readonly searchInput = viewChild<ElementRef>('searchInput');
  readonly size = input(SelectSize.MEDIUM);
  readonly ariaLabel = input('vfi-multi-select');
  readonly placeholder = input('');
  readonly value = input<string[]>(undefined);
  readonly label = input<string>(undefined);
  readonly hint = input<string>(undefined);
  readonly required = input(false);
  readonly iconLeft = input<string>(undefined);
  readonly readOnly = input(false);
  readonly disabled = input(false);
  readonly subtle = input(false);
  readonly success = input(false);
  readonly warning = input(false);
  readonly warningMessage = input<string>(undefined);
  readonly error = input(false);
  readonly errorMessage = input<string>(undefined);
  readonly showSearch = input(true);
  readonly options = input<VfiSelectOption[]>([]);
  readonly loading = input(false);
  readonly parentData = input<VfiSelectParentData>(undefined);
  readonly canClear = input(false);
  readonly autofocus = input(false);
  readonly useLabels = input(false);
  readonly cleared = output();
  readonly dropdownVisibleChanged = output<boolean>();
  readonly scrolled = output<boolean>();
  readonly backSelected = output<VfiSelectParentData>();
  readonly optionSelected = output<VfiSelectOption[]>();
  showDropdown = false;
  formattedOptions: VfiSelectOption[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.options?.currentValue) {
      this.formattedOptions = fastParse(this.options());
    }
  }

  /**
   * handle option select event
   *
   * @param {VfiSelectOption} option
   * @memberof VfiMultiSelectComponent
   */
  onOptionSelect(option: VfiSelectOption) {
    let o = this.value();
    if (!o?.includes(option?.value)) {
      o.push(option?.value);
    } else {
      o = o.filter((op) => op !== option?.value);
    }
    const options = o.map((op) =>
      this.options().find((opt) => opt?.value === op)
    );
    this.optionSelected.emit(options);
  }

  /**
   * handle on search event
   *
   * @param {string} ev
   * @memberof VfiMultiSelectComponent
   */
  onSearch(search: string) {
    if (search) {
      this.formattedOptions = this.options().filter((op) =>
        op.label.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      this.formattedOptions = this.options();
    }
  }

  /**
   * handle on back select event
   *
   * @param {VfiSelectParentData} parent
   * @memberof VfiMultiSelectComponent
   */
  onBackSelected(parent: VfiSelectParentData) {
    this.backSelected.emit(parent);
  }

  /**
   * handle popover visible change event
   *
   * @param {boolean} show
   * @memberof VfiMultiSelectComponent
   */
  onPopoverVisibleChange(show: boolean) {
    this.dropdownVisibleChanged.emit(show);
    if (show) {
      this.formattedOptions = this.options();
      this.autoFocusSearch();
    }
  }

  /**
   * handle options scroll event
   *
   * @memberof VfiMultiSelectComponent
   */
  onOptionsScroll() {
    this.scrolled.emit(true);
  }

  /**
   * auto focus on search input
   *
   * @memberof VfiMultiSelectComponent
   */
  autoFocusSearch() {
    this.cdr.detectChanges();
    if (this.autofocus() && this.showSearch()) {
      this.searchInput().nativeElement.focus();
    }
  }

  /**
   * format display values
   *
   * @returns
   * @memberof VfiMultiSelectComponent
   */
  formatDisplayValues() {
    if (this.useLabels()) {
      return this.value().join(', ');
    }
    return this.value()
      .map((v) => this.options().find((o) => o.value === v)?.label)
      .join(', ');
  }

  /**
   * return if value is checked
   *
   * @param {VfiSelectOption} ev
   * @returns
   * @memberof VfiMultiSelectComponent
   */
  isChecked(ev: VfiSelectOption) {
    const value = this.value();
    return value ? value.includes(ev?.value) : false;
  }
}
