<div
  class="login w-100 d-flex align-content-center justify-content-center ios-header"
>
  <div class="align-self-center">
    <div class="login_wrapper">
      <div class="login_form">
        <div class="login_box">
          <div
            class="regular-24 cursor-pointer"
            *ngIf="mfa.showSecure || mfa.showVerification"
            (click)="handleBackButton()"
          >
            &lt;
          </div>
          <section
            class="login_content d-flex flex-column align-items-center justify-content-between"
          >
            <img
              src="/assets/vfi-logo.svg"
              alt="vfi-logo"
              class="vfi-logo mb-4"
            />

            <div class="login_content" *ngIf="mfa.showSecure">
              <div
                *ngIf="!selectedFactorId"
                class="form_wrapper d-flex flex-column align-items-center w-100"
              >
                <p>Select your method of MFA</p>
                <atom-vfi-button
                  id="totpEnrollBtn"
                  text="Authenticator App"
                  [ariaLabel]="'totpEnrollBtn'"
                  type="primary"
                  [classes]="'block-button'"
                  (clicked)="selectFactor(totpFactorId)"
                ></atom-vfi-button>
              </div>
            </div>
            <div class="login_content" *ngIf="mfa.showVerification">
              <div
                class="form_wrapper d-flex flex-column align-items-center w-100"
              >
                <qrcode
                  *ngIf="totpUrl"
                  [qrdata]="totpUrl"
                  [width]="256"
                  [errorCorrectionLevel]="'M'"
                ></qrcode>
                <h1>Enter the Verification Code</h1>
                <form class="w-100">
                  <div>
                    <code-input
                      class="mb-3"
                      #codeInputEl
                      codeLength="6"
                      [initialFocusField]="0"
                      (codeCompleted)="onCodeCompleted($event)"
                    >
                    </code-input>
                    <div *ngIf="mfa.errorMessage" class="red mt-2 mb-2">
                      {{ mfa.errorMessage }}
                    </div>
                    <atom-vfi-button
                      text="Submit"
                      ariaLabel="submitBtn"
                      [classes]="'block-button'"
                      [loading]="mfaVerificationLoading"
                      (clicked)="onCodeCompleted(codeInput().code)"
                    ></atom-vfi-button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
