<div
  class="w-100 select-wrapper"
  [ngClass]="fullInput() ? 'full-form-group' : 'form-group'"
  *ngIf="!newDisabled()"
  [style.width.px]="width()"
>
  <div
    class="input-wrapper caret-wrapper h-100"
    [ngClass]="{
      'required-select': required(),
      'input-active': value()?.length > 0 || focused(),
    }"
  >
    <ng-select
      #select
      [items]="options"
      [loadingText]="
        customQuery() === 'alarmId' ? 'Search for an Alarm ID' : 'Loading'
      "
      [addTag]="allowNewValues()"
      [addTagText]="'Add new value'"
      [multiple]="true"
      [closeOnSelect]="false"
      [searchable]="false"
      bindLabel="label"
      bindValue="value"
      [clearable]="false"
      [readonly]="disabled()"
      [(ngModel)]="value"
      [ngClass]="{
        'value-selected': value()?.length > 0,
        'no-placeholder': !showPlaceholder(),
        fullInput: fullInput(),
        defaultInput: !fullInput(),
        'cursor-default': disabled(),
      }"
      [appendTo]="appendTo()"
      [attr.name]="name()"
      [virtualScroll]="true"
      (search)="fetchFields($event?.term)"
      (focus)="fetchFields($event?.term); focused.set(true)"
      (change)="valueChanged($event, value())"
      (open)="handleOpenChange($event)"
      (add)="addNewTag($event)"
      (close)="handleCloseChange()"
      (scrollToEnd)="onScroll(searchValue)"
      attr.aria-label="{{ name() }}-select-dropdown"
    >
      <ng-template ng-header-tmp>
        <input
          [id]="'ng-dropdown-input-' + name()"
          class="ng-dropdown-input"
          type="text"
          placeholder="Search {{ placeholder() }}"
          (input)="select.filter($event.target.value)"
          attr.aria-label="{{ name() }}-search-input"
          tabIndex="-1"
        />
      </ng-template>

      <ng-template ng-option-tmp let-item$="item$" let-item="item">
        <div
          class="d-flex justify-content-between"
          attr.aria-label="{{ item?.label }}-option"
        >
          <span class="ellipsis">{{ item?.label }}</span>
          <i *ngIf="item$.selected" class="fa fa-check"></i>
        </div>
      </ng-template>
    </ng-select>
    <label class="z-index-max"
      >{{ showPlaceholder() && !fullInput() ? placeholder() || name() : '' }}
      {{ requiredPlaceholder() ? '*' : '' }}</label
    >
  </div>
</div>

<atom-disabled-input
  *ngIf="newDisabled()"
  [placeholder]="placeholder()"
  [values]="value()"
></atom-disabled-input>
