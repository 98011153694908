import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthService } from '@vfi-ui/data-access/shared';
import { getCurrentYear } from '@vfi-ui/util/helpers';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NgIf } from '@angular/common';
import { VfiButtonComponent } from '@vfi-ui/ui/atoms';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'vfi-auth-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NzInputDirective,
    NgIf,
    VfiButtonComponent,
    RouterLink,
  ],
})
export class ResetPasswordComponent {
  submitted = false;
  currentYear = getCurrentYear();
  resetForm = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
  });
  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {}

  /**
   * returns resetForm controls
   *
   * @readonly
   * @memberof ResetPasswordComponent
   */
  get resetFormControls() {
    return this.resetForm.controls;
  }

  /**
   * reset password
   *
   * @param {string} password
   * @memberof ResetPasswordComponent
   */
  resetPassword(value: any) {
    this.submitted = true;
    if (this.resetForm.valid) {
      this.auth.resetPassword(value.email);
    }
  }
}
