import { Component, input, output } from '@angular/core';

@Component({
  selector: 'nuclei-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.scss'],
})
export class PopupContainerComponent {
  readonly togglePopup = output<void>();
  readonly title = input<string>(undefined);

  /**
   * toggle close event
   * @param event
   * @memberof PopupContainerComponent
   */
  onTogglePopup(event) {
    this.togglePopup.emit(event);
  }
}
