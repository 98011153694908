import { Component, OnChanges, SimpleChanges, input } from '@angular/core';
import { AlarmPriorityIcon } from '@vfi-ui/models';
import { isNil } from '@vfi-ui/util/helpers';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NgClass } from '@angular/common';

@Component({
  selector: 'atom-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  imports: [NzTooltipDirective, NgClass],
})
export class IconComponent implements OnChanges {
  readonly id = input<string>(undefined);
  readonly priority = input<number>(undefined);
  readonly status = input<string>(undefined);
  readonly nuisanceCount = input<number>(undefined);
  readonly bellIcon = input<string>(undefined);
  readonly priorityColor = input<string>(undefined);
  readonly showBackground = input(true);
  readonly size = input(16);
  readonly showTooltip = input(false);
  iconPrefix: string;
  iconBell: string;
  iconColor: string;

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    if (
      (change.id && change.id.currentValue) ||
      (change.priority && !isNil(change.priority.currentValue))
    ) {
      this.iconPrefix = this.updatePrefix(this.status());
      this.iconBell = AlarmPriorityIcon;
      this.iconColor = this.updateColor(this.priority());
    }
  }

  /**
   * update icon prefix based on status
   *
   * @param {string} status
   * @returns {string}
   * @memberof IconComponent
   */
  updatePrefix(status: string): string {
    return status?.toLowerCase() === 'inactive' ? 'fal' : 'fas';
  }

  /**
   * update icon color based on priority
   *
   * @param {number} priority
   * @returns {string}
   * @memberof IconComponent
   */
  updateColor(priority: number): string {
    return `priority-${priority}`;
  }
}
