import {
  AlarmInstanceCoveredSource,
  ASSET_CRITICALITY_VALUE_MAP,
  AssetStatus,
  AssetStatusComparatorValues,
  AUTOMATION_CREATED_GRID_FILTER,
  CoreAlarm,
  CREATOR_TEAM_GRID_FILTER,
  CREATOR_USER_GRID_FILTER,
  CriterionSelection,
  KPI_DASHBOARD_WORK_TICKET_DRILLDOWN_BLACKLISTED_FILTERS,
  StaleStatus,
  WorkTicket,
} from '@vfi-ui/models';
import {
  CheckboxSelectionCallbackParams,
  HeaderCheckboxSelectionCallbackParams,
  ProcessCellForExportParams,
} from 'ag-grid-community';
import {
  intervalToDuration,
  isAfter,
  isBefore,
  millisecondsToSeconds,
  startOfDay,
} from 'date-fns';
import { fastParse, isNull } from '..';

/**
 * map KPI filters to AG-Grid filters
 *
 * @export
 * @param {*} drilldown
 * @param {*} baseWhereClause
 * @param {CriterionSelection[]} baseCriterion
 * @returns
 */
export function mapKpiFiltersToAgGridFilters(
  drilldown: any,
  baseWhereClause: any,
  baseCriterion: CriterionSelection[],
  workKpi = false
) {
  Object.keys(baseWhereClause).forEach((key) => {
    // Format attribute values
    if (key === 'attributes') {
      baseWhereClause[key].reduce((obj, key) => {
        // Only set filter if there is no pre-existing filter with the same key exists
        if (!obj.filters[key.type]) {
          Object.assign(obj.filters, {
            [key.type]: {
              values: key.values,
              filterType: 'set',
            },
          });
        }
        return obj;
      }, drilldown);
    } else {
      // Format non-attribute values
      // Do not apply 'isCovered' filter when formatting Work KPI since work table data will always be 'covered'
      // Do not apply 'coveredSource' filter when formatting Work KPI, it needs to be translated to automation created filter
      if (
        (!workKpi ||
          (workKpi &&
            !KPI_DASHBOARD_WORK_TICKET_DRILLDOWN_BLACKLISTED_FILTERS.includes(
              key
            ))) &&
        !drilldown.filters[key]
      ) {
        const value = baseWhereClause[key];
        if (key !== 'externalSystemId') {
          const propertyKey = key === 'alarmIds' ? 'alarmId' : key;
          Object.assign(drilldown.filters, {
            [propertyKey]: {
              values: Array.isArray(value)
                ? value.map((v) => String(v))
                : [String(value)],
              filterType: 'set',
            },
          });
        } else {
          // Map KPI alarm source ID to alarm history report alarm source name
          const values = baseCriterion.find(
            (crit) => crit?.detail?.dbName === key
          )?.detail?.selection?.name;
          Object.assign(drilldown.filters, {
            externalSystemDisplayName: {
              values,
              filterType: 'set',
            },
          });
        }
      } else if (workKpi && key === 'coveredSource') {
        // Convert 'coveredSource' to Automation Created filter
        if (!drilldown?.filters?.isAutomationCreated) {
          const automationCreatedFilter = fastParse(
            AUTOMATION_CREATED_GRID_FILTER
          );
          const value = String(
            baseWhereClause[key] === AlarmInstanceCoveredSource.Automation
          );
          automationCreatedFilter.isAutomationCreated.values = [value];
          Object.assign(drilldown.filters, automationCreatedFilter);
        }
      } else if (workKpi && key === 'externalSystemId') {
        // Map KPI alarm source ID to work history report alarm source name
        const values = baseCriterion.find(
          (crit) => crit?.detail?.dbName === key
        )?.detail?.selection?.name;
        Object.assign(drilldown.filters, {
          associatedAlarmSources: {
            values,
            filterType: 'set',
          },
        });
      }
    }
  });
  return drilldown;
}

/**
 * set ag grid automation created filter
 *
 * @export
 * @param {*} tableFilters
 * @param {boolean} automation
 * @returns
 */
export function setAgGridAutomationCreatedFilter(
  tableFilters,
  automation: boolean
) {
  const automationFilter = fastParse(AUTOMATION_CREATED_GRID_FILTER);
  automationFilter.isAutomationCreated.values = [String(automation)];
  tableFilters.filters = {
    ...tableFilters.filters,
    ...automationFilter,
  };
  return tableFilters;
}

/**
 * set ag grid creator team filter
 *
 * @export
 * @param {*} tableFilters
 * @param {string} teamName
 * @returns
 */
export function setAgGridCreatorTeamFilter(tableFilters, teamName: string) {
  const creatorTeamFilter = CREATOR_TEAM_GRID_FILTER;
  creatorTeamFilter.creatorTeams.values = [teamName];
  tableFilters.filters = { ...tableFilters.filters, ...creatorTeamFilter };
  return tableFilters;
}

/**
 * set ag grid created by filter
 *
 * @export
 * @param {*} tableFilters
 * @param {string} displayName
 * @returns
 */
export function setAgGridCreatedByFilter(tableFilters, displayName: string) {
  const createdFilter = fastParse(CREATOR_USER_GRID_FILTER);
  createdFilter.createdBy.values = [displayName];
  tableFilters.filters = {
    ...tableFilters.filters,
    ...createdFilter,
  };
  return tableFilters;
}

/**
 * format copy values for AG-Grid columns
 *
 * @export
 * @param {string} colId
 * @param {*} value
 * @param {boolean} [addPrefix=true]
 * @returns
 */
export function formatAgGridCopyValues(
  colId: string,
  value: any,
  addPrefix = true
) {
  const isArray = Array.isArray(value);
  if ((isArray && !value.length) || isNull(value)) {
    return '—';
  }
  if (colId === 'openWorkTickets') {
    return value.map((tickets) => tickets?.displayId).join(', ');
  }
  if (colId === 'id' && addPrefix) {
    return `A${value}`;
  }
  if (isArray) {
    return value.join(', ');
  }
  return value;
}

/**
 * date comparator for ag grid date filter
 *
 * @export
 * @param {string} compareDate
 * @param {string} cellValue
 * @returns
 */
export function agGridDateComparator(compareDate: string, cellValue: string) {
  const cellDate = startOfDay(new Date(cellValue));
  const gridDate = new Date(compareDate);
  if (isBefore(cellDate, gridDate)) {
    return -1;
  } else if (isAfter(cellDate, gridDate)) {
    return 1;
  }
  return 0;
}

/**
 * format duration values for ag grid export
 *
 * @export
 * @param {number} ms
 * @returns
 */
export function agGridProcessDurationExportCells(ms: number) {
  if (!ms) {
    return '';
  }
  const seconds = millisecondsToSeconds(ms);
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const days = duration?.days ? String(duration.days).padStart(2, '0') : '00';
  const hours = duration?.hours
    ? String(duration.hours).padStart(2, '0')
    : '00';
  const minutes = duration?.minutes
    ? String(duration.minutes).padStart(2, '0')
    : '00';

  return `${days}:${hours}:${minutes}`;
}

/**
 * format associated alarm values for ag grid export
 *
 * @export
 * @param {CoreAlarm[]} associatedAlarms
 * @returns
 */
export function agGridProcessAlarmExportCells(associatedAlarms: CoreAlarm[]) {
  return !associatedAlarms
    ? ''
    : associatedAlarms.map((alarm) => alarm?.displayId).join(', ');
}

/**
 * format associated work ticket values for ag grid export
 *
 * @export
 * @param {WorkTicket[]} associatedWorkTickets
 * @param {string} type
 * @returns
 */
export function agGridProcessWorkExportCells(
  associatedWorkTickets: WorkTicket[],
  type: string
) {
  if (!associatedWorkTickets) {
    return '';
  }
  let value: any = [];
  if (associatedWorkTickets.length) {
    if (associatedWorkTickets.length > 1) {
      value =
        type === 'Work ID'
          ? associatedWorkTickets.map((ticket) => `W${ticket?.id}`)
          : 'Multiple';
    } else if (associatedWorkTickets.length === 1) {
      switch (type) {
        case 'Work ID':
          value = `W${associatedWorkTickets[0].id}`;
          break;
        case 'Resolution':
          value = associatedWorkTickets[0]?.isResolved
            ? 'Resolved'
            : 'Unresolved';
          break;
        case 'Work Assignee':
          value = associatedWorkTickets[0]?.assigneeName;
          break;
        case 'Work Closed Time':
          value = associatedWorkTickets[0]?.closedAt;
          break;
        case 'Work Created Time':
          value = associatedWorkTickets[0]?.createdAt;
          break;
        case 'Work Closed State':
          value = associatedWorkTickets[0]?.state;
      }
    }
  }
  return value;
}

/**
 * return ag grid value for boolean values
 *
 * @export
 * @param {*} value
 * @returns
 */
export function agGridBooleanValueGetter(value) {
  if (Array.isArray(value)) {
    if (value.length === 1) {
      return value[0] ? 'True' : 'False';
    } else if (value.length > 1) {
      return 'Multiple';
    }
  } else {
    return value ? 'True' : 'False';
  }
}

/**
 * return ag grid value for boolean values that
 * need to display as "Yes" or "No"
 *
 * @export
 * @param {*} value
 * @returns
 */
export function agGridYesNoBooleanValueGetter(value) {
  return value ? 'Yes' : 'No';
}

/**
 * return ag grid value for stale status value
 *
 * @export
 * @param {*} value
 * @returns
 */
export function agGridStaleValueGetter(value) {
  if (Array.isArray(value)) {
    if (value.length === 1) {
      return value[0] ? StaleStatus.STALE : StaleStatus.NOT_STALE;
    } else if (value.length > 1) {
      return 'Multiple';
    }
  } else {
    return value ? StaleStatus.STALE : StaleStatus.NOT_STALE;
  }
}

/**
 * return ag grid value for multiple values
 *
 * @export
 * @param {*} value
 * @returns
 */
export function agGridMultiValueGetter(value) {
  if (!value || !value.length) {
    return '—';
  } else {
    if (value.length === 1) {
      return value[0];
    } else if (value.length > 1) {
      return 'Multiple';
    }
  }
}

/**
 * map em dash value to (Blanks) value in filters
 *
 * @export
 * @param {string} value
 * @returns
 */
export function agGridMapBlankFilters(value: string) {
  return value === '—' ? '(Blanks)' : value;
}

/**
 * ag grid number comparator
 * helps handle duration values with decimals
 *
 * @export
 * @param {*} valueA
 * @param {*} valueB
 * @returns
 */
export function agGridNumberComparator(valueA, valueB) {
  return +valueA - +valueB;
}

/**
 * sorts grouped rows by children count
 *
 * @export
 * @param {*} nodeA
 * @param {*} nodeB
 * @returns
 */
export function agGridChildCountComparator(nodeA, nodeB) {
  return nodeA?.allChildrenCount - nodeB?.allChildrenCount;
}

/**
 * sort asset criticality
 *
 * @export
 * @param {*} nodeA
 * @param {*} nodeB
 * @returns
 */
export function agGridAssetCriticalityComparator(nodeA, nodeB) {
  return (
    ASSET_CRITICALITY_VALUE_MAP.get(nodeA) -
    ASSET_CRITICALITY_VALUE_MAP.get(nodeB)
  );
}

/**
 * sort asset floor values
 *
 * @export
 * @param {*} a
 * @param {*} b
 * @returns
 */
export function agGridAssetFloorComparator(a, b) {
  return a === null || a === undefined
    ? -1
    : b === null || b === undefined
      ? 1
      : ('' + a).localeCompare(b, undefined, { numeric: true });
}

/**
 * sort asset status values
 * @param valueA
 * @param valueB
 * @returns
 */
export function agGridAssetStatusComparator(
  valueA: AssetStatus,
  valueB: AssetStatus
): number {
  if (valueA === valueB) return 0;
  return (
    AssetStatusComparatorValues[valueA] - AssetStatusComparatorValues[valueB]
  );
}
/**
 * sort array values
 *
 * @export
 * @param {any[]} valueA
 * @param {any[]} valueB
 * @return {number}
 */
export function agGridArrayComparator(valueA: any[], valueB: any[]): number {
  if (valueA === valueB) return 0;
  return valueA.length - valueB.length;
}

/**
 * format values for ag grid export
 *
 * @export
 * @param {ProcessCellForExportParams} params
 * @returns
 */
export function processExportCells(params: ProcessCellForExportParams) {
  let cellValue = params.value;
  const headerName = params.column.getColDef().headerName;

  // Format export values for associated work ticket values and duration values
  switch (headerName) {
    case 'Time in Alarm':
      cellValue = agGridProcessDurationExportCells(params.value);
      break;
    case 'Work ID':
      cellValue = agGridProcessWorkExportCells(params.value, 'Work ID');
      break;
    case 'Resolution':
      cellValue = agGridProcessWorkExportCells(params.value, 'Resolution');
      break;
    case 'Work Assignee':
      cellValue = agGridProcessWorkExportCells(params.value, 'Work Assignee');
      break;
    case 'Work Closed Time':
      cellValue = agGridProcessWorkExportCells(
        params.value,
        'Work Closed Time'
      );
      break;
    case 'Work Created Time':
      cellValue = agGridProcessWorkExportCells(
        params.value,
        'Work Created Time'
      );
      break;
    case 'Work Closed State':
      cellValue = agGridProcessWorkExportCells(
        params.value,
        'Work Closed State'
      );
      break;
    case 'Alarm ID':
      cellValue = `A${params?.value}`;
      break;
  }

  return cellValue;
}

/**
 * return if ag grid column is the first column
 * Note: use this to achieve checkboxes
 *
 * @private
 * @param {(CheckboxSelectionCallbackParams
 *       | HeaderCheckboxSelectionCallbackParams)} params
 * @returns
 */
export function isFirstColumn(
  params:
    | CheckboxSelectionCallbackParams
    | HeaderCheckboxSelectionCallbackParams
) {
  const displayedColumns = params.api.getAllDisplayedColumns();
  const thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}
