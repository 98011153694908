import { Team } from '../interfaces/team.interface';

export enum SocketEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
}
export const SOCKET_RETRYS = 5;
export const SOCKET_ERROR_MESSAGE =
  'For your protection, you have been automatically logged out. If you would like to reconnect, click OK';
export enum SocketAction {
  ALARM_INSTANCE_CREATED = 'alarm_instance_created',
  ALARM_INSTANCE_DELETE = 'alarm_instance_deleted',
  ALARM_INSTANCE_UPDATED = 'alarm_instance_updated',
  ALARM_CREATED = 'alarm_created',
  ALARM_DELETED = 'alarm_deleted',
  ALARM_UPDATED = 'alarm_updated',
  WORKORDER_CREATED = 'workorder_created',
  WORKORDER_DELETED = 'workorder_deleted',
  WORKORDER_UPDATED = 'workorder_updated',
  EXTERNAL_SYSTEM_UDPATES = 'external_system_updated',
  ALARM_ADDED = 'alarm_held',
  ALARM_REMOVED = 'alarm_unheld',
  ALARM_STATUS_CHANGE = 'alarm_status_changed',
  ALARM_COUNTS = 'alarm_count_updated',
  LENS_BADGE_COUNT_EVENT = 'lens_badge_count_event',
  LENS_EVENT = 'lens_event',
  DISABLE_USER = 'disable_user',
  BULK_JOB_CREATED = 'alarm_property_update_task_created',
  BULK_JOB_UPDATE = 'alarm_property_update_task_updated',
  BULK_JOB_COMPLETED = 'alarm_property_update_task_completed',
  CSV_DOWNLOAD = 'csv_download',
  ROLE_CHANGED = 'role_changed',
  WORK_TICKET_SYNC = 'work_ticket_sync',
  SITUATIONAL_AWARENESS_ENTITY_INGRESS = 'situational_awareness_entity_ingress',
  SITUATIONAL_AWARENESS_ENTITY_EGRESS = 'situational_awareness_entity_egress',
  SITUATIONAL_AWARENESS_ENTITY_UPDATED = 'situational_awareness_entity_updated',
  SITUATIONAL_AWARENESS_WORK_TICKET_INGRESS = 'situational_awareness_work_ticket_ingress',
  SITUATIONAL_AWARENESS_WORK_TICKET_EGRESS = 'situational_awareness_work_ticket_egress',
  SITUATIONAL_AWARENESS_VIEW_COUNTS = 'situational_awareness_view_count',
  REPORT_EXPORT_COMPLETE = 'report_export_complete',
  SITUATIONAL_AWARENESS_MAP_ENTITY_UPDATED = 'situational_awareness_map_entity_updated',
  TEAM_ADDED = 'team_added',
  TEAM_REMOVED = 'team_removed',
  SessionExpired = 'session_expired',
}

export interface Lens {
  id: string;
  type?: string;
  name?: string;
  team?: Team;
}

export enum LensEventType {
  IngressEvent = 'INGRESS_EVENT',
  EgressEvent = 'EGRESS_EVENT',
}

export interface LenBadgeCountEvent {
  lens: Lens;
  data: { entity: number };
}

export interface LensEntityEvent<T> {
  lens: Lens;
  data: { type: LensEventType; entity: T };
}
