import {
  Component,
  OnChanges,
  SimpleChanges,
  input,
  model,
  output,
} from '@angular/core';
import { TABLE_SIZE_OPTIONS } from '@vfi-ui/models';
import { get } from '@vfi-ui/util/helpers';
import { NzPaginationComponent } from 'ng-zorro-antd/pagination';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { NzSelectComponent, NzOptionComponent } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'atom-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  imports: [
    NzPaginationComponent,
    NgClass,
    NgIf,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
  ],
})
export class PaginationComponent implements OnChanges {
  readonly pageLimit = model<number>(undefined);
  readonly totalItems = input<number>(undefined);
  readonly allowPageChange = input(false);
  readonly disabled = input(false);
  readonly pageIndex = model(1);
  readonly resetCurrentPage = input(false);
  readonly pageChanged = output<number>();
  readonly pageSizeChanged = output<number>();
  pageOptions = TABLE_SIZE_OPTIONS;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const isTotalItemsChanged = get(changes.totalItems, 'currentValue', false);
    this.pageIndex.update((pageIndex) =>
      isTotalItemsChanged && !this.resetCurrentPage() ? 1 : pageIndex
    );
  }
}
