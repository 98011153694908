import { filterCategoryType, LensCategory } from '..';
import { OrderDirection } from '../constants/work-ticket.constant';

export enum DisplayOptionsCache {
  User = 'USER',
  Team = 'TEAM',
  Integration = 'INTEGRATION',
  AssetId = 'ASSET_ID',
}
export interface GlobalFilters {
  searchTerm?: string;
  criterion?: CriterionSelection[];
}

export interface GlobalFiltersWhere {
  _or: GlobalFiltersWhereOr[];
}

export interface GlobalFiltersWhereOr {
  buildings?: PropertyFilter;
  conditions?: PropertyFilter;
  contextualName?: FilterLike;
  databaseId?: FilterLike;
  floors?: PropertyFilter;
  rooms?: PropertyFilter;
  mediums?: PropertyFilter;
  parameters?: PropertyFilter;
  sensors?: PropertyFilter;
  spaceTypes?: PropertyFilter;
  workorders?: Workorders;
}

export interface GlobalSortAndFiltersCriterion {
  name: string;
  type: string;
  show?: boolean;
  apiName?: string;
  dbName?: string;
  dbProperty?: string;
  parentDbProperty?: string;
  query?: string;
  min?: number;
  max?: number;
  selection?: {
    value?: any;
    name?: any;
    min?: string;
    max?: string;
    maxDays?: number;
    minDays?: number;
    minHrs?: number;
    minMins?: number;
    maxHrs?: number;
    maxMins?: number;
    fullValues?: FilterDropdownOption[];
  };
  blanks?: boolean;
  multi?: boolean;
  selectValues?: any[];
  model?: string;
  isExclusion?: boolean;
  category: filterCategoryType;
  minMaxType?: string;
  newValues?: boolean;
}
export interface CriterionSelection {
  model: string | null;
  altModel?: string;
  detail?: GlobalSortAndFiltersCriterion;
  value?: any;
  selection?: string[];
}

interface PropertyFilter {
  name: FilterLike;
}

interface FilterLike {
  _ilike: string;
}

interface GreaterThan {
  _gt: number;
}

export interface GreaterThanWhere {
  [key: string]: GreaterThan;
}

interface Workorders {
  _or: WorkordersOr[];
}

interface WorkordersOr {
  building?: FilterLike;
  floor?: FilterLike;
  room?: FilterLike;
  shop?: FilterLike;
  equipment?: FilterLike;
  unit?: FilterLike;
}

export interface TileListActionsSortMenu {
  sortValue: string;
  sortType: string;
}

export interface NuisanceFiltersWhere {
  currentChatteringCount?: GreaterThan;
  currentFleetingCount?: GreaterThan;
  Alarm: GlobalFiltersWhereOr;
}

export interface GlobalFilterSort {
  parentSortValue?: string | null;
  sortValue?: string;
  sortType?: OrderDirection;
  sortDbName?: string | null;
  sortValue2?: string | null;
}

export interface LensRequiredInputs {
  name: string;
  sort: GlobalFilterSort | GlobalFilterSort[];
  isActive?: boolean;
  category?: LensCategory;
  description?: string;
}

export interface CriterionSelectionOutput {
  criterion: CriterionSelection[];
  lens: Partial<LensRequiredInputs>;
}
export interface FilterDropdownOption {
  name: string;
  value: string;
}

export interface SelectDropdownOption {
  label: string;
  value: string;
}
