<i
  *ngIf="workTicket()?.status === workTicketStatus.Open"
  class="fa-solid fa-clipboard-list regular-{{ size() }} openWork"
  nzTooltipTitle="Open Work"
  nzTooltipPlacement="top"
  nz-tooltip
></i>
<img
  *ngIf="workTicket()?.state === workTicketState.Closed && workTicket().isResolved"
  src="/assets/work-closed-good.svg"
  class="mr-1"
  [width]="size()"
  [height]="size()"
  alt="work_closed_good"
  nzTooltipTitle="Closed Resolved Work Ticket"
  nzTooltipPlacement="top"
  nz-tooltip
/>
<img
  *ngIf="workTicket()?.state === workTicketState.Closed && !workTicket().isResolved"
  src="/assets/work-closed-bad.svg"
  class="mr-1"
  [width]="size()"
  [height]="size()"
  alt="work_closed_bad"
  nzTooltipTitle="Closed Unresolved Work Ticket"
  nzTooltipPlacement="top"
  nz-tooltip
/>
