import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { AuthService } from '@vfi-ui/data-access/shared';
import { Observable } from 'rxjs';
import { ValidateUser } from '@vfi-ui/models';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { getCurrentYear } from '@vfi-ui/util/helpers';
import { NgIf, AsyncPipe } from '@angular/common';
import { SetPasswordComponent } from '@vfi-ui/ui/nucleus';
import { VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'vfi-auth-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  imports: [NgIf, SetPasswordComponent, VfiButtonComponent, AsyncPipe],
})
export class ChangePasswordComponent implements OnInit {
  formValid = false;
  user$: Observable<ValidateUser>;
  token: string;
  customerId: string;
  currentYear = getCurrentYear();

  passwordForm = this.formBuilder.group({
    password: [null, Validators.required],
    confirmPassword: [null, Validators.required],
  });

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(
        filter((params) => !!params),
        take(1)
      )
      .subscribe((params) => {
        this.token = params.token;
        this.customerId = params.cid;
        this.user$ = this.authService.validateToken(
          this.token,
          this.customerId
        );
      });
  }

  /**
   * sets formValid value
   *
   * @param {boolean} isValid
   * @memberof ChangePasswordComponent
   */
  checkFormValid(isValid: boolean) {
    this.formValid = isValid;
  }

  /**
   * calls auth service to activate user and redirect to application
   *
   * @param {string} email
   * @memberof ChangePasswordComponent
   */
  changePassword() {
    if (
      this.formValid &&
      this.passwordForm.value.password ===
        this.passwordForm.value.confirmPassword
    ) {
      const password = this.passwordForm.value.confirmPassword;
      this.authService
        .resetPasswordByApi(password, this.token, this.customerId)
        .subscribe((res) => {
          if (res) {
            window.location.href = '/login';
          }
        });
    } else {
      this.passwordForm.controls['confirmPassword'].setErrors({
        noMatch: true,
      });
    }
  }
}
