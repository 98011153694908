import { Action, State, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { patch } from '@ngxs/store/operators';

export class ResetCore {
  public static readonly type = '[Core] Reset core state';
}

export class ToggleMobileNavState {
  public static readonly type = '[Core] Toggle mobile navigation menu';
  constructor(public payload: boolean) {}
}

export class SetMobileState {
  public static readonly type = '[Core] Set mobile state';
  constructor(public payload: boolean) {}
}
export class SetReportExportId {
  public static readonly type = '[Core] Set report export Id';
  constructor(public payload: string) {}
}

export class ToggleSituationalAwareness {
  public static readonly type =
    '[Core] Set if user is on situational awareness';
  constructor(public payload: boolean) {}
}

export class CoreStateModel {
  mobileNavState: boolean;
  onSituationalAwareness: boolean;
  isMobile: boolean;
  reportExportId: string;
  diffChecker?: string;
}

const defaultCoreState: CoreStateModel = {
  mobileNavState: true,
  onSituationalAwareness: false,
  reportExportId: null,
  isMobile: false,
};

@State<CoreStateModel>({
  name: 'core',
  defaults: defaultCoreState,
})
@Injectable({
  providedIn: 'root',
})
export class CoreState {
  //TODO: Find permanent solution for Core state not updating. Temp solution in me.resolver

  @Selector()
  static getState(state: CoreStateModel) {
    return state;
  }

  @Selector()
  static isMobile(state: CoreStateModel) {
    return state.isMobile;
  }

  @Selector()
  static getReportExportId(state: CoreStateModel) {
    return state.reportExportId;
  }

  @Selector()
  static isMobileNavOpen(state: CoreStateModel) {
    return state.mobileNavState;
  }

  @Selector()
  public static isOnSituationalAwareness(state: CoreStateModel) {
    return state.onSituationalAwareness;
  }

  @Action(ToggleMobileNavState)
  public toggleMobileNav(ctx: StateContext<CoreStateModel>, { payload }) {
    ctx.setState(
      patch({
        mobileNavState: payload,
      })
    );
  }

  @Action(SetMobileState)
  public setMobileState(ctx: StateContext<CoreStateModel>, { payload }) {
    ctx.setState(
      patch({
        isMobile: payload,
      })
    );
  }

  @Action(SetReportExportId)
  public setReportExportId(ctx: StateContext<CoreStateModel>, { payload }) {
    ctx.setState(
      patch({
        reportExportId: payload,
      })
    );
  }

  @Action(ResetCore)
  resetCore(ctx: StateContext<CoreStateModel>) {
    ctx.setState(defaultCoreState);
  }

  @Action(ToggleSituationalAwareness)
  public toggleSituationalAwareness(
    ctx: StateContext<CoreStateModel>,
    { payload }
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      onSituationalAwareness: payload,
    });
  }
}
