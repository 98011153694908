import { Observable } from 'rxjs';
import { Customers, CUSTOMER_ID_HEADER } from '@vfi-ui/models';
import { AuthService } from '@vfi-ui/data-access/shared';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  getCurrentYear,
  isNil,
  navigateToDeeplink,
} from '@vfi-ui/util/helpers';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { NzSelectComponent, NzOptionComponent } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'vfi-auth-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    VfiButtonComponent,
    AsyncPipe,
  ],
})
export class CustomerSelectorComponent implements OnInit {
  customers$: Observable<Customers[]>;
  selectedCustomer = 1000;
  currentYear = getCurrentYear();
  isNil = isNil;
  storage = localStorage;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.customers$ = this.authService.getCustomers();
  }

  /**
   * select a customer
   *
   * @param {number} selectedCustomer
   * @memberof CustomerSelectorComponent
   */
  select(selectedCustomer: number) {
    this.selectedCustomer = selectedCustomer;
  }
  /**
   * set customer to localstorage and navigate to main route
   *
   * @param {Customers} selectedCustomer
   * @memberof CustomerSelectorComponent
   */
  login(selectedCustomer: number) {
    this.storage.setItem(CUSTOMER_ID_HEADER, String(selectedCustomer));
    navigateToDeeplink();
  }
}
