import {
  UPDATE_SITUATIONAL_AWARENESS_BLOCK,
  FETCH_SITUATIONAL_AWARENESS_ENTITIES,
  UPDATE_SITUATIONAL_AWARENESS_DIVISION,
  DELETE_SITUATIONAL_AWARENESS_BLOCK,
  FETCH_RELATED_ENTITIES,
  FETCH_CURRENT_ENTITY_ISSUES,
  GET_SITUATIONAL_AWARENESS_VIEW_COUNTS_QUERY,
  RECALCULATE_ALL_SAE,
  GET_VISIBLE_SITUATIONAL_AWARENESS_VIEW,
} from './../queries/situational-awareness.query';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import {
  OrderDirection,
  SituationalAwarenessTimers,
  SituationalDashboardEntitySortField,
  ERROR_SNOOZE_ENTITY,
  ERROR_UNSNOOZE_ENTITY,
  ERROR_CREATE_SITUATIONAL_AWARENESS_VIEW,
  SituationalDashboardView,
  SituationalDashboardViewUpdateInput,
  ERROR_UPDATE_SITUATIONAL_AWARENESS_VIEW,
  ERROR_DELETE_SITUATIONAL_AWARENESS_VIEW,
  SituationalDashboardBlock,
  SituationalDashboardBlockCreateInput,
  Entity,
  SituationalDashboardDivision,
  SituationalDashboardDivisionUpdateInput,
  SituationalAwarenessViewEntitySortOrder,
  RelatedEntityQuery,
  SituationalDashboardEntityAndCount,
} from '@vfi-ui/models';
import { Observable, throwError } from 'rxjs';
import { map, filter, catchError, tap } from 'rxjs/operators';
import {
  GET_SITUATIONAL_AWARENESS_ENTITIES,
  GET_SITUATIONAL_AWARENESS_TIMERS,
  SNOOZE_ENTITY,
  UNSNOOZE_ENTITY,
  GET_SITUATIONAL_AWARENESS_ENTITIY_COUNT,
  CREATE_SITUATIONAL_AWARENESS_VIEW,
  GET_SITUATIONAL_AWARENESS_VIEW,
  UPDATE_SITUATIONAL_AWARENESS_VIEW,
  DELETE_SITUATIONAL_AWARENESS_VIEW,
  CREATE_SITUATIONAL_AWARENESS_BLOCK,
} from '../queries/situational-awareness.query';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class SituationalAwarenessDataService {
  constructor(
    private apollo: Apollo,
    private notificationService: NotificationService
  ) {}

  /**
   * fetch situational awareness entities
   *
   * @param {*} where
   * @param {number} limit
   * @param {number} [offset=0]
   * @returns {Observable<SituationalDashboardEntity[]>}
   * @memberof SituationalAwarenessDataService
   */
  fetchSituationalAwarenessEntities(
    where,
    limit: number,
    sortOrder: SituationalAwarenessViewEntitySortOrder,
    offset = 0
  ): Observable<SituationalDashboardEntityAndCount> {
    return this.apollo
      .query<SituationalDashboardEntityAndCount>({
        fetchPolicy: 'no-cache',
        query: GET_SITUATIONAL_AWARENESS_ENTITIES,
        variables: {
          input: {
            limit,
            offset,
            order: [
              {
                field: SituationalDashboardEntitySortField.BuildingName,
                direction: OrderDirection.ASC,
              },
              {
                field: SituationalDashboardEntitySortField.State,
                direction: OrderDirection.ASC,
              },
              {
                field: SituationalDashboardEntitySortField.IsEscalated,
                direction: OrderDirection.DESC,
              },
              {
                field: SituationalDashboardEntitySortField.IsCovered,
                direction: OrderDirection.ASC,
              },
              {
                field: SituationalDashboardEntitySortField.StateChangedAt,
                direction:
                  sortOrder ===
                  SituationalAwarenessViewEntitySortOrder.LatestFirst
                    ? OrderDirection.DESC
                    : OrderDirection.ASC,
              },
            ],
            where,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data)
      );
  }

  /**
   * fetch entity counts
   *
   * @param {*} where
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  fetchSituationalAwarenessEntityCount(where) {
    return this.apollo
      .query<{ situationalDashboardEntityCount: number }>({
        fetchPolicy: 'no-cache',
        query: GET_SITUATIONAL_AWARENESS_ENTITIY_COUNT,
        variables: { input: { where } },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.situationalDashboardEntityCount)
      );
  }

  /**
   * fetch situational awareness timers
   *
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  fetchSituationalAwarenessTimers() {
    return this.apollo
      .query<{
        situationalDashboardThresholds: SituationalAwarenessTimers[];
      }>({
        fetchPolicy: 'no-cache',
        query: GET_SITUATIONAL_AWARENESS_TIMERS,
      })
      .pipe(
        filter((d) => !!d),
        map((res) =>
          res?.data?.situationalDashboardThresholds?.sort(
            (a, b) => b.criticality - a.criticality
          )
        )
      );
  }

  /**
   * snooze entity by id
   *
   * @param {number} entityId
   * @param {string} snoozeExpiresAt
   * @param {string} reason
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  snoozeEntity(entityId: number, snoozeExpiresAt: string, reason: string) {
    return this.apollo
      .mutate({
        mutation: SNOOZE_ENTITY,
        variables: {
          entityId,
          snoozeExpiresAt,
          reason,
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notificationService.showError(ERROR_SNOOZE_ENTITY);
          return throwError(error);
        })
      );
  }

  /**
   * unsnooze entity by id
   *
   * @param {number} entityId
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  unsnoozeEntity(entityId: number) {
    return this.apollo
      .mutate({
        mutation: UNSNOOZE_ENTITY,
        variables: {
          entityId,
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notificationService.showError(ERROR_UNSNOOZE_ENTITY);
          return throwError(error);
        })
      );
  }

  /**
   * create situational awareness view
   *
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  createSituationalAwarenessView() {
    return this.apollo
      .mutate<{ createSituationalDashboardView: SituationalDashboardView }>({
        mutation: CREATE_SITUATIONAL_AWARENESS_VIEW,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.createSituationalDashboardView),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_CREATE_SITUATIONAL_AWARENESS_VIEW
          );
          return throwError(error);
        })
      );
  }

  /**
   * fetch situational awareness views
   *
   * @param {*} [options={}]
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  fetchSituationalAwarenessView() {
    return this.apollo
      .query<{ situationalDashboardViews: SituationalDashboardView[] }>({
        fetchPolicy: 'no-cache',
        query: GET_SITUATIONAL_AWARENESS_VIEW,
        variables: {
          options: {
            order: { field: 'CREATED_AT', direction: OrderDirection.ASC },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.situationalDashboardViews)
      );
  }

  /**
   * fetch visible CC views
   *
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  fetchVisibleSituationalAwarenessView() {
    return this.apollo
      .query<{ situationalDashboardViews: SituationalDashboardView[] }>({
        fetchPolicy: 'no-cache',
        query: GET_VISIBLE_SITUATIONAL_AWARENESS_VIEW,
        variables: {
          options: {
            where: { isVisible: true },
            order: { field: 'CREATED_AT', direction: OrderDirection.ASC },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.situationalDashboardViews)
      );
  }

  /**
   * fetch situational awareness view counts
   *
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  fetchSituationalAwarenessViewCounts() {
    return this.apollo
      .query<{ situationalDashboardViews: SituationalDashboardView[] }>({
        fetchPolicy: 'no-cache',
        query: GET_SITUATIONAL_AWARENESS_VIEW_COUNTS_QUERY,
        variables: {
          options: {
            order: { field: 'CREATED_AT', direction: OrderDirection.ASC },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.situationalDashboardViews)
      );
  }

  /**
   * update situational awareness view
   *
   * @param {string} id
   * @param {SituationalDashboardViewUpdateInput} input
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  updateSituationalAwarenessView(
    id: string,
    input: SituationalDashboardViewUpdateInput
  ) {
    return this.apollo
      .mutate<{ updateSituationalDashboardView: SituationalDashboardView }>({
        mutation: UPDATE_SITUATIONAL_AWARENESS_VIEW,
        variables: { id, input },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.updateSituationalDashboardView),
        tap(() => {
          this.notificationService.showSuccess(
            'Command Center',
            'Successfully updated command center view.'
          );
        }),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_UPDATE_SITUATIONAL_AWARENESS_VIEW
          );
          return throwError(error);
        })
      );
  }

  /**
   * delete situational awareness view
   *
   * @param {string} id
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  deleteSituationalAwarenessView(id: string) {
    return this.apollo
      .mutate<{ deleteSituationalDashboardView: SituationalDashboardView }>({
        mutation: DELETE_SITUATIONAL_AWARENESS_VIEW,
        variables: { id },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.deleteSituationalDashboardView),
        tap(() => {
          this.notificationService.showSuccess(
            'Command Center',
            'Successfully deleted command center view.'
          );
        }),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_DELETE_SITUATIONAL_AWARENESS_VIEW
          );
          return throwError(error);
        })
      );
  }

  /**
   * create situational awareness block
   *
   * @param {SituationalDashboardBlockCreateInput} input
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  createSituationalAwarenessBlock(input: SituationalDashboardBlockCreateInput) {
    return this.apollo
      .mutate<{ createSituationalDashboardBlock: SituationalDashboardBlock }>({
        mutation: CREATE_SITUATIONAL_AWARENESS_BLOCK,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.createSituationalDashboardBlock),
        tap(() => {
          this.notificationService.showSuccess(
            'Command Center',
            'Successfully created command center block.'
          );
        }),
        catchError((error) => throwError(error))
      );
  }

  /**
   * update situational awareness block
   *
   * @param {string} id
   * @param {SituationalDashboardBlockCreateInput} input
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  updateSituationalDashboardBlock(
    id: string,
    input: SituationalDashboardBlockCreateInput
  ) {
    return this.apollo
      .mutate<{ updateSituationalDashboardBlock: SituationalDashboardBlock }>({
        mutation: UPDATE_SITUATIONAL_AWARENESS_BLOCK,
        variables: { id, input },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.updateSituationalDashboardBlock),
        catchError((error) => throwError(error))
      );
  }

  /**
   * deletes situational awareness block
   *
   * @param {string} id
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  deleteSituationalAwarenessBlock(id: string) {
    return this.apollo
      .mutate<{ deleteSituationalDashboardBlock: SituationalDashboardBlock }>({
        mutation: DELETE_SITUATIONAL_AWARENESS_BLOCK,
        variables: { id },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.deleteSituationalDashboardBlock),
        catchError((error) => throwError(error))
      );
  }

  /**
   * update situational awareness division
   *
   * @param {string} id
   * @param {SituationalDashboardDivisionUpdateInput} input
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  updateSituationalDashboardDivision(
    id: string,
    input: SituationalDashboardDivisionUpdateInput
  ) {
    return this.apollo
      .mutate<{
        updateSituationalDashboardDivision: SituationalDashboardDivision;
      }>({
        mutation: UPDATE_SITUATIONAL_AWARENESS_DIVISION,
        variables: { id, input },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.updateSituationalDashboardDivision),
        catchError((error) => throwError(error))
      );
  }

  /**
   * get entities for situational awareness dashboard
   *
   * @returns
   * @memberof SituationalAwarenessDataService
   */
  fetchSituationalAwarenessDashboardEntities() {
    const options = {
      where: { class: 'BUILDING', isPaid: true },
      order: { direction: 'ASC', field: 'NAME' },
    };

    return this.apollo
      .query<{ entities: Entity[] }>({
        fetchPolicy: 'no-cache',
        query: FETCH_SITUATIONAL_AWARENESS_ENTITIES,
        variables: { options },
      })
      .pipe(
        filter((d) => !!d),
        map((res) =>
          res?.data?.entities.map((entity) => ({
            value: entity.id,
            label: entity.name,
          }))
        )
      );
  }

  /**
   * fetch related entities by IDs
   *
   * @param {number[]} entityIds
   * @param {number} [offset=0]
   * @returns {Observable<RelatedEntityQuery>}
   * @memberof SituationalAwarenessDataService
   */
  fetchRelatedEntities({
    entityIds,
    viewIds,
    offset = 0,
  }: {
    entityIds: number[];
    viewIds?: string[];
    offset?: number;
  }): Observable<RelatedEntityQuery> {
    return this.apollo
      .query<RelatedEntityQuery>({
        fetchPolicy: 'no-cache',
        query: FETCH_RELATED_ENTITIES,
        variables: {
          input: {
            offset,
            where: { entityIds, viewIds },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data)
      );
  }

  /**
   * fetch current issue entities by IDs
   *
   * @param {number[]} ids
   * @returns {Observable<Entity[]>}
   * @memberof SituationalAwarenessDataService
   */
  fetchCurrentEntityIssues(ids: number[]): Observable<Entity[]> {
    return this.apollo
      .query<{ entities: Entity[] }>({
        fetchPolicy: 'no-cache',
        query: FETCH_CURRENT_ENTITY_ISSUES,
        variables: {
          options: {
            where: { ids },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.entities)
      );
  }

  /**
   * Recalculates all SAE's
   *
   * @return {*}  {Observable<boolean>}
   * @memberof SituationalAwarenessDataService
   */
  recalculateAllSituationalDashboardEntities(): Observable<boolean> {
    return this.apollo
      .mutate<{ recalculateAllSituationalDashboardEntities: boolean }>({
        fetchPolicy: 'no-cache',
        mutation: RECALCULATE_ALL_SAE,
      })
      .pipe(
        filter((d) => !!d),
        tap(() => {
          this.notificationService.showSuccess(
            'Command Center',
            'Successfully started recalculation.'
          );
        }),
        map((res) => res?.data?.recalculateAllSituationalDashboardEntities)
      );
  }
}
