import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  input,
  output,
  model,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CmmsService } from '@vfi-ui/data-access/shared';

import {
  ExternalCmmsDomainValueCreateInput,
  CmmsFieldMapping,
  ExternalCmmsTranslationDirection,
  ExternalCmmsDomainValueWhere,
  CmmsTranslationInternalValueSource,
  MODAL_Z_INDEX,
} from '@vfi-ui/models';
import { get } from '@vfi-ui/util/helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { NgClass, AsyncPipe } from '@angular/common';
import { DropdownMenuComponent, VfiButtonComponent } from '@vfi-ui/ui/atoms';

export const DEFAULT_TRANSLATION_DIRECTION: ExternalCmmsTranslationDirection =
  ExternalCmmsTranslationDirection.InternalToExternal;

@Component({
  selector: 'nuclei-create-cmms-value-translation',
  templateUrl: './create-cmms-value-translation.component.html',
  styleUrls: ['./create-cmms-value-translation.component.scss'],
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    DropdownMenuComponent,
    VfiButtonComponent,
    AsyncPipe,
  ],
})
export class CreateCmmsValueTranslationComponent implements OnInit, OnChanges {
  readonly isVisible = model(false);
  readonly disableAddButton = input(false);
  readonly cmmsFieldMapping = input<CmmsFieldMapping>(undefined);
  readonly closeModal = output();
  readonly createValueTransation = output<ExternalCmmsDomainValueCreateInput>();
  form: UntypedFormGroup;
  totalDomainValueCount = 0;
  searchDomainValue = '';
  domainValueOptions$: Observable<any>;
  internalFieldSourceOptions = [
    {
      value: CmmsTranslationInternalValueSource.AlarmAttribute,
      label: 'Alarm Attribute',
    },
    {
      value: CmmsTranslationInternalValueSource.CmmsAttribute,
      label: 'Cmms Attribute',
    },
  ];
  modalZIndex = MODAL_Z_INDEX;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private cmmsService: CmmsService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      externalValue: [null, Validators.required],
      internalValue: [null, Validators.required],
      internalValueSource: [
        CmmsTranslationInternalValueSource.AlarmAttribute,
        Validators.required,
      ],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isVisible = get(changes.isVisible, 'currentValue', false);
    if (isVisible) {
      this.form.reset();
      this.domainValueOptions$ = this.fetchCmmsDomainValues();
    }
  }

  /**
   * Invoked when the cancel button is selected. Closes
   * the modal and resets the form.
   *
   * @memberof CreateCmmsDomainValueComponent
   */
  handleCancel() {
    this.form.reset();
    this.closeModal.emit();
  }
  /**
   * Sets the domain value based on the search
   *
   * @param {string} event
   * @memberof CreateCmmsValueTranslationComponent
   */
  setDomainValues(event: string) {
    this.searchDomainValue = event;
    this.domainValueOptions$ = this.fetchCmmsDomainValues();
  }

  /**
   * Fetch cmms domain values.
   *
   * @param {number} [offset=0]
   * @memberof CmmsConfigComponent
   */
  fetchCmmsDomainValues(offset = 0) {
    const where = {
      externalCmmsFieldId: this.cmmsFieldMapping()?.externalField?.id,
    } as ExternalCmmsDomainValueWhere;
    if (this.searchDomainValue) {
      where.keyValueLike = `%${this.searchDomainValue}%`;
    }
    return this.cmmsService.getCmmsDomainValues({ where, offset }).pipe(
      map((res) =>
        res?.externalCmmsDomainValues?.map((domainValue) => ({
          value: domainValue.value,
          label: `${domainValue.value} - ${domainValue.name}`,
        }))
      )
    );
  }

  /**
   * Handle external value selected event.
   *
   * @param {*} ev
   * @memberof DetailsUserComponent
   */
  updateExternalValue(ev) {
    this.form.patchValue({ externalValue: ev });
    this.form.markAsDirty();
  }

  /**
   * Handle internal value source event
   *
   * @param {*} ev
   * @memberof CreateCmmsValueTranslationComponent
   */
  updateInternalValueSource(ev) {
    this.form.patchValue({ internalValueSource: ev });
    this.form.markAsDirty();
  }

  /**
   * Invoked when the add button is selected.
   */
  handleCreateValueTransation() {
    this.createValueTransation.emit({
      ...this.form.value,
      translationDirection: DEFAULT_TRANSLATION_DIRECTION,
      externalFieldId: this.cmmsFieldMapping().externalField.id,
    });
  }
}
